import React from 'react';
import { useSelector } from 'react-redux';

const UpdatingLoader = () => {
  const isUpdating = useSelector(state => state.core.updatingLoader.isUpdating);

  return (
    isUpdating && (
      <div className='update-loader-container'>
        <div className='loader-container'>
          <div className='loader' />
        </div>
        <span>Would you like some fries while waiting?</span>
      </div>
    )
  );
};

export default UpdatingLoader;
