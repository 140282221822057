import { useMemo } from 'react';
import usePermissions from './usePermissions';
import { formatPermissions } from './helpers';
import { Permissions } from './types';

const useOriginalPermissions = (): Permissions => {
  const { keys, originalRoles } = usePermissions();

  const originalPermissions: Permissions = useMemo(() => (
    formatPermissions(keys, originalRoles)
  ), [keys, originalRoles]);

  return originalPermissions;
};

export default useOriginalPermissions;
