import React, {
  Fragment, FunctionComponent, Suspense, SuspenseProps,
} from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { SignUpRouteProps } from './interfaces';
import { RootState } from '../../store';

const SignUpRoute: FunctionComponent<SignUpRouteProps> = ({
  component: Component,
  lang,
  componentProps = {},
  children = null,
  lazy = false,
  ...rest
}) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const user = useSelector((state: RootState) => state.auth.user);

  const state = user?.state ?? '';

  const Wrapper = lazy
    ? Suspense
    : Fragment;

  const wrapperProps = (lazy ? { fallback: null } : {}) as SuspenseProps;

  return (
    <Route
      {...rest}
      render={routeProps => (
        isAuthenticated && state === 'ONBOARDING' ? (
          children || (
            <Wrapper
              {...wrapperProps}
            >
              <Component
                {...routeProps}
                {...componentProps}
                lang={lang}
              />
            </Wrapper>
          )
        ) : (
          <Redirect
            exact
            to={{
              pathname: `/${lang}/login`,
              state: { from: routeProps.location },
            }}
          />
        )
      )}
    />
  );
};

export default withRouter(SignUpRoute);
