import { BackendConstantsKeys, client } from '../../utils';

export type GetConstantsPropType = {
  keys: BackendConstantsKeys[],
};

type GetConstantsReturnType = {
  // It can be an array or object with different levels of nesting or string|number etc
  [key in BackendConstantsKeys]?: any;
};

export const getConstants = <ResponseType extends GetConstantsReturnType>(params?: GetConstantsPropType): Promise<ResponseType> => (
  client.get('/constants', { params })
    .then(({ data }) => data.result)
    .catch((e) => {
      throw new Error(e);
    })
);
