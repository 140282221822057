import { isNotEmpty } from '../helpers';

const formatNumber = (num) => num.toString().replace(',', '.');

const formatProjectFinancialCalculate = (financial, project) => {
  const {
    final_quantity: quantity,
  } = financial;

  const clientPriority = +(financial.apply_priority_client ?? 0);
  const freelancerPriority = +(financial.apply_priority_freelancer ?? 0);

  // TODO REFACTOR
  const pricePerQuantity = isNotEmpty(financial.final_price_per_quantity)
    ? formatNumber(financial.final_price_per_quantity)
    : +clientPriority === 1
      ? formatNumber(financial.price_per_word_average_priority_fee)
      : formatNumber(financial.price_per_quantity) * project.crowdin_compensation_client;
  // TODO REFACTOR
  const costPerQuantity = isNotEmpty(financial.final_cost_per_quantity)
    ? formatNumber(financial.final_cost_per_quantity)
    : freelancerPriority === 1 && !financial.final_cost_per_quantity
      ? formatNumber(financial.cost_per_word_average_priority_fee)
      : formatNumber(financial.cost_per_quantity) * project.crowdin_compensation_user;

  const clientOverrideMinimumFee = +(financial.switch_client_minimum_fee ?? 0);
  const freelancerOverrideMinimumFee = +(financial.switch_freelancer_minimum_fee ?? 0);

  return ({
    quantity: +quantity,
    price_per_quantity: +pricePerQuantity,
    cost_per_quantity: +costPerQuantity,
    apply_priority_client: clientPriority,
    apply_priority_freelancer: freelancerPriority,
    switch_client_minimum_fee: clientOverrideMinimumFee,
    switch_freelancer_minimum_fee: freelancerOverrideMinimumFee,
  });
};

const formatResponseToProjectFinancials = ({ response }) => {
  const {
    apply_priority_client: applyPriorityClient = 0,
    apply_priority_freelancer: applyPriorityFreelancer = 0,
    // custom_total_price: customTotalPrice,
    // custom_total_cost: customTotalCost,
  } = response;
  return ({
    ...response,
    apply_priority_client: +applyPriorityClient,
    apply_priority_freelancer: +applyPriorityFreelancer,
    // custom_total_price: customTotalPrice ? customTotalPrice.toString().replace('.', ',') : null,
    // custom_total_cost: customTotalCost ? customTotalCost.toString().replace('.', ',') : null,
    final_price_per_quantity: response.final_price_per_quantity ?? 0,
    final_cost_per_quantity: response.final_cost_per_quantity ?? 0,
  });
};

export {
  formatProjectFinancialCalculate,
  formatResponseToProjectFinancials,
};
