import { useEffect, useReducer, useRef } from 'react';
import { WIMB } from '../libs/whatismybrowser.min';

const initialState = { hasCookiesChecked: false };

const ACTIONS = {
  TRIGGER_SET_COOKIE: 'trigger_set_cookie',
  SET_COOKIES_THIRD_PARTY_ENABLED: 'set_cookies_third_party_enabled',
  TRIGGER_SET_COOKIE_FAILED: 'trigger_set_cookie_failed',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.TRIGGER_SET_COOKIE:
      return { isCookiesChecking: true };
    case ACTIONS.SET_COOKIES_THIRD_PARTY_ENABLED:
      return { isCookiesChecking: false, hasCookiesChecked: true, isCookiesEnabled: action.payload };
    case ACTIONS.TRIGGER_SET_COOKIE_FAILED:
      return { isCookiesChecking: false, hasCookiesChecked: true, isFailed: true };
    default:
      throw new Error();
  }
}

const detectUrl = 'https://webbrowsertests.com/detect/are-third-party-cookies-enabled-set-cookie';
const useThirdPartyCookiesChecker = (url = detectUrl) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isCookiesChecking } = state;
  const checkCookiesTimeoutRef = useRef(null);
  const checkCookiesIntervalRef = useRef(null);
  // use for set timeout body
  const isCookiesCheckingRef = useRef(isCookiesChecking);
  useEffect(() => { isCookiesCheckingRef.current = isCookiesChecking; }, [isCookiesChecking]);

  // trigger set cookie on mount
  useEffect(() => {
    // detectUrl server use window.WIMB to call WIMB
    window.WIMB = WIMB;

    WIMB.detect.cookies_third_party.trigger_set_cookie(url);
    dispatch({ type: ACTIONS.TRIGGER_SET_COOKIE });
    checkCookiesTimeoutRef.current = setTimeout(() => {
      if (isCookiesCheckingRef.current) {
        dispatch({ type: ACTIONS.TRIGGER_SET_COOKIE_FAILED });
        clearInterval(checkCookiesIntervalRef.current);
      }
    }, 4000);

    return () => {
      clearTimeout(checkCookiesTimeoutRef.current);
    };
  }, []);

  // init checker every 50 ms
  useEffect(() => {
    if (isCookiesChecking) {
      checkCookiesIntervalRef.current = setInterval(() => {
        const isEnabled = WIMB.detect.cookies_third_party.enabled();
        const hasFinished = typeof isEnabled === 'boolean';

        if (hasFinished) {
          dispatch({
            type: ACTIONS.SET_COOKIES_THIRD_PARTY_ENABLED,
            payload: isEnabled,
          });

          clearInterval(checkCookiesIntervalRef.current);

          // detectUrl server use window.WIMB to call WIMB
          delete window.WIMB;
        }
      }, 50);
    }

    return () => {
      clearInterval(checkCookiesIntervalRef.current);
    };
  }, [isCookiesChecking]);

  return state;
};

export default useThirdPartyCookiesChecker;
