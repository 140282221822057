import * as types from '../constants';

const initState = {
  isLoading: true,
  ids: null,
  valueById: null,
  isFailed: false,
};

const financialTypes = (state = initState, action) => {
  switch (action.type) {
    case types.FINANCIAL_TYPES_LOADING:
      return {
        isLoading: true,
        ids: null,
        valueById: null,
        isFailed: false,
      };
    case types.FINANCIAL_TYPES_SUCCESS:
      return {
        isLoading: false,
        ids: action.payload.ids,
        valueById: action.payload.valueById,
        isFailed: false,
      };
    case types.FINANCIAL_TYPES_FAILED:
      return {
        isLoading: false,
        ids: null,
        valueById: null,
        isFailed: true,
      };
    default:
      return state;
  }
};

export default financialTypes;
