import * as types from '../constants';

const initialState = {
  languages: [],
  contentTypes: [],
  industries: [],
  services: [],
  signinSource: [],
  localisationSoftware: [],
  seoOptimize: [],
  signUpErrorMessage: null,
  assessmentWritingExercise: null,
  assessmentTranslatingExercise: null,
  initialValues: null,
};

export default function newBrainstorm(state = initialState, action) {
  switch (action.type) {
    case types.GET_ONBOARDING_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.payload,
      };
    }
    case types.GET_ONBOARDING_CONTENT_TYPES_SUCCESS: {
      return {
        ...state,
        contentTypes: action.payload,
      };
    }
    case types.GET_ONBOARDING_SERVICES_SUCCESS: {
      return {
        ...state,
        services: action.payload,
      };
    }
    case types.GET_ONBOARDING_INDUSRIES_SUCCESS: {
      return {
        ...state,
        industries: action.payload,
      };
    }
    case types.GET_ONBOARDING_SOURCE_SUCCESS: {
      return {
        ...state,
        signinSource: action.payload,
      };
    }
    case types.GET_ONBOARDING_LOCALISATION_SOFTWARE_SUCCESS: {
      return {
        ...state,
        localisationSoftware: action.payload,
      };
    }
    case types.GET_ONBOARDING_SEO_OPTIMAZE_SUCCESS: {
      return {
        ...state,
        seoOptimize: action.payload,
      };
    }
    case types.CREATE_NEW_FREELANCER_FAILURE: {
      return {
        ...state,
        signUpErrorMessage: action.payload,
      };
    }
    case types.GET_ONBOARDING_ASSESSMENT_WRITING_EXERCISE_SUCCESS: {
      return {
        ...state,
        assessmentWritingExercise: action.payload,
      };
    }
    // TODO Remove. Looks like useless
    case types.GET_ONBOARDING_ASSESSMENT_TRANSLATING_EXERCISE_SUCCESS: {
      return {
        ...state,
        assessmentTranslatingExercise: action.payload,
      };
    }
    case types.RECEIVE_ONBOARDING_DATA_SUCCESS: {
      return {
        ...state,
        initialValues: action.payload,
      };
    }
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        initialValues: null,
        signUpErrorMessage: null,
        assessmentWritingExercise: null,
        assessmentTranslatingExercise: null,
      };
    default:
      return state;
  }
}
