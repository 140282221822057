const PAGINATION_LIMITS = [10, 25, 50, 100];

const DEFAULT_PAGINATION_LIMITS = {
  ADMIN: 50,
  SUPER: 50,
  WRITER: 10,
  DESIGNER: 10,

  DEFAULT: 10,
};

export {
  PAGINATION_LIMITS,
  DEFAULT_PAGINATION_LIMITS,
};
