const functionCreator = (functionStr, context = null) => {
  const result = {};
  try {
    if (!functionStr) {
      throw new Error('functionCreator Error: functionStr is empty');
    }
    // eslint-disable-next-line no-new-func,@typescript-eslint/no-implied-eval
    const createdFunction = new Function(`return ${functionStr}`)();
    if (typeof createdFunction !== 'function') {
      throw new Error('functionCreator Error: createdFunction is not a function');
    }
    result.function = context
      ? createdFunction.bind(context)
      : createdFunction;
    result.success = true;
  } catch (error) {
    result.success = false;
    result.error = error;
  }

  return result;
};

export {
  functionCreator,
};
