import React from 'react';
import {
  AUDIO_TRANSCRIPTION_ID, EDITING_ID, LOCALISATION_ID, REWRITING_ID, TRANSLATION_ID, WRITING_ID,
} from '../../constants';
import { PROJECT_ATTACHMENTS, ServiceTypeId, translationServices } from '../constants';
import { formatURL, isNotEmpty } from './utils';
import { OrNull } from '../../types';
import { FileTypeIds } from '../../components/profile/components/UserProfile/FreelancerProfile/DetailsTab/EvaluationDocuments/helpers';
import { IFile } from '../../components/projects/components/NewProject/AdditionalDocumentsField/interfaces';
import {
  UpdateSourceServerFileByIdCallbackType,
} from '../../components/projects/components/ProjectDetails/ProjectDetailsContent/BriefingTabNew/SourceFilesContext';

type ServiceKeysType = { id: number, title: string }[];
export const serviceKeys: ServiceKeysType = [
  { id: LOCALISATION_ID, title: 'Localisation' },
  { id: TRANSLATION_ID, title: 'MTPE' },
  { id: EDITING_ID, title: 'Editing' },
  { id: WRITING_ID, title: 'Writing' },
  { id: REWRITING_ID, title: 'Rewriting' },
  { id: AUDIO_TRANSCRIPTION_ID, title: 'Audio Transcription' },
];

type FieldsForGroupingType = { couple: string, serviceTypeId: string };
export const fieldsForGrouping: FieldsForGroupingType = {
  couple: 'couple',
  serviceTypeId: 'service_type_id',
};

export type StringNumberType = string | number;
type StringNumberTypeArray = StringNumberType[];
type AttachmentGroupValueType = StringNumberType | undefined | null;
type AttachmentGroupValueTypeArray = AttachmentGroupValueType[];
type ObjectStringType = { [key: string]: string };
export type ObjectStringNumberType = { [key: string]: StringNumberType };
export type GroupObjectType = { [key: string]: AttachmentGroupValueType };

type GetAttachmentsSectionTitlesType = (...args: AttachmentGroupValueTypeArray) => string;
export const getProjectAttachmentsSectionTitles: GetAttachmentsSectionTitlesType = (...args) => {
  const [serviceId, couple] = args;
  const servicesTitles: ObjectStringType = serviceKeys.reduce((pr, { id, title }) => ({
    ...pr,
    [id.toString()]: title,
  }), {});

  const argsCount: number = args.length;
  const serviceTitleId = serviceId ? servicesTitles[serviceId] : '';
  switch (argsCount) {
    case 1:
      return serviceTitleId;
    case 2:
      return isNotEmpty(couple)
        ? `${serviceTitleId} (Example ${couple})`
        : serviceTitleId;
    default:
      return '';
  }
};

type GetAttachmentsGroupKeysType = (...args: StringNumberTypeArray) => string[];
export const getProjectAttachmentGroupKeys: GetAttachmentsGroupKeysType = (...args) => {
  const [serviceId] = args;
  return [
    fieldsForGrouping.serviceTypeId,
    translationServices.includes(serviceId as ServiceTypeId) && fieldsForGrouping.couple,
  ].filter(Boolean) as string[];
};

type GetAttachmentsMaxCountPerGroupType = (...args: AttachmentGroupValueTypeArray) => OrNull<number>;
export const getProjectAttachmentMaxCountPerGroup: GetAttachmentsMaxCountPerGroupType = (...args) => {
  const [serviceId, couple] = args;
  const isLocalisationOrTranslation: boolean = [
    ServiceTypeId.LOCALISATION,
    ServiceTypeId.TRANSLATION,
  ].includes(serviceId as ServiceTypeId);
  const isCoupleExist: boolean = isNotEmpty(couple);

  const argsCount: number = args.length;
  switch (argsCount) {
    case 1:
      return 4;
    case 2:
      // for assessment_exercise_works couple not exist
      // so check if it is 2nd param for translating
      return isLocalisationOrTranslation || isCoupleExist ? 2 : 4;
    default:
      return null;
  }
};

type ExerciseFileType = {
  file_url: string,
  title: string,
};
export type AttachmentPropType = {
  id: StringNumberType,
  title?: string,
  url?: string,
  file_url?: string,
  link?: string,
  service_type_id: number,
  type_id?: null | number,
  couple: null | number,
  is_task?: number,
  exercise_file?: ExerciseFileType
};
export type AttachmentItem = {
  file_url: string,
  type: string,
  fileName: string,
  link: string,
  id: StringNumberType,
  service_type_id: number,
  type_id: null | number,
  couple: null | number,
  is_task: number,
  exercise_file: ExerciseFileType | null,
};

export type RestAttachmentItemType<T> = Omit<T,
'couple'
| 'service_type_id'
| 'id'
| 'title'
| 'url'
| 'file_url'
| 'link'
| 'type_id'
| 'is_task'
| 'exercise_file'
>;
export const getAttachmentItem = <T extends AttachmentPropType>({
  id,
  title: fileName,
  url,
  file_url: fileUrl,
  link,
  service_type_id: serviceTypeId,
  type_id: typeId,
  couple,
  is_task: isTask,
  exercise_file: exerciseFile,
  ...rest
}: T): RestAttachmentItemType<T> & AttachmentItem => {
  // this should be refactored on backend part
  const attachmentFileUrl: string = (isTask ? exerciseFile?.file_url : fileUrl) ?? '';
  const attachmentTitle: string = (isTask ? exerciseFile?.title : (fileName || link)) ?? '';
  const linkValue = formatURL(link || url || attachmentFileUrl || '');

  return ({
    ...rest,
    is_task: isTask || 0,
    exercise_file: exerciseFile ?? null,
    couple,
    file_url: attachmentFileUrl,
    type: link ? PROJECT_ATTACHMENTS.LINK : PROJECT_ATTACHMENTS.DOCUMENT,
    fileName: attachmentTitle,
    link: linkValue,
    service_type_id: serviceTypeId,
    type_id: typeId ?? null,
    id,
  });
};

type FormatProjectAttachments = (params: { attachments: AttachmentPropType[] }) => AttachmentItem[];
export const formatProjectAttachments: FormatProjectAttachments = ({ attachments }) => attachments.map(getAttachmentItem);

type AttachmentsSectionInfoType = {
  id: number | string,
  title: string,
  groupKey: string,
  maxAttachmentsCount: OrNull<number>,
  groupIds: GroupObjectType,
  groupPath: string[],
};

type AttachmentsObjType = {
  [groupKey: string]: AttachmentsSectionType
};
export type AttachmentsSectionType = AttachmentsSectionInfoType & {
  attachments: AttachmentItem[] | AttachmentsObjType
};

type GetInitSectionDataType = (params: {
  id: AttachmentsSectionType['id'],
  groupValues: AttachmentGroupValueTypeArray,
  currentGroupKey: string,
  isArray: boolean,
  getSectionTitles: GetAttachmentsSectionTitlesType,
  getMaxAttachmentCountPerGroup?: GetAttachmentsMaxCountPerGroupType,
  groupIds?: GroupObjectType,
  groupPath?: string[],
}) => AttachmentsSectionType;
const getInitSectionData: GetInitSectionDataType = ({
  id,
  groupValues,
  currentGroupKey,
  isArray,
  getSectionTitles,
  getMaxAttachmentCountPerGroup,
  groupIds = {},
  groupPath = [],
}) => ({
  id,
  title: getSectionTitles(...groupValues) ?? '',
  maxAttachmentsCount: getMaxAttachmentCountPerGroup?.(...groupValues) ?? null,
  groupKey: currentGroupKey,
  attachments: isArray ? [] : {},
  groupIds,
  groupPath,
});

type GetProjectAttachmentsMultiGroup = (params: {
  attachments: AttachmentPropType[],
  groupByKey: string | string[],
  getAttachmentGroupKeys: GetAttachmentsGroupKeysType,
  getSectionTitles: GetAttachmentsSectionTitlesType,
  getMaxAttachmentCountPerGroup?: GetAttachmentsMaxCountPerGroupType,
  requiredMainSectionsIds?: number[]
}) => AttachmentsSectionType;
export const getProjectAttachmentsMultiGroup: GetProjectAttachmentsMultiGroup = ({
  attachments,
  groupByKey,
  getAttachmentGroupKeys,
  getSectionTitles,
  getMaxAttachmentCountPerGroup,
  requiredMainSectionsIds = [],
}) => {
  const filesAndLinks = getInitSectionData({
    id: 'attachments_main_group',
    groupValues: [],
    isArray: false,
    currentGroupKey: '',
    getSectionTitles,
  });

  let filesAndLinksAttachments: AttachmentsSectionType['attachments'] = {};

  if (!groupByKey) {
    // format files if nothing to group
    filesAndLinksAttachments = formatProjectAttachments({ attachments });
  } else {
    const allGroups: string[] = Array.isArray(groupByKey) ? groupByKey : [groupByKey];

    // this grouping is based on mutation
    // we select/create section object on each step
    // and push item into it

    attachments.forEach((attachment) => {
      let itemGroups: string[] = allGroups;
      if (getAttachmentGroupKeys) {
        const attachmentValues: StringNumberTypeArray = allGroups.map(groupKey => attachment[groupKey]);
        itemGroups = getAttachmentGroupKeys(...attachmentValues);
      }

      const attachmentsSectionToPut = itemGroups.reduce<AttachmentsSectionType['attachments']>(
        (prevFilesLinks, currentGroupKey: string, index: number) => {
          // create all nested object on path for section
          const itemKeyRealValue: AttachmentGroupValueType = attachment[currentGroupKey];
          const itemKey: StringNumberType = itemKeyRealValue ?? 'NO_VALUE';

          // save group path and value by each group key
          // index + 1, as slice not include end
          const groupPath: string[] = itemGroups.slice(0, index + 1);
          const groupIds: GroupObjectType = groupPath.reduce((pr, key) => ({ ...pr, [key]: attachment[key] }), {});
          const groupValues: AttachmentGroupValueTypeArray = groupPath.map(key => groupIds[key]);

          // generate default value
          const isLastGroupKey: boolean = index === itemGroups.length - 1;
          const defaultValue = getInitSectionData({
            id: itemKey,
            groupValues,
            // array for last group, but object for other to allow nesting
            isArray: isLastGroupKey,
            currentGroupKey,
            getSectionTitles,
            getMaxAttachmentCountPerGroup,
            groupIds,
            groupPath,
          });

          // take existing nested object or get default
          prevFilesLinks[itemKey] = prevFilesLinks[itemKey] || defaultValue;

          // select section attachments
          return prevFilesLinks[itemKey].attachments;
        }, filesAndLinksAttachments,
      );

      // the latest section (place to put) will be an array
      (attachmentsSectionToPut as AttachmentItem[]).push(getAttachmentItem(attachment));
    });

    // add required sections
    requiredMainSectionsIds.forEach(requiredMainSectionId => {
      const currentGroupKey: string = allGroups[0];
      const groupValues: AttachmentGroupValueTypeArray = [requiredMainSectionId];
      const isItemExist: boolean = !!filesAndLinksAttachments[requiredMainSectionId];
      if (!isItemExist) {
        filesAndLinksAttachments[requiredMainSectionId] = getInitSectionData({
          id: requiredMainSectionId,
          groupValues,
          isArray: true,
          currentGroupKey,
          getSectionTitles,
          getMaxAttachmentCountPerGroup,
          groupIds: { [currentGroupKey]: requiredMainSectionId },
          groupPath: [currentGroupKey],
        });
      }
    });
  }

  return {
    ...filesAndLinks,
    attachments: filesAndLinksAttachments,
  };
};

export type UploadFileType = (file: File | null, options: GroupObjectType) => Promise<void>;
export type DeleteFileType = (fileId: StringNumberType) => void;
export type UploadFileSectionType = {
  key: string,
  typeId: FileTypeIds,
  label: string,
  inputLabel: string,
  disabled?: boolean,
};
export type UploadFileSectionObjectType = { [key in FileTypeIds]: UploadFileSectionType };
export type UploadFileSectionArrayType = UploadFileSectionType[];
export type HandleUploadFileWithEvent = (e: React.ChangeEvent<HTMLInputElement>, additionalOptions?: { type_id?: number }) => void;

export type ServerDocumentType = AttachmentItem & {
  id: number,
  size: number,
  // retry logic
  retryCallback?: VoidFunction,
  retryText?: string,
  warnMessage?: string,
  can_use_translation_flow?: boolean,
  deepl_translation_failed?: boolean,
};

export type ServerDocumentPropType = AttachmentPropType & {
  id: number,
  size: number,
};

export const formatUploadedProjectFile = (file: ServerDocumentPropType): ServerDocumentType => (
  {
    ...getAttachmentItem(file),
    id: file.id,
  }
);

export const updateServerFile = (
  newServerFile: IFile,
  updateServerFileById: UpdateSourceServerFileByIdCallbackType,
): void => {
  const serverFile: ServerDocumentType = formatUploadedProjectFile({
    ...newServerFile,
    couple: null,
    service_type_id: 0,
    size: newServerFile.size_in_kb * 1024,
  });

  // we use string value as id for uploaded files
  updateServerFileById({ id: String(serverFile.id), serverFile });
};
