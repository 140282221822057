export const RECEIVE_ONBOARDING_DATA_SUCCESS = 'RECEIVE_ONBOARDING_DATA_SUCCESS';

export const GET_ONBOARDING_LANGUAGES_SUCCESS = 'GET_ONBOARDING_LANGUAGES_SUCCESS';
export const GET_ONBOARDING_LANGUAGES_FAILURE = 'GET_ONBOARDING_LANGUAGES_FAILURE';

export const GET_ONBOARDING_CONTENT_TYPES_SUCCESS = 'GET_ONBOARDING_CONTENT_TYPES_SUCCESS';
export const GET_ONBOARDING_CONTENT_TYPES_FAILURE = 'GET_ONBOARDING_CONTENT_TYPES_FAILURE';

export const GET_ONBOARDING_SERVICES_SUCCESS = 'GET_ONBOARDING_SERVICES_SUCCESS';
export const GET_ONBOARDING_SERVICES_FAILURE = 'GET_ONBOARDING_SERVICES_FAILURE';

export const GET_ONBOARDING_INDUSRIES_SUCCESS = 'GET_ONBOARDING_INDUSRIES_SUCCESS';
export const GET_ONBOARDING_INDUSRIES_FAILURE = 'GET_ONBOARDING_INDUSRIES_FAILURE';

export const GET_ONBOARDING_SOURCE_SUCCESS = 'GET_ONBOARDING_SOURCE_SUCCESS';
export const GET_ONBOARDING_SOURCE_FAILURE = 'GET_ONBOARDING_SOURCE_FAILURE';

export const GET_ONBOARDING_LOCALISATION_SOFTWARE_SUCCESS = 'GET_ONBOARDING_LOCALISATION_SOFTWARE_SUCCESS';
export const GET_ONBOARDING_LOCALISATION_SOFTWARE_FAILURE = 'GET_ONBOARDING_LOCALISATION_SOFTWARE_FAILURE';

export const GET_ONBOARDING_SEO_OPTIMAZE_SUCCESS = 'GET_ONBOARDING_SEO_OPTIMAZE_SUCCESS';
export const GET_ONBOARDING_SEO_OPTIMAZE_FAILURE = 'GET_ONBOARDING_SEO_OPTIMAZE_FAILURE';

export const CREATE_NEW_FREELANCER_FAILURE = 'CREATE_NEW_FREELANCER_FAILURE';

export const UPDATE_FREELANCER_AVATAR_SUCCESS = 'UPDATE_FREELANCER_AVATAR_SUCCESS';
export const UPDATE_FREELANCER_AVATAR_FAILURE = 'UPDATE_FREELANCER_AVATAR_FAILURE';

export const REMOVE_FREELANCER_AVATAR_SUCCESS = 'REMOVE_FREELANCER_AVATAR_SUCCESS';
export const GET_ONBOARDING_ASSESSMENT_WRITING_EXERCISE_SUCCESS = 'GET_ONBOARDING_ASSESSMENT_WRITING_EXERCISE_SUCCESS';
export const GET_ONBOARDING_ASSESSMENT_WRITING_EXERCISE_FAILURE = 'GET_ONBOARDING_ASSESSMENT_WRITING_EXERCISE_FAILURE';

export const GET_ONBOARDING_ASSESSMENT_TRANSLATING_EXERCISE_SUCCESS = 'GET_ONBOARDING_ASSESSMENT_TRANSLATING_EXERCISE_SUCCESS';
export const GET_ONBOARDING_ASSESSMENT_TRANSLATING_EXERCISE_FAILURE = 'GET_ONBOARDING_ASSESSMENT_TRANSLATING_EXERCISE_FAILURE';

export const GET_ONBOARDING_ASSESSMENT_WORK_SUCCESS = 'GET_ONBOARDING_ASSESSMENT_WORK_SUCCESS';
export const GET_ONBOARDING_ASSESSMENT_WORK_FAILURE = 'GET_ONBOARDING_ASSESSMENT_WORK_FAILURE';

export const REMOVE_ONBOARDING_ASSESSMENT_WORK_SUCCESS = 'REMOVE_ONBOARDING_ASSESSMENT_WORK_SUCCESS';
export const REMOVE_ONBOARDING_ASSESSMENT_WORK_FAILURE = 'REMOVE_ONBOARDING_ASSESSMENT_WORK_FAILURE';

export const UPLOAD_ONBOARDING_ASSESSMENT_WORK_SUCCESS = 'UPLOAD_ONBOARDING_ASSESSMENT_WORK_SUCCESS';
export const UPLOAD_ONBOARDING_ASSESSMENT_WORK_FAILURE = 'UPLOAD_ONBOARDING_ASSESSMENT_WORK_FAILURE';

export const GET_ONBOARDING_WORK_EXAMPLES_FILE_SUCCESS = 'GET_ONBOARDING_WORK_EXAMPLES_FILE_SUCCESS';
export const GET_ONBOARDING_WORK_EXAMPLES_FILE_FAILURE = 'GET_ONBOARDING_WORK_EXAMPLES_FILE_FAILURE';

export const GET_ONBOARDING_WORK_EXAMPLES_LINK_SUCCESS = 'GET_ONBOARDING_WORK_EXAMPLES_LINK_SUCCESS';
export const GET_ONBOARDING_WORK_EXAMPLES_LINK_FAILURE = 'GET_ONBOARDING_WORK_EXAMPLES_LINK_FAILURE';

// export const WRITING_ID = 1;
// export const EDITING_ID = 2;
// export const LOCALISATION_ID = 3;
// export const REWRITING_ID = 4;
