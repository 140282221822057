import { RootState } from '../../store';
import { OrNull } from '../../types';

export type ClientIdAndUserIdReturnType = {
  userID: OrNull<number>,
  clientID: OrNull<number>,
};

export const getUserAndClientIdFromStore = (state: RootState): ClientIdAndUserIdReturnType => ({
  userID: state.auth.user?.id ?? null,
  clientID: state.auth.user?.client_id ?? null,
});

export const getUserAndClientIdFromLocalStorage = (): ClientIdAndUserIdReturnType => {
  // impersonate user id
  const impersonateUserId: OrNull<string> = localStorage.getItem('impersonate_id');
  const impersonateUserIdNumber: OrNull<number> = impersonateUserId ? +impersonateUserId : null;

  // impersonate client id
  const impersonateClientId: OrNull<string> = localStorage.getItem('client_id');
  const impersonateClientIdNumber: OrNull<number> = impersonateClientId ? +impersonateClientId : null;

  return {
    userID: impersonateUserIdNumber,
    clientID: impersonateClientIdNumber,
  };
};

export const getAuthUserId = (store: RootState): ClientIdAndUserIdReturnType => {
  const {
    userID: storeUserId,
    clientID: storeClientId,
  } = getUserAndClientIdFromStore(store);

  // from impersonate
  const {
    userID: impersonateUserIdNumber,
    clientID: impersonateClientIdNumber,
  } = getUserAndClientIdFromLocalStorage();

  // result user id
  const userID: OrNull<number> = impersonateUserIdNumber || storeUserId;

  // result client id
  const clientID: OrNull<number> = impersonateClientIdNumber || storeClientId;

  return { userID, clientID };
};
