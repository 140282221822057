import * as types from '../constants/projects';

const initialState = {
  projects: [],
  projectsForSelect: [],
  url: '',
  current_page: null,
  per_page: 10,
  total: null,
  last_page: null,
};

export default function projects(state = initialState, action) {
  switch (action.type) {
    case types.GET_MY_PROJECTS_SUCCESS:
    case types.GET_ACTIVE_PROJECTS_SUCCESS:
    case types.GET_ALL_PROJECTS_SUCCESS:
    case types.GET_FINISHED_PROJECTS_SUCCESS:
    case types.SORT_PROJECTS_SUCCESS:
    case types.RESULTS_PER_PAGE_SUCCESS:
    case types.SET_PAGE_PROJECTS_PAGE_SUCCESS:
    case types.FILTER_PROJECTS_BY_SUCCESS:
    case types.SEARCH_SUCCESS:
    case types.GET_DRAFT_BRIEFINGS_SUCCESS:
      return {
        ...state,
        projects: action.payload.data,
        current_page: action.payload.current_page,
        total: action.payload.total,
        per_page: action.payload.per_page,
        last_page: action.payload.last_page,
      };
    case types.GET_MY_PROJECTS_URL:
    case types.GET_ACTIVE_PROJECTS_URL:
    case types.GET_ALL_PROJECTS_URL:
    case types.GET_FINISHED_PROJECTS_URL:
    case types.SORT_PROJECTS_URL:
    case types.FILTER_PROJECTS_BY_SUCCESS_URL:
    case types.SET_PAGE_PROJECTS_PAGE_URL:
    case types.GET_DRAFT_BRIEFINGS_URL:
      return {
        ...state,
        url: action.payload,
      };
    case types.GET_FOR_SELECT_SUCCESS:
      return {
        ...state,
        projectsForSelect: action.payload,
      };
    case types.DELETE_PROJECT_FROM_DASHBOARD_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(({ project_id: projectId, draft_id: draftId }) => (
          action.payload.isDraft
            ? draftId !== action.payload.id
            : projectId !== action.payload.id
        )),
      };
    default:
      return state;
  }
}
