import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'; // eslint-disable-line @typescript-eslint/no-var-requires
import { toast, ToastPosition } from 'react-toastify';
import sendSlackMessage from './slackApi';
import store from '../store';
import sentryLogger from '../services/sentry';
import { MISSED_REFRESH_TOKEN_ERROR, refreshAccessToken } from '../actions/auth';
import { deleteFromLocalStorage } from './localStorage';
import { HttpStatusCode } from './types';
import { redirectToLoginPage } from './helpers';

const baseURL = process.env.REACT_APP_API_URL;

const clientDefault = axios.create({
  baseURL,
});

const checkUrlImpersonate = (endPoint: string) => {
  const routesWithoutImpersonate: string[] = [
    '/getProfile',
  ];
  const isRouteWithoutImpersonate = routesWithoutImpersonate
    .some((route) => {
      // regexp will check if is same as route or end with ? after route name
      const regExp = new RegExp(`^${route}($|[?])`);
      return regExp.test(endPoint);
    });
  const shouldAddImpersonate: boolean = !isRouteWithoutImpersonate;
  const impersonateId = localStorage.getItem('impersonate_id');
  return (shouldAddImpersonate && impersonateId !== null && endPoint.indexOf('impersonate_user_id') === -1)
    ? (endPoint.indexOf('?') === -1)
      ? `${endPoint}?impersonate_user_id=${impersonateId}`
      : `${endPoint}&impersonate_user_id=${impersonateId}`
    : endPoint;
};

const client: AxiosInstance = new Proxy(clientDefault, {
  get(target: AxiosInstance, propKey: string) {
    const originalMethod = target[propKey];

    // Add proxied methods
    const proxiedUrlMethods: string[] = [
      'get',
      'delete',
      'head',
      'options',
      'post',
      'put',
      'patch',
    ];

    if (typeof originalMethod === 'function' && proxiedUrlMethods.includes(propKey)) {
      return (...args) => {
        const [oldUrl, ...restArgs] = args;
        const newUrl = checkUrlImpersonate(oldUrl);
        const newArgs = [newUrl, ...restArgs];
        return originalMethod(...newArgs);
      };
    }

    // For other properties, return as is
    return originalMethod;
  },
});

const setToken = (authToken: string, tokenType: string = 'Bearer') => {
  client.defaults.headers.common.Authorization = `${tokenType} ${authToken}`;
};

const removeToken = (): void => {
  delete client.defaults.headers.common.Authorization;
};

const quest: AxiosInstance = axios.create({
  baseURL,
});

type AuthUserData = {
  token_type: string,
  // expires_in: number,
  access_token: string,
  refresh_token: string,
};

export const clearUserData = () => {
  const keysToDelete: string[] = [
    'userData',
    'token',
    'token_type',
    'refresh_token',
    'impersonate_id',
    'impersonate_is_company',
    'impersonate_name',
    'type',
    'client_id',
    'info_alert_id',
    'info_alert_text',
    'info_alert_link',
    'info_alert_active',
    'show_freelancer_warning',
    'uncomplete_umount',
  ];

  deleteFromLocalStorage(keysToDelete);
};

export const clearUserAuthData = (shouldRedirect: boolean = false) => {
  clearUserData();
  removeToken();

  if (shouldRedirect) {
    const { pathname } = window.location;
    const lang: string = pathname.split('/')[1];
    // call redirect async to wait until store will be updated
    setTimeout(() => redirectToLoginPage(lang), 0);
  }
};

export const setUserAuthData = (authUserData: AuthUserData) => {
  const {
    access_token: authToken,
    token_type: authTokenType,
    refresh_token: refreshToken,
  } = authUserData;

  // update tokens in storage
  localStorage.setItem('token', authToken);
  localStorage.setItem('token_type', authTokenType);
  localStorage.setItem('refresh_token', refreshToken);

  // update auth token in requests
  setToken(authToken, authTokenType);
};

const toastOptions: { position: ToastPosition; autoClose: false } = { autoClose: false, position: 'bottom-left' };

const isSecureField = (field: string): boolean => {
  const secureFields = ['authorization', 'token', 'password'];
  return secureFields.some(secureField => new RegExp(secureField, 'i').test(field));
};

const filterSecureData = <T extends Object>(data: T): T => {
  const filteredValue: string = '<FILTERED>';

  return Object.entries(data)
    .reduce<T>((resultObject, [key, value]) => {
    const isSecuredKey = isSecureField(key);
    return {
      ...resultObject,
      [key]: isSecuredKey ? filteredValue : value,
    };
  }, {} as T);
};

const getReportInfo = (error) => {
  const {
    config: {
      baseURL: requestBaseURL,
      headers,
      data: dataJson,
      method,
      url,
    },
  } = error;

  const state = store.getState();
  const user = state?.auth?.user || null;

  const impersonateId = localStorage.getItem('impersonate_id');
  const apiUrl = `${requestBaseURL}${url}`;
  const location = window.location.href;

  let dataObj = null;
  let shouldStringify = true;
  const isFormData = dataJson instanceof FormData;
  try {
    dataObj = isFormData
      ? Object.fromEntries(dataJson.entries())
      : JSON.parse(dataJson);
  } catch (e) {
    shouldStringify = false;
  }

  const requestDataJson = shouldStringify && dataObj
    ? JSON.stringify(filterSecureData(dataObj), null, 2)
    : dataJson;
  const requestHeadersStr = JSON.stringify(filterSecureData(headers), null, 2);

  return ({
    user,
    impersonateId,
    apiUrl,
    location,
    data: requestDataJson,
    headers: requestHeadersStr,
    method: method.toUpperCase(),
    isFormData,
  });
};

const getError = async (error) => {
  if (
    error?.request?.responseType === 'blob'
    && error.response?.data instanceof Blob
    && error.response.data?.type
    && error.response.data.type.toLowerCase().indexOf('json') !== -1
  ) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const { result } = reader;
        if (result && typeof result === 'string') {
          error.response.data = JSON.parse(result);
        }
        resolve(error);
      };

      reader.onerror = () => {
        reject(error);
      };

      reader.readAsText(error.response.data);
    });
  } else {
    return error;
  }
};

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing: boolean = false;

type RefreshErrorType = {
  status: HttpStatusCode,
  data: Partial<{
    data: { error: string },
    message: string,
  }>
};

const handleRefreshTokenError = (refreshError: unknown): boolean => {
  const isRefreshTokenMissed = (refreshError as Error)?.message === MISSED_REFRESH_TOKEN_ERROR;
  if (isRefreshTokenMissed) return true;

  const status = (refreshError as RefreshErrorType)?.status;

  const errorMessage = (refreshError as RefreshErrorType)?.data?.data?.error ?? '';
  const isAuthenticationFailed: boolean = (status === HttpStatusCode.BadRequest) && (errorMessage === 'Authentication failed');
  if (isAuthenticationFailed) return true;

  const tokenError: string = (refreshError as RefreshErrorType)?.data?.message ?? '';
  const isRefreshTokenError: boolean = (status === HttpStatusCode.Forbidden) && tokenError === 'Refresh token error';
  if (isRefreshTokenError) return true;

  return false;
};

// Add a response interceptor
[client, quest].forEach((instant, index) => (
  instant.interceptors.response.use((response) => (
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response.data
  ),
  async (initialError) => {
    const error = await getError(initialError);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    const originalRequest: AxiosRequestConfig = error.config;

    // index
    const isClientInstants = index === 0;
    if (error.response.status === HttpStatusCode.Unauthorized && isClientInstants) {
      // Access token has expired, refresh it
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Refresh the access token
          const newAuthData = await refreshAccessToken();

          setUserAuthData(newAuthData);

          // Update the request headers with the new access token
          const { access_token: newAccessToken, token_type: newAuthTokenType } = newAuthData;
          error.config.headers.Authorization = `${newAuthTokenType} ${newAccessToken}`;

          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            // Update access token for each request
            const requestConfig: AxiosRequestConfig = {
              ...config,
              headers: {
                ...(config.headers ?? {}),
                Authorization: `${newAuthTokenType} ${newAccessToken}`,
              },
            };
            instant
              .request(requestConfig)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return await instant(originalRequest);
        } catch (refreshError: unknown) {
          const shouldLogout: boolean = handleRefreshTokenError(refreshError);

          // Clear user data + redirect to login page
          clearUserAuthData(shouldLogout);

          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }

      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    // FailedResource error
    if (error.response.status === HttpStatusCode.BadRequest) {
      const text = error.response.data?.message
          || error.response.data?.data?.result
          || error.response.data?.data?.error;
      if (text && typeof text === 'string') {
        const reportInfo = getReportInfo(error);
        try {
          toast.error(text, toastOptions);
          await sendSlackMessage({
            ...reportInfo,
            type: 'FailedResource error',
            text,
          });
        } catch (e: any) {
          console.info(`Fail to send Slack notification. ${e.message}`);
        }
      }
    }

    // Validation error
    if (error.response.status === HttpStatusCode.UnprocessableEntity) {
      const reportInfo = getReportInfo(error);
      const errors = error.response.data?.errors || error.response.data?.data?.errors;
      const isValidResponse = !!errors && typeof errors === 'object';
      if (isValidResponse) {
        Object.entries(errors).forEach(([, val]) => {
          try {
            const string = val?.[0] ?? val;
            if (typeof string === 'string') {
              toast.error(string, toastOptions);
              sendSlackMessage({
                ...reportInfo,
                type: 'Validation error',
                text: string,
              });
            }
          } catch (e: any) {
            console.info(`Fail to send Slack notification. ${e.message}`);
          }
        });
      }
    }

    return Promise.reject(error.response);
  })
));

const savedToken = localStorage.getItem('token') || null;
const savedTokenType = localStorage.getItem('token_type') || null;
// Set token if exists
(savedToken && savedTokenType) && setToken(savedToken, savedTokenType);

const getData = (url, user) => (
  client.get(url)
    .then(data => data.data.result)
    .catch(error => {
      sentryLogger.exceptionWithScope(error, { user, url });
      throw new Error(error);
    })
);

export {
  client,
  quest,
  setToken,
  removeToken,
  getData,
};
