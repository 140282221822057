import { useEffect, useReducer } from 'react';

enum ReducerActions {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

type Action<T> = (
  { type: ReducerActions.LOADING }
  | { type: ReducerActions.SUCCESS, payload: T, }
  | { type: ReducerActions.FAILED }
  );

type State<T> = {
  isLoading: boolean,
  data: T,
  isFailed?: boolean,
};

type Reducer<T> = (state: State<T>, action: Action<T>) => State<T>;

const getProjectActionsReducer = <T>(defaultValue: T): Reducer<T> => (
  (state: State<T>, action: Action<T>) => {
    switch (action.type) {
      case ReducerActions.LOADING:
        return { isLoading: true, data: defaultValue };
      case ReducerActions.SUCCESS:
        return { isLoading: false, data: action.payload };
      case ReducerActions.FAILED:
        return { isLoading: false, data: defaultValue, isFailed: true };
      default:
        return state;
    }
  }
);

const defaultFormatter = <T, P>(v: P): T => v as unknown as T;

// if `shouldFetchOnRequestDataChange` is true - keep in mind that
//   requestData should be same for rerender, if it doesn't changed (objects and arrays)
const useApi = <DataType, RequestParams = unknown, ApiReturnType = DataType>({
  api,
  requestData,
  formatter = defaultFormatter,
  defaultValue,
  shouldFetchOnRequestDataChange = false,
}: {
  api: (params?: RequestParams) => Promise<ApiReturnType>,
  requestData?: RequestParams,
  formatter?: (v: ApiReturnType) => DataType,
  defaultValue: DataType,
  shouldFetchOnRequestDataChange?: boolean,
}) => {
  const initialState: State<DataType> = { isLoading: true, data: defaultValue };

  const [result, dispatch] = useReducer(getProjectActionsReducer<DataType>(defaultValue), initialState);

  useEffect(() => {
    dispatch({ type: ReducerActions.LOADING });
    api(requestData)
      .then((data: ApiReturnType) => {
        const payload = formatter(data);
        dispatch({ type: ReducerActions.SUCCESS, payload });
      })
      .catch(() => dispatch({ type: ReducerActions.FAILED }));
  }, [shouldFetchOnRequestDataChange && requestData]);

  return result;
};

export default useApi;
