import { ProfessionalsAction } from '../constants';
import { SimpleUserType, UserRoles, UserStates } from '../utils';
import { OrNull } from '../types';

export type ProfessionalFreelancer = {
  id: number;
  user: SimpleUserType & {
    type: UserRoles,
    state: UserStates,
  }
};

export type ProfessionalsState = {
  data: ProfessionalFreelancer[],
  current_page: number,
  last_page: number,
  total: number,
  per_page: number,
};

const initialState: ProfessionalsState = {
  data: [],
  current_page: 0,
  last_page: 0,
  total: 0,
  per_page: 10,
};

type ProfessionalsActionType = {
  type: ProfessionalsAction.GET_PROFESSIONALS_SUCCESS,
  payload: ProfessionalsState,
} | {
  type: ProfessionalsAction.UPDATE_PROFESSIONAL_STATUS_SUCCESS,
  id: number,
  payload: UserStates,
} | {
  type: ProfessionalsAction.DECLINE_PROFESSIONAL_SUCCESS,
  payload: number,
} | {
  type: ProfessionalsAction.DEACTIVATE_PROFESSIONAL,
  payload: {
    id: number,
    state: UserStates,
    deactivation: OrNull<{
      deactivation_reason_id: OrNull<number>,
      deactivation_reason_note: OrNull<string>,
    }>
  },
};

const professionals = (state = initialState, action: ProfessionalsActionType): ProfessionalsState => {
  switch (action.type) {
    case ProfessionalsAction.GET_PROFESSIONALS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
        per_page: action.payload.per_page,
      };
    case ProfessionalsAction.UPDATE_PROFESSIONAL_STATUS_SUCCESS:
      return {
        ...state,
        data: state.data.map(item => (
          item.id !== action.id
            ? item
            : {
              ...item,
              user: {
                ...item.user,
                state: action.payload,
              },
            }),
        ),
      };
    case ProfessionalsAction.DECLINE_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload),
        total: state.total - 1 || 0,
      };
    case ProfessionalsAction.DEACTIVATE_PROFESSIONAL:
      return {
        ...state,
        data: state.data.map(item => (item.id !== action.payload.id ? item : {
          ...item,
          user: {
            ...item.user,
            state: action.payload.state,
          },
        })),
      };
    default:
      return state;
  }
};

export default professionals;
