import { getConstants } from './api-requests-only';
import { BackendConstantsKeys } from '../utils';
import { PermissionsListAction } from '../constants';
import { PermissionsList } from '../reducers/permissionsList';

export const getPermissions = () => (
  (dispatch) => (
    getConstants<{
      [BackendConstantsKeys.USER_ROLES]: PermissionsList
    }>({ keys: [BackendConstantsKeys.USER_ROLES] })
      .then(({ [BackendConstantsKeys.USER_ROLES]: permissions }) => {
        dispatch({ type: PermissionsListAction.SET_PERMISSIONS, payload: permissions });
        return permissions;
      })
  )
);
