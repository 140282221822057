export const GET_TEAM_WRITERS_SUCCESS = 'GET_TEAM_WRITERS_SUCCESS';
export const SEARCH_TEAM_WRITER_SUCCESS = 'SEARCH_TEAM_WRITER_SUCCESS';
export const ADD_WRITER_TO_TEAM_SUCCESS = 'ADD_WRITER_TO_TEAM_SUCCESS';
export const ADD_DESIGNER_TO_TEAM_SUCCESS = 'ADD_DESIGNER_TO_TEAM_SUCCESS';
export const REMOVE_WRITER_FROM_TEAM_SUCCESS = 'REMOVE_WRITER_FROM_TEAM_SUCCESS';
export const SET_PAGE_ADD_WRITER_TO_TEAM_SUCCESS = 'SET_PAGE_ADD_WRITER_TO_TEAM_SUCCESS';
export const GET_REQUESTS_TEAM_WRITERS_URL = 'GET_REQUESTS_TEAM_WRITERS_URL';
export const GET_TEAM_DESIGNERS_SUCCESS = 'GET_TEAM_DESIGNERS_SUCCESS';
export const GET_REQUESTS_TEAM_DESIGNER_URL = 'GET_REQUESTS_TEAM_DESIGNER_URL';
export const SET_PAGE_ADD_DESIGNER_TO_TEAM_SUCCESS = 'SET_PAGE_ADD_DESIGNER_TO_TEAM_SUCCESS';
export const REMOVE_DESIGNER_FROM_TEAM_SUCCESS = 'REMOVE_DESIGNER_FROM_TEAM_SUCCESS';
export const SEARCH_TEAM_DESIGNERS = 'SEARCH_TEAM_DESIGNERS';
export const SEARCH_TEAM_DESIGNERS_URL = 'SEARCH_TEAM_DESIGNERS_URL';
