import React, { useEffect } from 'react';
import { OrNull } from '../types';

type UseDynamicTextAreaHeightType = ({
  value,
  minHeight,
  maxHeight,
  ref,
}: {
  value: string,
  minHeight?: number,
  maxHeight: number,
  ref: React.MutableRefObject<OrNull<HTMLTextAreaElement>>,
}) => void;
const useDynamicTextAreaHeight: UseDynamicTextAreaHeightType = ({
  value, minHeight = 0, maxHeight, ref,
}) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      const scrollHeight = ref.current.scrollHeight || 0;
      // check min of two max values - scroll and maxHeight
      const newWithMaxHeight = Math.min(scrollHeight, maxHeight);
      // check max of two min values - new and minHeight
      const newHeight = Math.max(newWithMaxHeight, minHeight);
      ref.current.style.height = `${newHeight}px`;
    }
  }, [value]);
};

export default useDynamicTextAreaHeight;
