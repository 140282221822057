import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { DropdownType } from '../../types';
import { PermissionsList } from '../../reducers/permissionsList';
import { PermissionsObject } from './types';
import { getHasPermissionsObject } from './helpers';

export type UsePermissionsReturnType = {
  keys: PermissionsList,
  roles: PermissionsObject,
  originalRoles: PermissionsObject,
};
const usePermissions = (): UsePermissionsReturnType => {
  const roles: DropdownType[] = useSelector((state: RootState) => state.auth.user?.roles ?? []);
  const originalRoles: DropdownType[] = useSelector((state: RootState) => state.auth.user?.originalRoles ?? []);
  const allPermissions: PermissionsList = useSelector((state: RootState) => state.permissionsList.permissions);

  return {
    keys: allPermissions,
    roles: getHasPermissionsObject(roles, allPermissions),
    originalRoles: getHasPermissionsObject(originalRoles, allPermissions),
  };
};

export default usePermissions;
