import * as types from '../constants/rate-plans';

const initialState = {
  filtersLoading: true,
  freelancersPlansList: {
    defaultRatePlans: [],
    customRatePlans: [],
    archivedPlans: [],
  },
  freelancer: [],
  url: null,
  current_page: null,
  last_page: null,
  total: null,
  per_page: 10,
  allFilerFreelancer: [],
  financialStatus: [],
};

export default function freelancersPlans(state = initialState, action) {
  switch (action.type) {
    case types.GET_FREELANCERS_DEFAULT_PLANS: {
      return {
        ...state,
        freelancersPlansList: {
          ...state.freelancersPlansList,
          defaultRatePlans: action.payload,
        },
      };
    }
    case types.GET_FREELANCERS_CUSTOM_PLANS: {
      return {
        ...state,
        freelancersPlansList: {
          ...state.freelancersPlansList,
          customRatePlans: action.payload,
        },
      };
    }
    case types.REMOVE_FROM_CUSTOM_FREELANCER_PLANS_SUCCESS:
      return {
        ...state,
        freelancersPlansList: {
          ...state.freelancersPlansList,
          customRatePlans: {
            ...state.freelancersPlansList.customRatePlans,
            data: state.freelancersPlansList.customRatePlans.data.filter(item => item.id !== action.payload),
            total: state.freelancersPlansList.customRatePlans.total - 1,
          },
        },
      };
    case types.SET_FREELANCER_DEFAULT_PLANS_PAGE_SUCCESS:
      return {
        ...state,
        freelancersPlansList: {
          ...state.freelancersPlansList,
          defaultRatePlans: action.payload,
        },
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.GET_PLAN_FREELANCERS:
    case types.SET_FREELANCERS_PLAN_PAGE_SUCCESS:
      return {
        ...state,
        freelancer: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.START_FETCHING_FREELANCER_FILTERS_DATA:
      return {
        ...state,
        filtersLoading: true,
      };
    case types.GET_FREELANCERS_PAGE_PLANS_URL:
    case types.SET_FREELANCERS_PAGE_PLANS_URL:
    case types.GET_PLAN_FREELANCERS_PAGE_URL:
    case types.SET_PLAN_FREELANCERS_PAGE_URL:
      return { ...state, url: action.payload };
    case types.GET_FREELANCER_FILTERS_DATA_SUCCESS:
      return {
        ...state,
        allFilerFreelancer: action.payload.freelancer,
        financialStatus: action.payload.financialStatus,
        filtersLoading: false,
      };
    case types.GET_FREELANCER_FILTERS_DATA_FAILED: {
      return {
        ...state,
        allFilerFreelancer: [],
        financialStatus: [],
        filtersLoading: false,
      };
    }
    default:
      return state;
  }
}
