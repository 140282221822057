export const GET_DESIGN_PROJECT_BY_ID_SUCCESS = 'GET_DESIGN_PROJECT_BY_ID_SUCCESS';
export const SAVE_DESIGN_PROJECT = 'SAVE_DESIGN_PROJECT';
export const UPDATE_DESIGN_PROJECT_SUCCESS = 'UPDATE_DESIGN_PROJECT_SUCCESS';
export const TRANSFER_TO_DESIGNER_SUCCESS = 'TRANSFER_TO_DESIGNER_SUCCESS';
export const REMOVE_DESIGN_FILE_SUCCESS = 'REMOVE_DESIGN_FILE_SUCCESS';
export const ADD_DESIGN_FILE_SUCCESS = 'ADD_DESIGN_FILE_SUCCESS';
export const DELETE_DESIGN_PROJECT_SUCCESS = 'DELETE_DESIGN_PROJECT_SUCCESS';
export const ACCEPT_DESIGN_PROJECT_SUCCESS = 'ACCEPT_DESIGN_PROJECT_SUCCESS';
export const UNACCEPT_DESIGN_PROJECT = 'UNACCEPT_DESIGN_PROJECT';
export const UPLOAD_DESIGN_DRAFT_SUCCESS = 'UPLOAD_DESIGN_DRAFT_SUCCESS';
export const DELETE_DESIGN_DRAFT_SUCCESS = 'DELETE_DESIGN_DRAFT_SUCCESS';
export const FINISH_DESIGN_PROJECT_SUCCESS = 'FINISH_DESIGN_PROJECT_SUCCESS';
export const SEND_DRAFT_MESSAGE_SUCCESS = 'SEND_DRAFT_MESSAGE_SUCCESS';
export const GET_LIST_OF_DESIGN_PROJECT_MESSAGES_SUCCESS = 'GET_LIST_OF_DESIGN_PROJECT_MESSAGES_SUCCESS';
export const SEND_DESIGN_PROJECT_MESSAGE_SUCCESS = 'SEND_DESIGN_PROJECT_MESSAGE_SUCCESS';
export const DESIGN_DRAFT_READY_SUCCESS = 'DESIGN_DRAFT_READY_SUCCESS';
export const FEEDBACK_DESIGN_DELIVERED_SUCCESS = 'FEEDBACK_DESIGN_DELIVERED_SUCCESS';
export const CLEAR_DESIGN_PROJECT_DATA = 'CLEAR_DESIGN_PROJECT_DATA';
export const UPLOAD_DESIGN_FEEDBACK_SUCCESS = 'UPLOAD_DESIGN_FEEDBACK_SUCCESS';
export const UPLOAD_SUBMIT_DESIGN_DRAFT_SUCCESS = 'UPLOAD_SUBMIT_DESIGN_DRAFT_SUCCESS';
export const DELETE_SUBMIT_DESIGN_DRAFT_SUCCESS = 'DELETE_SUBMIT_DESIGN_DRAFT_SUCCESS';
export const DELETE_SUBMIT_DESIGN_FEEDBACK_SUCCESS = 'DELETE_SUBMIT_DESIGN_FEEDBACK_SUCCESS';
