export const ACTIVE_PROJECT_PAGINATION_KEY = 'activeProjectPerPage';
export const CONCEPT_BRIEFINGS_PAGINATION_KEY = 'conceptBriefingsPerPage';
export const FINISHED_PROJECT_PAGINATION_KEY = 'finishedProjectPerPage';

export const ACTIVE_PROJECT_SORTING_KEY = 'activeProjectSortBy';
export const FINISHED_PROJECT_SORTING_KEY = 'finishedProjectSortBy';

export const PROJECT_SORTING_IDS = {
  NEWEST_ON_TOP: 'newest_on_top',
  OLDEST_ON_TOP: 'oldest_on_top',
  UPCOMING_DEADLINE_FIRST: 'upcoming_deadline_first',
  FURTHEST_DEADLINE_FIRST: 'furthest_deadline_first',
  DRAFT_FIRST: 'draft_first',
  ACTION_BASED_FIRST: 'action_based_first',

  DEFAULT: 'action_based_first',
};
