import { getProjectTypes } from './project-types';
import { getAlertInfo, showUpdateBar } from '../alert-baner-on-login';

function getApiData() {
  return (dispatch) => {
    dispatch(getProjectTypes());
    dispatch(showUpdateBar());
    dispatch(getAlertInfo());
  };
}

export default getApiData;
