export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_CLIENT_USERS_SUCCESS = 'GET_CLIENT_USERS_SUCCESS';
export const GET_SIGNUPS_CLIENTS_SUCCESS = 'GET_SIGNUPS_CLIENTS_SUCCESS';
export const GET_SIGNUPS_CLIENTS_URL = 'GET_SIGNUPS_CLIENTS_URL';
export const GET_ACTIVE_CLIENTS_SUCCESS = 'GET_ACTIVE_CLIENTS_SUCCESS';
export const GET_ACTIVE_CLIENTS_URL = 'GET_ACTIVE_CLIENTS_URL';
export const GET_INACTIVE_CLIENTS_SUCCESS = 'GET_INACTIVE_CLIENTS_SUCCESS';
export const GET_INACTIVE_CLIENTS_URL = 'GET_INACTIVE_CLIENTS_URL';
export const SEARCH_CLIENT_SUCCESS = 'SEARCH_CLIENT_SUCCESS';
export const SET_PAGE_CLIENTS_PAGE_SUCCESS = 'SET_PAGE_CLIENTS_PAGE_SUCCESS';
export const ACCEPT_CLIENT_SUCCESS = 'ACCEPT_CLIENT_SUCCESS';

export const DECLINE_CLIENT_SUCCESS = 'DECLINE_CLIENT_SUCCESS';

export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const GET_CSM_LIST = 'GET_CSM_LIST';
export const GET_CLIENTS_URL = 'GET_CLIENTS_URL';
export const GET_CLIENT = 'GET_CLIENT';
