import { UserRoles } from '../types';

const FREELANCER_ROLES = {
  WRITER: UserRoles.WRITER,
  DESIGNER: UserRoles.DESIGNER,
  EXTERNAL: 'EXTERNAL',
};

export {
  FREELANCER_ROLES,
};
