export const GET_PROJECT_BY_ID_SUCCESS = 'GET_PROJECT_BY_ID_SUCCESS';
export const GET_PROJECT_FINANCIAL_SUCCESS = 'GET_PROJECT_FINANCIAL_SUCCESS';
export const UPDATE_PROJECT_FINANCIAL_SUCCESS = 'UPDATE_PROJECT_FINANCIAL_SUCCESS';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const DOWNLOAD_TRANSLATED_FILES = 'DOWNLOAD_TRANSLATED_FILES';
export const TRANSFER_TO_FREELANCER_SUCCESS = 'TRANSFER_TO_FREELANCER_SUCCESS';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const ACCEPT_PROJECT_SUCCESS = 'ACCEPT_PROJECT_SUCCESS';
export const UNACCEPT_PROJECT = 'UNACCEPT_PROJECT';
export const DRAFT_READY_SUCCESS = 'DRAFT_READY_SUCCESS';
export const FEEDBACK_DELIVERED_SUCCESS = 'FEEDBACK_DELIVERED_SUCCESS';
export const DECLINE_PROJECT_SUCCESS = 'DECLINE_PROJECT_SUCCESS';
export const UPLOAD_DRAFT_SUCCESS = 'UPLOAD_DRAFT_SUCCESS';
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS';
export const DELIVER_FEEDBACK = 'DELIVER_FEEDBACK';
export const DISABLE_ACTIVE_FEEDBACKCARD = 'DISABLE_ACTIVE_FEEDBACKCARD';
export const UPLOAD_FEEDBACK_SUCCESS = 'UPLOAD_FEEDBACK_SUCCESS';
export const DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS';
export const FINISH_PROJECT_SUCCESS = 'FINISH_PROJECT_SUCCESS';
export const FINISH_GOOGLE_DOCS_PROJECT_SUCCESS = 'FINISH_GOOGLE_DOCS_PROJECT_SUCCESS';
export const GET_LIST_OF_MESSAGES_SUCCESS = 'GET_LIST_OF_MESSAGES_SUCCESS';
export const SET_DRAFT_MESSAGE = 'SET_DRAFT_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const MARK_AS_READ = 'MARK_AS_READ';
export const FINISH_SUCCESS = 'FINISH_SUCCESS';
export const DELETE_DRAFTS_SUCCESS = 'DELETE_DRAFTS_SUCCESS';
export const CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA';
export const GET_FREELANCER_EVALUATIONS = 'GET_FREELANCER_EVALUATIONS';
export const UPDATE_FREELANCER_EVALUATIONS = 'UPDATE_FREELANCER_EVALUATIONS';
export const START_NEW_EVALUATION = 'START_NEW_EVALUATION';
export const FINISH_EVALUATION = 'FINISH_EVALUATION';
export const UPDATE_PROJECT_DETAILS_PARTIALLY = 'UPDATE_PROJECT_DETAILS_PARTIALLY';
