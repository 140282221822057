import * as types from '../constants/core';

const initialState = {
  alert: { isVisible: false, message: '' },
  platformUpdate: {
    isVisible: false, text: '', link: '', id: null,
  },
  updatingLoader: { isUpdating: false },
};

export default function core(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_HIDE_ALERT:
      return { ...state, alert: action.payload };
    case types.SHOW_HIDE_PLATFORM_UPDATE:
      return {
        ...state,
        platformUpdate: {
          ...state.platformUpdate,
          isVisible: action.payload,
        },
      };
    case types.SET_PLATFORM_UPDATE_TEXT:
      return {
        ...state,
        platformUpdate: {
          ...state.platformUpdate,
          text: action.payload,
        },
      };
    case types.SET_PLATFORM_UPDATE_LINK:
      return {
        ...state,
        platformUpdate: {
          ...state.platformUpdate,
          link: action.payload,
        },
      };
    case types.SET_PLATFORM_UPDATE_ID:
      return {
        ...state,
        platformUpdate: {
          ...state.platformUpdate,
          id: action.payload,
        },
      };
    case types.SET_ISUPDATING_MODE:
      return {
        ...state,
        updatingLoader: { isUpdating: true },
      };
    case types.CANCEL_UPDATING_MODE:
      return {
        ...state,
        updatingLoader: { isUpdating: false },
      };
    /* case types.SHOW_HIDE_WARNING:
      return { ...state, warningBar: action.payload }; */
    default:
      return state;
  }
}
