import * as types from '../constants/freelancer-document-list';

const initialState = {
  documents: [],
};

export default function freelancerFinancialOverview(state = initialState, action) {
  switch (action.type) {
    case types.GET_FREELANCER_DOCUMENTS_SUMMARY: {
      return {
        documents: action.payload,
      };
    }
    default:
      return state;
  }
}
