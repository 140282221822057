import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';

import App from './components/App';
import { unregister } from './registerServiceWorker';

// styles
import './assets/styles/index.scss';

(process.env.NODE_ENV !== 'production') && console.info(process.env.NODE_ENV, 'environment');

if (process.env.NODE_ENV === 'production') {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
  SENTRY_DSN && Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App />);
unregister();
