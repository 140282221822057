import * as types from '../constants';

const initialState = {
  copywriters: [],
  isNewCreated: false,
  showBriefingTab: false,
};

export default function newBrainstorm(state = initialState, action) {
  switch (action.type) {
    case types.GET_BRAINSTORM_FLOW_WRITERS_SUCCESS: {
      return {
        ...state,
        copywriters: action.payload,
      };
    }
    case types.CREATE_NEW_BRAINSTORM_SUCCESS: {
      return {
        ...state,
        isNewCreated: true,
        showBriefingTab: true,
      };
    }
    case types.MARK_AS_CREATED: {
      return {
        ...state,
        isNewCreated: false,
      };
    }
    default:
      return state;
  }
}
