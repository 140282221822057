import * as types from '../constants';

const initialState = {
  verifiedPhoneBySms: false,
  verifiedPhoneByCode: false,
  notifications: {
    phoneVerified: false,
    setting: [],
  },
  errors: {
    phoneNumberError: '',
    verificationCodeError: '',
  },
};

export default function whatsappNotifications(state = initialState, action) {
  switch (action.type) {
    case types.VERIFICATION_SMS_SUCCESS:
      return {
        ...state,
        verifiedPhoneBySms: action.payload,
        notifications: {
          ...state.notifications,
          phoneVerified: action.payload,
        },
      };
    case types.VERIFY_PHONE_NUMBER:
      return {
        ...state,
        verifiedPhoneByCode: action.payload,
        notifications: {
          ...state.notifications,
          phoneVerified: action.payload,
        },
      };
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case types.SEND_VERIFICATION_ERROR:
      return {
        ...state,
        verifiedPhoneByCode: false,
        errors: {
          ...state.errors,
          phoneNumberError: action.payload,
        },
      };
    case types.VERIFY_PHONE_NUMBER_ERROR:
      return {
        ...state,
        verifiedPhoneBySms: false,
        errors: {
          ...state.errors,
          verificationCodeError: action.payload,
        },
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        verifiedPhoneBySms: false,
        verifiedPhoneByCode: false,
        errors: {
          phoneNumberError: '',
          verificationCodeError: '',
        },
      };
    default:
      return state;
  }
}
