import { useEffect, useState } from 'react';
import { SelectedMultipleFilter, SelectedMultipleFilters } from '../utils';
import { OrNull } from '../types';

type UseFiltersParams<T> = {
  defaultValue?: OrNull<T>,
  shouldMatchWithURL?: boolean,
  shouldGetDefaultValueFromURL?: boolean,
  shouldResetPageOnFilterChange?: boolean,
  onFiltersChange?: (filters: Partial<T>) => void,
  arrayFieldNames?: string[],
  numberedFieldNames?: string[],
};

type UseFiltersReturnType<T> = {
  filters: OrNull<Partial<T>>,
  applyFilter: (filter: Partial<T>) => void,
};

const updateSearchParams = <T extends { [s: string]: unknown }>(filters: T): void => {
  const nonEmptyFilersParams = Object.fromEntries(
    Object.entries(filters)
      .filter(([, value]) => (Array.isArray(value) ? value.length : value))
      .map(([key, value]) => {
        const paramValue = Array.isArray(value) ? value.join(',') : String(value);
        return [key, paramValue];
      }),
  );

  const searchParams: string = new URLSearchParams(nonEmptyFilersParams).toString();
  const query: string = searchParams ? `?${searchParams}` : '';
  window.history.pushState({}, '', `${window.location.pathname}${query}`);
};

// defaultValue should be null if it's not set
// onFiltersChange will be called only when filters will exist
// filters will be null if default value not set
const useFilters = <FiltersType extends SelectedMultipleFilters>({
  defaultValue = null,
  shouldMatchWithURL = false,
  shouldGetDefaultValueFromURL = false,
  shouldResetPageOnFilterChange = true,
  onFiltersChange,
  arrayFieldNames = [],
  numberedFieldNames = [],
}: UseFiltersParams<FiltersType> = {}): UseFiltersReturnType<FiltersType> => {
  const [filters, setFilters] = useState<OrNull<Partial<FiltersType>>>(defaultValue);
  // update filters if default value changed
  useEffect(() => {
    setFilters(defaultValue);
  }, [defaultValue]);

  // set initial filters value from url on mount if not set in defaultValues
  useEffect(() => {
    if (shouldGetDefaultValueFromURL && !defaultValue) {
      const locationSearch: string = window.location.search;
      const searchParams = new URLSearchParams(locationSearch);

      const filtersFromUrl: FiltersType = [...searchParams.keys()]
        .reduce<FiltersType>((urlFilters, filterKey) => {
        const isArrayValue: boolean = arrayFieldNames.includes(filterKey);
        const isNumberValue: boolean = numberedFieldNames.includes(filterKey);
        const convertToNumberIfNeed = <T>(value: T): number | T => (isNumberValue ? +value : value);

        // we use get all to join all params to one key
        const valueFromParam = searchParams.getAll(filterKey).join(',');

        const valueToSet: SelectedMultipleFilter = isArrayValue
          ? valueFromParam.split(',').map(convertToNumberIfNeed)
          : convertToNumberIfNeed(valueFromParam);

        return ({
          ...urlFilters,
          [filterKey]: valueToSet,
        });
      }, {} as FiltersType);

      setFilters(filtersFromUrl);
    }
  }, []);

  // handle filters change
  useEffect(() => {
    if (filters) {
      onFiltersChange?.(filters);
      shouldMatchWithURL && updateSearchParams(filters);
    }
  }, [filters]);

  // HOOK METHODS
  const applyFilter = (filter: Partial<FiltersType>): void => {
    const resetPageObj = shouldResetPageOnFilterChange ? { page: 1 } : {};
    setFilters((prevFilters) => ({
      ...(prevFilters ?? {} as FiltersType),
      ...resetPageObj,
      ...filter,
    }));
  };

  return {
    filters,
    applyFilter,
  };
};

export default useFilters;
