import * as types from '../constants';

export function showHideWarning(data) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_HIDE_WARNING,
      payload: data,
    });
  };
}
