import mixpanel from 'mixpanel-browser';
import { MIXPANEL_PROJECT_TOKEN } from '../constants';
import { isNotEmpty } from './utils';
import { StringNumberType } from './projectAttachments';

export const track = (eventName: string, value?: any): void => {
  const params: [string, { value: any }?] = [eventName];
  isNotEmpty(value) && params.push({ value });
  MIXPANEL_PROJECT_TOKEN && mixpanel.track(...params);
};

export const identifyUser = (userId: StringNumberType, options: Record<string, any> = {}): void => {
  MIXPANEL_PROJECT_TOKEN && mixpanel.identify(`${userId}`, options);
};
