import { client } from '../utils';
import sentryLogger from '../services/sentry';
import * as types from '../constants';

export function showUpdateBar() {
  return (dispatch) => {
    const infoAlertText = localStorage.getItem('info_alert_text');
    const infoAlertLink = localStorage.getItem('info_alert_link');
    const showAlert = !sessionStorage.getItem('hideUpdateBar') && Boolean(localStorage.getItem('info_alert_active'));

    dispatch({
      type: types.SHOW_HIDE_PLATFORM_UPDATE,
      payload: showAlert,
    });

    dispatch({
      type: types.SET_PLATFORM_UPDATE_TEXT,
      payload: infoAlertText,
    });

    dispatch({
      type: types.SET_PLATFORM_UPDATE_LINK,
      payload: infoAlertLink,
    });
  };
}

export function getAlertInfo() {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = '/alerts';

    return client.get(url)
      .then(data => {
        if (data.data.success) {
          const infoAlert = data.data.result.find(item => item.type === 'alert-info');
          if (infoAlert) {
            dispatch({
              type: types.SET_PLATFORM_UPDATE_ID,
              payload: infoAlert.id,
            });

            localStorage.setItem('info_alert_text', infoAlert.text);
            localStorage.setItem('info_alert_link', infoAlert.read_more);
            infoAlert.active === '1' && localStorage.setItem('info_alert_active', infoAlert.active);
            const sessionHideBar = Boolean(sessionStorage.getItem('hideUpdateBar'));

            dispatch({
              type: types.SHOW_HIDE_PLATFORM_UPDATE,
              payload: (infoAlert.active && !sessionHideBar),
            });

            dispatch({
              type: types.SET_PLATFORM_UPDATE_TEXT,
              payload: infoAlert.text,
            });

            dispatch({
              type: types.SET_PLATFORM_UPDATE_LINK,
              payload: infoAlert.read_more,
            });
          }
        }
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
      });
  };
}
