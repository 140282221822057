const STATUSES = {
  INCOMPLETE_SIGN_UP: 'Incomplete sign up',
  WAITING_FOR_EVALUATION: 'Waiting for evaluation',
  EVALUATION_IN_PROGRESS: 'Evaluation in progress',
  EVALUATOR_DECLINED_PROJECT: 'Evaluator Declined project',
  EVALUATION_COMPLETE: 'Evaluation Complete',
  EVALUATION_DISAPPROVED: 'Evaluation disapproved',
  WAITING_FOR_ONBOARDING: 'Waiting for onboarding',
  WAIT_FOR_ACCEPTANCE: 'Wait for acceptance',
  CONNECT_RATE_PLAN: 'Connect rate plan',
  ACTIVATE: 'Activate',
  ACTIVATED: 'Activated',
  ACCEPTED_PROJECT: 'Evaluator accept project',
  WAITING_LIST: 'Waiting list',
  PENDING: 'PENDING',
};

const STATUSES_IDS = {
  [STATUSES.INCOMPLETE_SIGN_UP]: 1,
  [STATUSES.WAITING_FOR_EVALUATION]: 2,
  [STATUSES.EVALUATION_IN_PROGRESS]: 3,
  [STATUSES.EVALUATOR_DECLINED_PROJECT]: 4,
  [STATUSES.EVALUATION_COMPLETE]: 5,
  [STATUSES.EVALUATION_DISAPPROVED]: 6,
  [STATUSES.WAITING_FOR_ONBOARDING]: 7,
  [STATUSES.CONNECT_RATE_PLAN]: 8,
  [STATUSES.ACTIVATE]: 9,
  [STATUSES.ACTIVATED]: 10,
  [STATUSES.WAITING_LIST]: 11,
  [STATUSES.ACCEPTED_PROJECT]: 12,
  [STATUSES.PENDING]: 13,
  // this is custom front-end status for progressbar
  [STATUSES.WAIT_FOR_ACCEPTANCE]: 999,
};

/* const STATUS_TITLES = {
  /!* INCOMPLETE_SIGN_UP: 'Incomplete sign up',
  WAITING_LIST: 'Waiting list',
  ACCEPTED_PROJECT: 'In evaluation',
  EVALUATOR_DECLINED_PROJECT: 'reassign evaluator',
  WAITING_FOR_EVALUATION: 'assign evaluator',
  EVALUATION_IN_PROGRESS: 'Wait for acceptance',
  WAIT_FOR_ACCEPTANCE: 'Wait for acceptance',
  EVALUATION_COMPLETE: 'review evaluation',
  EVALUATION_DISAPPROVED: 'Disapproved',
  // activate freelancer
  CONNECT_RATE_PLAN: 'activate freelancer',
  WAITING_FOR_ONBOARDING: 'activate freelancer',
  ACTIVATE: 'activate freelancer',
  // activate freelancer END
  ACTIVATED: '', *!/

  INCOMPLETE_SIGN_UP: 'Incomplete sign up',
  WAITING_FOR_EVALUATION: 'Assign evaluator',
  EVALUATION_IN_PROGRESS: 'Waiting for acceptance',
  EVALUATOR_DECLINED_PROJECT: 'Re-assign evaluator',
  ACCEPTED_PROJECT: 'In evaluation',
  EVALUATION_COMPLETE: 'Review evaluation',
  // activate freelancer
  CONNECT_RATE_PLAN: 'Activate freelancer',
  WAITING_FOR_ONBOARDING: 'Activate freelancer',
  ACTIVATE: 'Activate freelancer',
  // activate freelancer END
  WAITING_LIST: 'Waiting list',
  // WAIT_FOR_ACCEPTANCE: 'Wait for acceptance',
  // EVALUATION_DISAPPROVED: 'Disapproved',
  // ACTIVATED: '',
}; */

const ACTIVATE_STATUS_ID_ARRAY = [
  STATUSES_IDS[STATUSES.CONNECT_RATE_PLAN],
  STATUSES_IDS[STATUSES.WAITING_FOR_ONBOARDING],
  STATUSES_IDS[STATUSES.ACTIVATE],
];
const SIGNUPS_STATUS_FILTER_ITEMS = [
  { id: STATUSES_IDS[STATUSES.INCOMPLETE_SIGN_UP], name: 'Incomplete sign up' },
  { id: STATUSES_IDS[STATUSES.WAITING_FOR_EVALUATION], name: 'Assign evaluator' },
  { id: STATUSES_IDS[STATUSES.EVALUATION_IN_PROGRESS], name: 'Waiting for acceptance' },
  { id: STATUSES_IDS[STATUSES.EVALUATOR_DECLINED_PROJECT], name: 'Re-assign evaluator' },
  { id: STATUSES_IDS[STATUSES.ACCEPTED_PROJECT], name: 'In evaluation' },
  { id: STATUSES_IDS[STATUSES.EVALUATION_COMPLETE], name: 'Review evaluation' },
  { id: ACTIVATE_STATUS_ID_ARRAY, name: 'Activate freelancer' },
  { id: STATUSES_IDS[STATUSES.WAITING_LIST], name: 'Waiting list' },
  { id: STATUSES_IDS[STATUSES.PENDING], name: 'Pending' },
];

export {
  STATUSES,
  STATUSES_IDS,
  SIGNUPS_STATUS_FILTER_ITEMS,
};
