import { CopywritersFiltersType } from '../../components/dashboard/components/FreelancersTab/Copywriters/interfaces';
import {
  appendDefaultFilters, client, formatFiltersValue, SelectedMultipleFilters, ToolsType,
} from '../../utils';

export const getDesignTools = (): Promise<ToolsType[]> => (
  client.get('/design-tool')
    .then(({ data: { result } }) => result)
);

const defaultPage: number = 1;
export const defaultResultsPerPage: number = 12;
export const defaultOrderBy: string = 'average_margin';
export const defaultSort: string = 'desc';

const defaultDesignersFilters: SelectedMultipleFilters = {
  page: defaultPage,
  resultsPerPage: defaultResultsPerPage,
  orderBy: defaultOrderBy,
  sort: defaultSort,
};

export const getAllDesignersWithFiltersApi = (filters: Partial<CopywritersFiltersType> = {}) => {
  const defaultState: string[] = ['ACTIVE', 'INACTIVE'];
  const shouldChangeStateArray = Array.isArray(filters.state) && filters.state.length === 0;
  const updatedFilters: Partial<CopywritersFiltersType> = { ...filters };
  shouldChangeStateArray && (updatedFilters.state = defaultState);

  const filtersWithDefaultValues = appendDefaultFilters(
    updatedFilters,
    { ...defaultDesignersFilters, state: defaultState },
  );
  const url = 'designer';
  return client.get(url, { params: formatFiltersValue(filtersWithDefaultValues) })
    .then(({ data: { result } }) => result);
};

export const getArchivedDesignersWithFiltersApi = (filters: Partial<CopywritersFiltersType> = {}): Promise<void> => {
  const defaultState: string[] = ['ARCHIVE'];
  const updatedFilters: Partial<CopywritersFiltersType> = { ...filters, state: defaultState };

  const filtersWithDefaultValues = appendDefaultFilters(
    updatedFilters,
    defaultDesignersFilters,
  );
  const url = 'designer';
  return client.get(url, { params: formatFiltersValue(filtersWithDefaultValues) })
    .then(({ data: { result } }) => result);
};
