import * as types from '../constants';

const initialState = [];

export default function industrySpecialties(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_INDUSTRIES_SPECIALTIES_SUCCESS:
    case types.GET_SPECIALTIES_BY_INDUSTRIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
