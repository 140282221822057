import * as types from '../../constants';
import { client } from '../../utils';

export function getProjectTypes() {
  return (dispatch) => {
    client.get('/service')
      .then(data => {
        dispatch({
          type: types.GET_PROJECT_TYPE_SUCCESS,
          payload: data.data.result,
        });
      })
      .catch(error => {
        dispatch({
          type: types.GET_PROJECT_TYPE_FAILURE,
          payload: error,
        });
      });
  };
}
