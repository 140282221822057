import * as types from '../constants';

const initialState = [];

export default function notes(state = initialState, action) {
  switch (action.type) {
    case types.GET_NOTES:
      return action.payload;
    default:
      return state;
  }
}
