export const GET_MY_BRAINSTORMS_SUCCESS = 'GET_MY_BRAINSTORMS_SUCCESS';
export const GET_MY_BRAINSTORMS_URL = 'GET_MY_BRAINSTORMS_URL';
export const GET_ACTIVE_BRAINSTORMS_SUCCESS = 'GET_ACTIVE_BRAINSTORMS_SUCCESS';
export const GET_ACTIVE_BRAINSTORMS_URL = 'GET_MY_BRAINSTORMS_URL';
export const GET_ALL_BRAINSTORMS_SUCCESS = 'GET_ALL_BRAINSTORMS_SUCCESS';
export const GET_ALL_BRAINSTORMS_URL = 'GET_MY_BRAINSTORMS_URL';
export const GET_FINISHED_BRAINSTORMS_SUCCESS = 'GET_FINISHED_BRAINSTORMS_SUCCESS';
export const GET_FINISHED_BRAINSTORMS_URL = 'GET_FINISHED_BRAINSTORMS_URL';
export const SEARCH_BRAINSTORMS_SUCCESS = 'SEARCH_BRAINSTORMS_SUCCESS';
export const SEARCH_BRAINSTORMS_URL = 'SEARCH_BRAINSTORMS_URL';
export const SORT_BRAINSTORMS_SUCCESS = 'SORT_BRAINSTORMS_SUCCESS';
export const SORT_BRAINSTORMS_URL = 'SORT_BRAINSTORMS_URL';
export const FILTER_BRAINSTORMS_BY_SUCCESS = 'FILTER_BRAINSTORMS_BY_SUCCESS';
export const FILTER_BRAINSTORMS_URL = 'FILTER_BRAINSTORMS_URL';
export const SET_PAGE_BRAINSTORM_PAGE_SUCCESS = 'SET_PAGE_BRAINSTORM_PAGE_SUCCESS';
// in projects.js the same const
// export const SET_PAGE_PROJECTS_PAGE_URL = 'SET_PAGE_PROJECTS_PAGE_URL';
export const RESULT_PER_BRAINSTORMS_PAGE_SUCCESS = 'RESULT_PER_BRAINSTORMS_PAGE_SUCCESS';
