import { combineReducers } from 'redux';
import auth from './auth';
import projects from './projects';
import clients from './clients';
import designers from './designers';
import copywriters from './copy-writers';
import { languages, languagesById } from './languages';
import services from './services';
import { tools, toolsById } from './tools';
import industries from './industries';
import { projectTypeById, projectTypes } from './project-type';
import specialties from './specialties';
import industrySpecialties from './industry-specialties';
import brainstorms from './brainstorms';
import admin from './admin';
import projectFlow from './project-flow';
import projectDetails from './project-details';
import designDetails from './desing-details';
import userProfile from './user-profile';
import freelancerFinancial from './freelancer-financial';
import freelancerDocumentList from './freelancer-document-list';
import newBrainstorm from './new-brainstorm';
import brainstormDetails from './brainstorm-details';
import contentTypes from './content-types';
import fetchingReducer from './fetching';
import alerts from './alerts';
import reviews from './reviews';
import ratePlans from './rate-plans';
import pricePlanTypes from './price-plan-types';
import core from './core';
import freelancers from './freelancers';
import projectOverview from './projects-overview-new';
import newFreelancer from './new-freelancer';
import freelancersPlans from './freelancer-plans';
import loaderReducer from './loader';
import clientPlans from './client-plans';
import clientFinancialOverview from './client-financial-overview-new';
import freelancerFinancialOverview from './freelancer-overview-tab-new';
import notes from './notes';
import { professionalServicesDesign } from './professional-service-design';
import professionals from './professionals';
import planHistory from './plan-history';
import warningBar from './warning-bar';
import financialTypes from './financial-types';
import signups from './sign-ups';
import whatsappNotifications from './whatsapp-notifications';
import permissionsList from './permissionsList';

export default combineReducers({
  auth,
  permissionsList,
  projects,
  clients,
  copywriters,
  designers,
  languages,
  languagesById,
  toolsById,
  services,
  tools,
  projectTypes,
  projectTypeById,
  industries,
  specialties,
  industrySpecialties,
  brainstorms,
  admin,
  reviews,
  projectFlow,
  projectDetails,
  designDetails,
  userProfile,
  freelancerFinancial,
  freelancerDocumentList,
  newBrainstorm,
  brainstormDetails,
  contentTypes,
  fetchingReducer,
  alerts,
  ratePlans,
  freelancersPlans,
  clientPlans,
  pricePlanTypes,
  core,
  freelancers,
  projectOverview, // New financials reducer
  newFreelancer,
  loaderReducer,
  clientFinancialOverview, // New financials reducer
  freelancerFinancialOverview, // New financials reducer
  notes,
  professionalServicesDesign,
  professionals,
  planHistory,
  warningBar,
  financialTypes,
  signups,
  whatsappNotifications,
});
