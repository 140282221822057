type ArrayPrimitiveValueType = string | number | boolean | null | undefined;

export const isPrimitive = (val: any): val is ArrayPrimitiveValueType => val !== Object(val);

type GetUniqueValuesType = <ArrayType>({ array, key }: { array: ArrayType[], key?: string }) => ArrayType[];
export const getUniqueValues: GetUniqueValuesType = <Type>({ array, key = '' }) => {
  const arrayForUnique = array.map(item => {
    const isPrimitiveValue = isPrimitive(item);
    const keyValue = key && !isPrimitiveValue ? item[key] : item;
    return [keyValue, item];
  });

  return [...new Map(arrayForUnique).values()] as Type[];
};

type GroupByKeyReturnType<ArrayItemType> = {
  [key in string]: ArrayItemType[]
};

export const groupByKey = <ArrayItemType>(
  array: ArrayItemType[],
  groupKey: string,
  defaultValue: string = `NO_${groupKey}`,
): GroupByKeyReturnType<ArrayItemType> => (
    array.reduce<GroupByKeyReturnType<ArrayItemType>>((grouped, item) => {
      const key = String(item?.[groupKey] ?? defaultValue);

      return ({
        ...grouped,
        [key]: [...(grouped[key] ?? []), item],
      });
    }, {} as GroupByKeyReturnType<ArrayItemType>)
  );

type GroupByKeyInArrayItem<ArrayItemType> = {
  id: (string | number),
  items: ArrayItemType[]
};
type GroupByKeyInArrayReturnType<ArrayItemType> = GroupByKeyInArrayItem<ArrayItemType>[];

export const groupByKeyInArray = <ArrayItemType>(
  array: ArrayItemType[],
  groupKey: string,
  defaultValue: string = `NO_${groupKey}`,
): GroupByKeyInArrayReturnType<ArrayItemType> => {
  const grouped = groupByKey(array, groupKey, defaultValue);
  return Object.entries(grouped).map(([key, value]) => ({ id: key, items: value }));
};

export const toggleValue = <V>(value: V, array: V[]): V[] => {
  const isValueExist = array.find(v => v === value);
  return isValueExist
    ? array.filter(v => v !== value)
    : [...array, value];
};

export const createArray = <V>(n: number, value: V): V[] => (
  [...Array(n).keys()].map(() => value)
);

type Collection<V = unknown, K = unknown> = Object | V[] | Set<V> | Map<K, V>;
export const isEmptyCollection = (collection: unknown): collection is Collection => {
  if (!collection) return true;

  if (collection instanceof Set) return collection.size === 0;
  if (collection instanceof Map) return collection.size === 0;
  if (Array.isArray(collection)) return collection.length === 0;
  if (typeof collection === 'object') return Object.values(collection).length === 0;
  return true;
};

export const joinWithoutDuplicates = <A, B>(a: A[], b: B[]): (A | B)[] => ([...new Set(([...a, ...b]))]);

export const uniqBy = <T>(array: T[], by: string | number | ((v: T, i?: number, a?: T[]) => string | number)): T[] => {
  const uniqValuesMap = new Map();

  array.forEach((value, index, arr) => {
    const groupKey = typeof by === 'function' ? by(value, index, arr) : value[by];
    !uniqValuesMap.has(groupKey) && uniqValuesMap.set(groupKey, value);
  });

  return [...uniqValuesMap.values()];
};
