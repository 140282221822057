import { ReviewsAction } from '../constants';
import { DropdownType, OrNull } from '../types';
import { NumberedBoolean } from '../utils';
import { Client } from '../components/dashboard/components/ReviewsTab/ReviewsContainer/Filter/interfaces';

export type ProjectFeedbacksReviewItemProject = {
  id: number,
  service_id: number,
  is_evaluation: NumberedBoolean,
  client: {
    super_admin: {
      name: string,
    },
    company: string,
    avatar: string,
  }
  client_user: {
    name: string,
  }
  name: string,
  designer: { user: { name: string } }
  writer: { user: { name: string } }
};

export type ProjectFeedbacksReviewItem = {
  id: number,
  rate: number,
  text: string,
  created_at: string,
  project?: OrNull<ProjectFeedbacksReviewItemProject>,
};

export type ProjectFeedbacks = {
  per_page: number;
  total: number;
  data: ProjectFeedbacksReviewItem[];
  last_page: number;
  current_page: number
};

export type FeedbacksTotals = {
  nps: number,
  distribution: {
    negative: number,
    neutral: number,
    positive: number,
  },
  trend: {
    [month: string]: { value: number }
  },
};

export type Reviews = {
  nps: number,
  distribution: {
    negative: number,
    neutral: number,
    positive: number,
  },
  trend: {
    [month: string]: { value: number }
  },
  projectFeedbacks: ProjectFeedbacks
};

export type ReviewsFiltersData = {
  client: Client[];
  projectFreelancer: DropdownType[];
  admin: DropdownType[];
};

type ReviewsLoadingFields = {
  isReviewsLoading: boolean;
};

export type ReviewsState = ReviewsFiltersData & ReviewsLoadingFields & {
  totals: FeedbacksTotals;
  feedbacks: ProjectFeedbacks;
};

const initialState: ReviewsState = {
  isReviewsLoading: true,
  totals: {
    nps: 0,
    distribution: {
      negative: 0,
      neutral: 0,
      positive: 0,
    },
    trend: {},
  },
  feedbacks: {
    data: [],
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  },
  client: [],
  projectFreelancer: [],
  admin: [],
};

type ReviewsActionType = {
  type: (
    ReviewsAction.DELETE_REVIEW_ITEM_SUCCESS
    | ReviewsAction.EDIT_REVIEW_ITEM_SUCCESS
    | ReviewsAction.GET_REVIEWS_TOTALS_SUCCESS
  )
  payload: Reviews
} | {
  type: ReviewsAction.GET_REVIEWS_LIST_SUCCESS,
  payload: ProjectFeedbacks
} | {
  type: ReviewsAction.SET_REVIEWS_FREELANCERS_FILTERS_DATA,
  payload: ReviewsFiltersData['projectFreelancer'],
} | {
  type: ReviewsAction.SET_REVIEWS_CLIENTS_FILTERS_DATA
  payload: ReviewsFiltersData['client'],
} | {
  type: ReviewsAction.STOP_REVIEWS_LOADING,
};

export default function reviews(state = initialState, action: ReviewsActionType): ReviewsState {
  switch (action.type) {
    case ReviewsAction.DELETE_REVIEW_ITEM_SUCCESS:
    case ReviewsAction.EDIT_REVIEW_ITEM_SUCCESS: {
      return {
        ...state,
        totals: action.payload,
        feedbacks: action.payload.projectFeedbacks,
        isReviewsLoading: false,
      };
    }
    case ReviewsAction.GET_REVIEWS_LIST_SUCCESS: {
      return {
        ...state,
        feedbacks: action.payload,
        isReviewsLoading: false,
      };
    }
    case ReviewsAction.GET_REVIEWS_TOTALS_SUCCESS: {
      return {
        ...state,
        totals: action.payload,
        isReviewsLoading: false,
      };
    }
    case ReviewsAction.SET_REVIEWS_FREELANCERS_FILTERS_DATA: {
      const { payload } = action;

      return {
        ...state,
        projectFreelancer: payload,
      };
    }
    case ReviewsAction.SET_REVIEWS_CLIENTS_FILTERS_DATA: {
      const { payload } = action;

      return {
        ...state,
        client: payload,
      };
    }
    case ReviewsAction.STOP_REVIEWS_LOADING: {
      return {
        ...state,
        isReviewsLoading: false,
      };
    }
    default:
      return state;
  }
}
