import { useEffect, useRef } from 'react';
import Echo from 'laravel-echo';
import createWebSocket from '../utils/webSocket';
import { OrNull } from '../types';

type UseWebSocketPropType = {
  chanel: string;
  event: string;
  handleEvent: Function;
  shouldConnect?: boolean;
};

const useWebSocket = ({
  chanel,
  event,
  handleEvent,
  shouldConnect = true,
}: UseWebSocketPropType) => {
  const webSocketRef = useRef<OrNull<Echo>>(null);
  useEffect(() => {
    if (shouldConnect) {
      // init ws connection
      webSocketRef.current = createWebSocket();

      // connect handle
      // it's always private for now
      webSocketRef.current
        .private(chanel)
        .listen(event, handleEvent);
    }

    // drop
    return () => {
      webSocketRef.current?.disconnect();
      webSocketRef.current = null;
    };
  }, [shouldConnect, chanel, event]);
};

export default useWebSocket;
