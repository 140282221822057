import * as types from '../constants';
import { STATUSES, STATUSES_IDS } from '../components/dashboard/components/FreelancersTab/SignupsListItem/constants';

const savedLimit = localStorage.getItem('list-limit');

const initialState = {
  signups: [],
  url: null,
  current_page: null,
  last_page: null,
  total: null,
  per_page: savedLimit || 10,
  evaluators: [],
};

export default function signups(state = initialState, action) {
  switch (action.type) {
    case types.GET_SIGNUPS_SUCCESS:
    case types.SEARCH_SIGNUPS_SUCCESS:
    case types.FILTER_SIGNUPS_BY_SUCCESS:
    case types.SET_PAGE_SIGNUPS_SUCCESS:
      return {
        ...state,
        signups: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.GET_SIGNUPS_URL:
    case types.SEARCH_SIGNUPS_URL:
    case types.FILTER_SIGNUPS_URL:
    case types.SET_SIGNUPS_PAGE_URL:
      return {
        ...state,
        url: action.payload,
      };
    case types.ACTIVATE_SIGNUP_SUCCESS:
      return {
        ...state,
        signups: state.signups.filter(item => item.id !== action.payload),
      };
    case types.UPDATE_SIGNUP_STATUS_SUCCESS:
      return {
        ...state,
        signups: state.signups.map(item => (item.id !== action.id ? item : {
          ...item,
          user: {
            ...item.user,
            state: action.payload,
          },
        })),
      };
      // TODO: need to impvore api response and remove mock data
    case types.ASSIGN_EVALUATOR_SUCCESS:
      return {
        ...state,
        signups: state.signups.map(item => (item.id !== action.freelancerId ? item : {
          ...item,
          status: {
            ...item.status,
            id: STATUSES_IDS[STATUSES.EVALUATION_IN_PROGRESS],
            title: STATUSES.EVALUATION_IN_PROGRESS,
          },
          status_id: STATUSES_IDS[STATUSES.EVALUATION_IN_PROGRESS],
          evaluate_project_id: action.projectId,
        })),
      };
    case types.ONBOARDING_PLANNED_SUCCESS:
      return {
        ...state,
        signups: state.signups.map(item => (item.id !== action.payload.freelancerId ? item : {
          ...item,
          status: {
            ...item.status,
            id: STATUSES_IDS[STATUSES.CONNECT_RATE_PLAN],
            title: STATUSES.CONNECT_RATE_PLAN,
          },
          status_id: STATUSES_IDS[STATUSES.CONNECT_RATE_PLAN],
        })),
      };
    case types.ASSIGN_PLAN_SUCCESS:
      return {
        ...state,
        signups: state.signups.map(item => (item.id !== action.payload.freelancerId ? item : {
          ...item,
          status: {
            ...item.status,
            id: STATUSES_IDS[STATUSES.ACTIVATE],
            title: STATUSES.ACTIVATE,
          },
          status_id: STATUSES_IDS[STATUSES.ACTIVATE],
        })),
      };
    case types.SET_SIGN_UPS_LIMIT:
      return {
        ...state,
        per_page: action.payload,
      };
    case types.UPDATE_WRITER_STATUS_SUCCESS:
      return {
        ...state,
        signups: state.signups.filter(item => item.id !== action.id),
      };
    default:
      return state;
  }
}
