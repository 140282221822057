const PROJECT_TYPES = {
  ONE_OFF_PROJECT: 'one-off-project',
  DESIGN: 'design',
  PROJECT: 'project',
  EVALUATION: 'evaluation-project',
};

const getProjectTypeByService = ({ serviceId, isEvaluation = false }) => {
  if (isEvaluation) return PROJECT_TYPES.EVALUATION;
  switch (serviceId) {
    case 7:
    case 8:
      return PROJECT_TYPES.ONE_OFF_PROJECT;
    case 5:
      return PROJECT_TYPES.DESIGN;
    default:
      return PROJECT_TYPES.PROJECT;
  }
};

const isProjectLinkValid = ({ serviceId, path, isEvaluation }) => {
  const type = getProjectTypeByService({ serviceId, isEvaluation });
  return path.split('/').some(pathPart => pathPart === type);
};

export {
  getProjectTypeByService,
  isProjectLinkValid,
};
