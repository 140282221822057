export const GET_DESIGNERS_SUCCESS = 'GET_DESIGNERS_SUCCESS';
export const GET_REQUESTS_DESIGNERS_URL = 'GET_REQUESTS_DESIGNERS_URL';
export const GET_ALL_DESIGNERS_SUCCESS = 'GET_ALL_DESIGNERS_SUCCESS';
export const GET_ALL_DESIGNERS_URL = 'GET_ALL_DESIGNERS_URL';
export const SEARCH_DESIGNERS_SUCCESS = 'SEARCH_DESIGNERS_SUCCESS';
export const FILTER_DESIGNERS_BY_SUCCESS = 'FILTER_DESIGNERS_BY_SUCCESS';
export const SET_PAGE_DESIGNERS_PAGE_SUCCESS = 'SET_PAGE_DESIGNERS_PAGE_SUCCESS';
export const ACCEPT_DESIGNERS_SUCCESS = 'ACCEPT_DESIGNERS_SUCCESS';
export const DECLINE_DESIGNERS_SUCCESS = 'DECLINE_DESIGNERS_SUCCESS';
export const SEARCH_DESIGNERS_URL = 'SEARCH_DESIGNERS_URL';
export const FILTER_DESIGNERS_URL = 'FILTER_DESIGNERS_URL';
export const SET_DESIGNERS_PAGE_URL = 'SET_DESIGNERS_PAGE_URL';
export const UPDATE_DESIGNERS_PER_PAGE = 'UPDATE_DESIGNERS_PER_PAGE';
export const UPDATE_DESIGNER_STATUS_SUCCESS = 'UPDATE_DESIGNER_STATUS_SUCCESS';
