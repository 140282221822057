import * as types from '../constants';

const initialState = {
  brainstorms: [],
  url: '',
  current_page: null,
  per_page: 10,
  total: null,
  last_page: null,
};

export default function brainstorms(state = initialState, action) {
  switch (action.type) {
    case types.GET_MY_BRAINSTORMS_SUCCESS:
    case types.GET_ACTIVE_BRAINSTORMS_SUCCESS:
    case types.GET_ALL_BRAINSTORMS_SUCCESS:
    case types.GET_FINISHED_BRAINSTORMS_SUCCESS:
    case types.SEARCH_BRAINSTORMS_SUCCESS:
    case types.SET_PAGE_BRAINSTORM_PAGE_SUCCESS:
    case types.RESULT_PER_BRAINSTORMS_PAGE_SUCCESS:
    case types.SORT_BRAINSTORMS_SUCCESS:
    case types.FILTER_BRAINSTORMS_BY_SUCCESS:
      return {
        ...state,
        brainstorms: action.payload.data,
        current_page: action.payload.current_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
        last_page: action.payload.last_page,
      };
    case types.GET_MY_BRAINSTORMS_URL:
    case types.GET_ACTIVE_BRAINSTORMS_URL:
    case types.GET_ALL_BRAINSTORMS_URL:
    case types.GET_FINISHED_BRAINSTORMS_URL:
    case types.SET_PAGE_PROJECTS_PAGE_URL:
    case types.SEARCH_BRAINSTORMS_URL:
    case types.SORT_BRAINSTORMS_URL:
    case types.FILTER_BRAINSTORMS_URL:
      return {
        ...state,
        url: action.payload,
      };
    default:
      return state;
  }
}
