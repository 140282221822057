import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ClientIdAndUserIdReturnType, getAuthUserId } from './helpers';

const useAuthUserId = (): ClientIdAndUserIdReturnType => {
  const store: RootState = useSelector((state: RootState) => state);

  return getAuthUserId(store);
};

export default useAuthUserId;
