export const GET_PROJECT_FLOW_WRITERS_SUCCESS = 'GET_PROJECT_FLOW_WRITERS_SUCCESS';
export const SET_PROJECT_FLOW_WRITERS_PAGE_URL = 'SET_PROJECT_FLOW_WRITERS_PAGE_URL';
export const GET_WRITER_BY_ID_SUCCESS = 'GET_WRITER_BY_ID_SUCCESS';
export const ASSIGN_WRITER = 'ASSIGN_WRITER';
export const UNASSIGN_WRITER = 'UNASSIGN_WRITER';
export const UNASSIGN_FREELANCER = 'UNASSIGN_FREELANCER';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const GET_PROJECT_FLOW_DESIGNERS_SUCCESS = 'GET_PROJECT_FLOW_DESIGNERS_SUCCESS';
export const GET_DESIGNER_BY_ID_SUCCESS = 'GET_DESIGNER_BY_ID_SUCCESS';
export const GET_FREE_WRITERS_SUCCESS = 'GET_FREE_WRITERS_SUCCESS';
export const CREATE_DRAFT_SUCCESS = 'CREATE_DRAFT_SUCCESS';
export const GET_PROJECT_FLOW_FREE_DESIGNERS_SUCCESS = 'GET_PROJECT_FLOW_FREE_DESIGNERS_SUCCESS';
export const SET_PROJECT_IN_PROJECT_FLOW = 'SET_PROJECT_IN_PROJECT_FLOW';
