import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import classNames from 'classnames';
import Spinner from '../Spinner';

const randomize = () => Math.floor(1 + Math.random() * (50 - 1));

class LoadingScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const loadingString = 'loading_message_';
    const message = t(loadingString + randomize());
    this.setState({ message });

    this.interval = setInterval(() => {
      const newMessage = t(loadingString + randomize());
      this.setState({ message: newMessage });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { isLoading, isSideModal } = this.props;
    const { message } = this.state;

    return (isLoading
      ? (
        <div className={classNames('loading-screen', {
          'side-modal-loader': isSideModal,
        })}
        >
          <Spinner
            width={40}
            height={40}
            className='spinner'
          />
          <span>{message}</span>
        </div>
      )
      : null
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.alerts.isLoading,
});

export default connect(mapStateToProps, null)(translate('App')(LoadingScreen));
