import React from 'react';

const UpdatingLoaderLocal = ({
  isLoading,
  message = 'Would you like some fries while waiting?',
  withoutMessage = false,
}) => (
  isLoading && (
  <div className='update-loader-container'>
    <div className='loader-container'>
      <div className='loader' />
    </div>
    {!withoutMessage && <span>{message}</span>}
  </div>
  )
);

export default UpdatingLoaderLocal;
