export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RECEIVE_PROFILE_SUCCESS = 'RECEIVE_PROFILE_SUCCESS';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const IMPERSONATE = 'IMPERSONATE';
export const UNIMPERSONATE = 'UNIMPERSONATE';
export const SET_IMPERSONATED_DATA = 'SET_IMPERSONATED_DATA';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const REQUEST_NEW_PASSWORD_SUCCESS = 'REQUEST_NEW_PASSWORD_SUCCESS';
export const REQUEST_NEW_PASSWORD_FAILURE = 'REQUEST_NEW_PASSWORD_FAILURE';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_DESIGNER_AVATAR = 'UPDATE_DESIGNER_AVATAR';
export const UPDATE_WRITER_AVATAR = 'UPDATE_WRITER_AVATAR';
export const UPDATE_PROFESSIONAL_AVATAR = 'UPDATE_PROFESSIONAL_AVATAR';
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const SEND_RESET_PASSWORD_LINK = 'SEND_RESET_PASSWORD_LINK';
export const UPDATE_TIPALTI_ID = 'UPDATE_TIPALTI_ID';
export const UPDATE_TIPALTI_COMPLETE_ONBOARDING_AT = 'UPDATE_TIPALTI_COMPLETE_ONBOARDING_AT';
