import React from 'react';
import classNames from 'classnames';

import spinner from '../../../../assets/gif/spinner.gif';

const Spinner = ({ width, height, className = '' }) => (
  <div className={classNames(className)}>
    <img
      src={spinner}
      width={width}
      height={height}
      alt='spinner'
    />
  </div>
);

export default Spinner;
