import * as types from '../constants/rate-plans';

const initialState = {
  filtersLoading: true,
  client: [],
  ratePlansList: {
    allDefaultRatePlans: [],
    customRatePlans: [],
    archivedPlans: [],
  },
  url: null,
  current_page: null,
  last_page: null,
  total: null,
  per_page: 10,
  allFilerClient: [],
  financialStatus: [],
  minimum_fee: {
    clientPlanFee: [],
    freelancerPlanFee: [],
  },
};

export default function clientPlans(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_DEFAULT_RATE_PLANS:
      return {
        ...state,
        ratePlansList: {
          ...state.ratePlansList,
          allDefaultRatePlans: action.payload,
        },
      };
    case types.GET_ARCHIVED_RATE_PLANS:
      return {
        ...state,
        ratePlansList: {
          ...state.ratePlansList,
          archivedPlans: action.payload,
        },
      };
    case types.GET_ALL_CUSTOM_RATE_PLANS:
      return {
        ...state,
        ratePlansList: {
          ...state.ratePlansList,
          customRatePlans: action.payload,
        },
      };
    case types.REMOVE_FROM_CUSTOM_RATE_PLANS_SUCCESS:
      return {
        ...state,
        ratePlansList: {
          ...state.ratePlansList,
          customRatePlans: {
            ...state.ratePlansList.customRatePlans,
            data: state.ratePlansList.customRatePlans.data.filter(item => item.id !== action.payload),
            total: state.ratePlansList.customRatePlans.total - 1,
          },
        },
      };
    case types.SET_DEFAULT_PLANS_PAGE_SUCCESS:
      return {
        ...state,
        ratePlansList: {
          ...state.ratePlansList,
          allDefaultRatePlans: action.payload,
        },
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.SET_ARCHIVED_RATE_PAGE_PLANS_SUCCESS:
      return {
        ...state,
        ratePlansList: {
          ...state.ratePlansList,
          archivedPlans: action.payload,
        },
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.DELETE_ARCHIVE_PLAN_SUCCESS:
      return {
        ...state,
        ratePlansList: {
          ...state.ratePlansList,
          archivedPlans: {
            ...state.ratePlansList.archivedPlans,
            data: state.ratePlansList.archivedPlans.data.filter(item => item.id !== action.payload),
            total: state.ratePlansList.archivedPlans.total - 1,
          },
        },
      };
    case types.GET_PLAN_CLIENTS:
    case types.SET_CLIENTS_PLAN_PAGE_SUCCESS:
      return {
        ...state,
        client: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.START_FETCHING_PLAN_FILTERS_DATA:
      return {
        ...state,
        filtersLoading: true,
      };
    case types.GET_DEFAULT_RATE_PLANS_URL:
    case types.SET_DEFAULT_RATE_PAGE_PLANS_URL:
    case types.GET_ARCHIVE_RATE_PLANS_URL:
    case types.SET_ARCHIVED_RATE_PAGE_PLANS_URL:
    case types.GET_PLAN_CLIENTS_PAGE_URL:
    case types.SET_PLAN_CLIENTS_PAGE_URL:
      return { ...state, url: action.payload };
    case types.GET_PLAN_FILTERS_DATA_SUCCESS:
      return {
        ...state,
        allFilerClient: action.payload.client,
        financialStatus: action.payload.financialStatus,
        filtersLoading: false,
      };
    case types.GET_PLAN_FILTERS_DATA_FAILED:
      return {
        ...state,
        allFilerClient: [],
        financialStatus: [],
        filtersLoading: false,
      };
    case 'GET_MINIMUM_FEE':
      return { ...state, minimum_fee: action.payload };
    case 'GET_MINIMUM_FEE_FREELANCER':
      return {
        ...state,
        minimum_fee: {
          ...state.minimum_fee,
          freelancerPlanFee: action.payload,
        },
      };
    case 'GET_MINIMUM_FEE_CLIENT':
      return {
        ...state,
        minimum_fee: {
          ...state.minimum_fee,
          clientPlanFee: action.payload,
        },
      };
    default:
      return state;
  }
}
