import * as types from '../constants';

const initialState = {
  isVisible: false,
  amount: 0,
  onClickString: '() => {}',
  functionContext: null,
};

export default function warningBar(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_HIDE_WARNING:
      return action.payload;
    case types.LOGOUT_SUCCESS:
    case types.UNIMPERSONATE:
      return initialState;
    default:
      return state;
  }
}
