import { Permissions, PermissionsObject } from './types';
import { PermissionsList } from '../../reducers/permissionsList';
import { DropdownType, UserPermissionKeys } from '../../types';

export const permissionExists = (permissionName: string, permissions: DropdownType[]): boolean => (
  !!permissions.find(({ name }) => name === permissionName)
);

export const getHasPermissionsObject = (permissions: DropdownType[], allPermissionsList: PermissionsList): PermissionsObject => (
  Object.values(allPermissionsList)
    .reduce((permissionsObject, currentPermission) => ({
      ...permissionsObject,
      [currentPermission]: permissionExists(currentPermission, permissions),
    }), {})
);

export const formatPermissions = (keys: PermissionsList, permissionObject: PermissionsObject): Permissions => {
  const {
    [keys[UserPermissionKeys.SUPERADMIN]]: hasSuperAdminRole = false,
    [keys[UserPermissionKeys.ADMIN]]: hasAdminRole = false,
    [keys[UserPermissionKeys.CLIENT]]: hasClientRole = false,
    [keys[UserPermissionKeys.WRITER]]: hasWriterRole = false,
    [keys[UserPermissionKeys.DESIGNER]]: hasDesignerRole = false,
    [keys[UserPermissionKeys.PROFESSIONAL]]: hasProfessionalRole = false,
    [keys[UserPermissionKeys.FREELANCER]]: hasFreelancerRole = false,
  } = permissionObject;

  const hasSuperAdminOrAdminRole = hasSuperAdminRole || hasAdminRole;

  return ({
    hasSuperAdminRole,
    hasAdminRole,
    hasClientRole,
    hasWriterRole,
    hasDesignerRole,
    hasProfessionalRole,
    hasFreelancerRole,
    // mixed conditions
    hasSuperAdminOrAdminRole,
  });
};

export const getFormattedPermissions = (keys: PermissionsList, permissions: DropdownType[]): Permissions => (
  formatPermissions(
    keys,
    getHasPermissionsObject(permissions, keys),
  )
);
