import moment from 'moment';
import {
  mixpanelEvents,
  PROJECT_STATUS,
  PROJECT_STATUS_BY_ID,
  TRANSLATION_KEY_PROJECT_TOOLS,
  WRITING_PROJECT_TOOLS_BY_ID,
  WRITING_PROJECT_TOOLS_NAMES,
} from '../constants';
import { formatProjectAttachments, getAttachmentItem } from './projectAttachments';
import { track } from './trackMixpanel';
import { getProjectTypeByService } from './projectTypes';

const getProjectStatusTranslate = ({
  t, state, projectStatusId, isDesignProject = false, withHasAction = false, isEvaluation = false, isClient,
  isFreelancer,
}) => {
  const status = state || PROJECT_STATUS_BY_ID[projectStatusId];
  const result = {
    status: '',
    hasAction: false,
  };
  switch (status) {
    case PROJECT_STATUS.PENDING_ACCEPT: {
      result.status = isFreelancer ? t('accept_project') : t('wait_for_acceptance');
      result.hasAction = isFreelancer;
      break;
    }
    case PROJECT_STATUS.DELIVER_DRAFT: {
      if (isEvaluation) {
        result.status = isFreelancer ? t('complete_evaluation') : t('in_evaluation');
      } else {
        result.status = isFreelancer
          ? isDesignProject ? t('upload_design') : t('upload_draft')
          : isDesignProject ? t('wait_for_design') : t('wait_for_draft');
      }
      result.hasAction = isFreelancer;
      break;
    }
    case PROJECT_STATUS.WAITING_FOR_FEEDBACK: {
      // evaluation project cannot have this state
      // so show in_evaluation for insurance
      if (isEvaluation) {
        result.status = t('in_evaluation');
      } else {
        result.status = isClient
          ? isDesignProject ? t('review_design') : t('review_draft')
          : t('wait_for_feedback');
        result.hasAction = isClient;
      }
      break;
    }
    // id 5
    case PROJECT_STATUS.FINISHED: {
      // evaluation project cannot have this state
      // so show in_evaluation for insurance
      if (isEvaluation) {
        result.status = t('in_evaluation');
      } else {
        result.status = isFreelancer ? t('complete_project') : t('finished');
        result.hasAction = isFreelancer;
        result.additionalOnClick = () => track(mixpanelEvents.PROJECT_MANAGEMENT.FREELANCER_ACTIONS.CLICKED_COMPLETE_PROJECT_ON_PD);
      }
      break;
    }
    // id 4
    case PROJECT_STATUS.COMPLETED: {
      result.status = t('completed');
      break;

      /* return !isDesignProject
        ? t('finished')
        : t('project_is_completed'); */
    }
    // id 7
    case PROJECT_STATUS.ACTIVE: {
      result.status = t('in_progress');
      break;
    }
    /* case PROJECT_STATUS.INACTIVE: {
     return t('not_appliccable');
     }
     case PROJECT_STATUS.ACTIVE: {
     return t('not_appliccable');
     }
     case PROJECT_STATUS.DRAFT: {
     return ''
     } */
    default:
      break;
  }

  return withHasAction ? result : result.status;
};

const getDraftStatus = ({ t, isClient }) => {
  const result = {
    status: t('complete_briefing'),
    hasAction: isClient,
  };

  return result;
};

const getProjectStep = ({
  draftsCount, state, projectStatusId, count = 4, isFreelancer,
}) => {
  const isSecondOrMoreDraft = draftsCount > 1;
  const status = state || PROJECT_STATUS_BY_ID[projectStatusId];
  const result = { count };

  switch (status) {
    case PROJECT_STATUS.PENDING_ACCEPT: {
      result.step = 0;
      break;
    }
    case PROJECT_STATUS.DELIVER_DRAFT: {
      result.step = isSecondOrMoreDraft ? 2 : 1;
      break;
    }
    case PROJECT_STATUS.WAITING_FOR_FEEDBACK: {
      result.step = 2;
      break;
    }
    // id 5
    case PROJECT_STATUS.FINISHED: {
      result.step = isFreelancer ? 4 : 3;
      break;
    }
    // id 4
    case PROJECT_STATUS.COMPLETED: {
      result.step = 4;
      break;
    }
    // id 7
    case PROJECT_STATUS.ACTIVE: {
      result.step = 1;
      break;
    }
    default:
      result.step = 0;
      break;
  }

  return result;
};

const getProjectDeadlineInfo = (deadlineTime, t) => {
  const now = moment();
  const deadline = moment(deadlineTime);
  const deadlineFormatted = deadline.format('DD.MM.YYYY');
  const daysToDeadline = deadline.diff(now, 'days');
  const isDeadlinePassed = daysToDeadline < 0;
  const isDeadlineInThreeDays = !isDeadlinePassed && daysToDeadline < 3;
  const tooltipContent = isDeadlinePassed
    ? t('deadline_passed')
    : isDeadlineInThreeDays
      ? t('deadline_within_three_days')
      : null;
  return ({
    deadlineFormatted,
    isDeadlinePassed,
    isDeadlineInThreeDays,
    tooltipContent,
  });
};

const getProjectsFilters = (filters) => {
  const arrParams = [];
  const removeParams = {};

  const filtersNames = [
    'freelancerDropdownValues',
    'languageDropDownValues',
    'contactDropdownValues',
    'actionDropdownValues',
    'clientUsersDropdownValues',
    'projectTypeDropDownValues',
    'csmDropdownValues',
  ];

  const filterKeys = {
    freelancerDropdownValues: 'freelancer',
    languageDropDownValues: 'language',
    contactDropdownValues: 'client',
    actionDropdownValues: 'state',
    clientUsersDropdownValues: 'owner',
    projectTypeDropDownValues: 'service',
    csmDropdownValues: 'admin',
  };

  filtersNames.forEach((filtersName) => {
    const filterValue = filters[filtersName] ?? [];
    const isFilterExist = !!filterValue.length;
    const filterKey = filterKeys[filtersName];

    if (isFilterExist) {
      const joinFilter = filterValue.join(',');
      arrParams.push(`${filterKey}=${joinFilter}`);
    } else {
      removeParams[filterKey] = true;
    }
  });

  return {
    arrParams,
    removeParams,
  };
};

const sortAttachments = ({
  sectionsOrder = [],
  otherGroupFirst = false,
  otherGroupKey = '',
}) => (a, b) => {
  const order = otherGroupFirst ? [otherGroupKey, ...sectionsOrder] : sectionsOrder;

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  const bFirst = 1; //      > 0   => [b, a]
  const aFirst = -1; //     < 0   => [a, b]
  const keepOrder = 0; // === 0 => keep original order of a and b

  // to keep order - we can compare 'a' and 'b' items ids according to order array
  // which index is less - should be moved to ahead
  const aOrderIndex = order.findIndex(id => id === a.id);
  const bOrderIndex = order.findIndex(id => id === b.id);
  const inOrderArray = (i) => i !== -1;

  // we can get -1 as index for each item
  // if both have same index - keep same order (even for -1)
  if (aOrderIndex === bOrderIndex) return keepOrder;

  // so, if a item id not exist in order array - it should go back if next item (b) group id exists
  // so b in this case should be first
  if (!inOrderArray(aOrderIndex) && inOrderArray(bOrderIndex)) return bFirst;

  // if b id not exist in order array we should keep same order or move b item to back (same as a item to first)
  if (!inOrderArray(bOrderIndex)) return aFirst;

  // if both indexes is in order array - check which is less
  // a > b   => [b, a] // should return [b first - same as (a - b), as a > b]
  // a < b   => [a, b] // should return [a first - same as (a - b), as a < b]
  // a === b => keep order // should return [keep order - same as (a - b), as a === b]
  // so we can return (a-b) condition
  return aOrderIndex - bOrderIndex;
};

const groupProjectAttachments = ({
  attachments,
  groupByKey,
  sectionTitles,
  requiredSections = [],
  additionalParamsByGroupKey = {},
  sectionsOrder = [],
  otherGroupFirst = false,
}) => {
  const initialValue = requiredSections.reduce((resultObject, sectionKey) => ({ ...resultObject, [sectionKey]: [] }), {});
  const otherGroupKey = 'getProjectAttachments__WITHOUT_GROUP_KEY';

  const attachmentsByKey = attachments.reduce((docsByService, attachment) => {
    const {
      [groupByKey]: groupBy,
    } = attachment;

    const groupId = groupBy || otherGroupKey;

    return ({
      ...docsByService,
      [groupId]: [
        ...(docsByService[groupId] || []),
        getAttachmentItem(attachment),
      ],
    });
  }, initialValue);

  const filesAndLinks = Object
    .entries(attachmentsByKey)
    .map(([groupBy, groupAttachments]) => ({
      ...additionalParamsByGroupKey[groupBy],
      id: groupBy,
      title: sectionTitles[groupBy],
      attachments: groupAttachments,
      isOtherGroup: groupBy === otherGroupKey,
    }));

  // sort order
  return filesAndLinks.sort(sortAttachments({
    sectionsOrder, otherGroupFirst, otherGroupKey,
  }));
};

const getProjectAttachments = ({
  attachments,
  groupByKey,
  sectionTitles,
  requiredSections = [],
  additionalParamsByGroupKey = {},
  sectionsOrder = [],
  otherGroupFirst = false,
}) => (groupByKey
  ? groupProjectAttachments({
    attachments,
    groupByKey,
    sectionTitles,
    requiredSections,
    additionalParamsByGroupKey,
    sectionsOrder,
    otherGroupFirst,
  })
  : ([{
    id: 'attachments',
    attachments: formatProjectAttachments({ attachments }),
  }]));

const getProjectTool = ({ project }) => {
  const {
    project_tool_id: projectToolId,
    briefing_urls: urls,
    crowdin_project_link: crowdinProjectLink,
  } = project;

  const translationKeyByToolId = WRITING_PROJECT_TOOLS_BY_ID[projectToolId] ?? null;
  const crowdinKey = crowdinProjectLink
    ? WRITING_PROJECT_TOOLS_NAMES.CROWDIN
    : null;
  const wordOrGoogleKey = (urls ?? []).length
    ? WRITING_PROJECT_TOOLS_NAMES.GOOGLE_DOCS
    : WRITING_PROJECT_TOOLS_NAMES.MICROSOFT_WORD;

  return translationKeyByToolId ?? crowdinKey ?? wordOrGoogleKey;
};

const getProjectToolTranslationKey = ({ project }) => {
  const toolName = getProjectTool({ project });
  return TRANSLATION_KEY_PROJECT_TOOLS[toolName];
};

/**
 * Get project path
 * @param {{id: number, service_id: number, is_evaluation: 0|1}} project
 * @returns {string}
 */
export const getProjectPath = (project) => {
  const {
    id: projectID,
    service_id: serviceId,
  } = project;
  const { pathname } = window.location;
  const lang = pathname.split('/')[1]; // first is empty string
  const isEvaluation = !!project.is_evaluation;
  const projectType = getProjectTypeByService({ serviceId, isEvaluation });
  return `/${lang}/${projectType}/${projectID}`;
};

/**
 * Get project unread messages count
 * @param project
 * @param userId
 * @returns {number}
 */
const getProjectUnreadMessagesCount = ({ project, userID }) => {
  if (!userID) return 0;
  const workElementUser = (project?.workelement?.users ?? []).find((user) => user?.pivot?.user_id === userID);

  return (
    (workElementUser?.pivot?.unread_count || 0)
    + (workElementUser?.pivot?.unread_draft_count || 0)
  );
};

export {
  getProjectStatusTranslate,
  getProjectStep,
  getProjectDeadlineInfo,
  getProjectsFilters,
  groupProjectAttachments,
  getProjectAttachments,
  getProjectTool,
  getProjectToolTranslationKey,
  getDraftStatus,
  getProjectUnreadMessagesCount,
};
