import reactRouterHistory from '../../helpers/history';

const addParamToURL = (param, value) => {
  const oldSearch = window.location.search;
  const newSearch = new URLSearchParams(oldSearch);
  newSearch.set(param, value);
  const newPath = `${reactRouterHistory.location.pathname}?${newSearch.toString()}`;
  window.history.pushState({}, '', newPath);
};

const removeParamFromURL = (param) => {
  const oldSearch = window.location.search;
  const newSearch = new URLSearchParams(oldSearch);
  newSearch.delete(param);
  const newPath = `${reactRouterHistory.location.pathname}${newSearch.toString() ? `?${newSearch.toString()}` : ''}`;
  window.history.pushState({}, '', newPath);
};

const setMultiFiltersInUrl = (filters) => Object.entries(filters)
  .forEach(([key, value]) => {
    const valueToSet = Array.isArray(value) ? value.join(',') : String(value);
    valueToSet
      ? addParamToURL(key, valueToSet)
      : removeParamFromURL(key);
  });

export {
  addParamToURL,
  removeParamFromURL,
  setMultiFiltersInUrl,
};
