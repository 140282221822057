import React, {
  Fragment, FunctionComponent, Suspense, SuspenseProps,
} from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { PrivateRouteProps } from './interfaces';
import { RootState } from '../../store';

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  lang,
  componentProps = {},
  children = null,
  lazy = false,
  routeWrapper,
  ...rest
}) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const user = useSelector((state: RootState) => state.auth.user);

  const state = user?.state ?? '';

  const Wrapper = lazy
    ? Suspense
    : Fragment;

  const wrapperProps = (lazy ? { fallback: null } : {}) as SuspenseProps;

  const ContentRouteWrapper = routeWrapper || Fragment;

  return (
    <Route
      {...rest}
    >
      {(routeProps) => (
        (isAuthenticated && state !== 'ONBOARDING')
          ? (
            children || (
              <Wrapper
                {...wrapperProps}
              >
                <ContentRouteWrapper>
                  <Component
                    {...routeProps}
                    {...componentProps}
                    lang={lang}
                  />
                </ContentRouteWrapper>
              </Wrapper>
            )
          ) : (
            <Redirect
              exact
              to={{
                pathname: (isAuthenticated && state === 'ONBOARDING')
                  ? `/${lang}/lets-go`
                  : `/${lang}/login`,
                state: { from: routeProps.location },
              }}
            />
          ))}
    </Route>
  );
};

export default withRouter(PrivateRoute);
