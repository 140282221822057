import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

const API_URL = process.env.REACT_APP_API_URL as string;
const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY as string;
const CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER as string;
const WEBSOCKET_NAMESPACE = process.env.REACT_APP_WEBSOCKET_NAMESPACE as string;
const WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST as string;
const WEBSOCKET_PORT: number = +(process.env.REACT_APP_WEBSOCKET_PORT ?? '');

type EchoConfigType = {
  broadcaster: string,
  client: Pusher,
  namespace?: string,
};

function createClient(): Echo {
  const domainUrl = new URL(API_URL).origin;

  const isAllParamsValid = [domainUrl, CLUSTER].every(Boolean);
  if (isAllParamsValid) {
    const authPath = '/broadcasting/auth';
    const authEndpoint = `${domainUrl}${authPath}`;
    const USER_TOKEN = localStorage.getItem('token') ?? '';

    const authConfig = USER_TOKEN
      ? ({ auth: { headers: { Authorization: `Bearer ${USER_TOKEN}` } } })
      : {};

    const clientConfigs = {
      ...authConfig,
      authEndpoint,
      cluster: CLUSTER,
      wsHost: WEBSOCKET_HOST,
      wsPort: WEBSOCKET_PORT,
      forceTLS: false,
      disableStats: true,
    };

    const client = new Pusher(PUSHER_APP_KEY, clientConfigs);

    const echoConfig: EchoConfigType = {
      broadcaster: 'pusher',
      client,
    };

    WEBSOCKET_NAMESPACE && (echoConfig.namespace = WEBSOCKET_NAMESPACE);

    return new Echo(echoConfig);
  } else {
    throw new Error('Something is wrong in init of WebSocket client: At least one of `domainUrl`, `WEBSOCKET_PORT`, `CLUSTER` is not set');
  }
}

export default createClient;
