// (?: ... ) - Non-capturing group

const REG_EXP = {
  HOLE_HALF_THOUSAND_COMMA: /^\d{1,3}(?:\.\d{3})*(?:,5)?$/,
  HOLE_HALF_THOUSAND_TYPING_COMMA: /^\d{1,3}(?:\.\d{0,3})*(?:,5)?$/,
  HOLE_HALF_COMMA: /^\d+(?:,[05]0?)?$/,
  HOLE_HALF_TYPING_COMMA: /^\d*(?:,?[05]?0?)?$/,
  HOLE_HALF_THOUSAND: /^\d{1,3}(?:,\d{3})*(?:\.5)?$/,
  HOLE_HALF_THOUSAND_TYPING: /^\d{1,3}(?:,\d{0,3})*(?:\.5)?$/,
  HOLE_HALF: /^\d+(?:\.[05]0?)?$/,
  HOLE_HALF_TYPING: /^\d*(?:\.?[05]?0?)?$/,
  QUATERNARY: /^\d+(?:\.([05]0?|[27]5))?$/,
  QUATERNARY_TYPING: /^\d*(?:\.([05]?0?|[27]?5?)?)?$/,
  // eslint-disable-next-line max-len
  EMAIL_VALIDATION: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-next-line max-len
  LINK_VALIDATION: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
  FIGMA: /^(?:https?:\/\/)?(?:\w{3}\.)?figma\.com(?:\/.*|$)/,
};

export default REG_EXP;
