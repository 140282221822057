import { applyMiddleware, createStore, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from '../reducers';
import { ClientIdAndUserIdReturnType, getAuthUserId } from '../hooks/useAuthUserId';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['warningBar'],
};

const addUserIdToActionMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  const state = store.getState();
  const userIdAndClientId: ClientIdAndUserIdReturnType = getAuthUserId(state);

  // Adding userIdAndClientId to the action payload
  const newAction = {
    ...action,
    ...userIdAndClientId,
  };

  // Pass the modified action to the next middleware or Redux store
  return next(newAction);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore() {
  if (process.env.NODE_ENV === 'production') {
    return createStore(
      persistedReducer,
      applyMiddleware(thunkMiddleware, addUserIdToActionMiddleware),
    );
  } else {
    return createStore(
      persistedReducer,
      composeWithDevTools(applyMiddleware(thunkMiddleware, addUserIdToActionMiddleware)),
    );
  }
}

const store = configureStore();
export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof persistedReducer>;
export type AppDispatch = typeof store.dispatch;
