export const GET_PRIORITIES = 'GET_PRIORITIES';
export const GET_ALL_RATE_PLANS = 'GET_ALL_RATE_PLANS';
export const ENABLE_EDIT_MODE = 'ENABLE_EDIT_MODE';
export const DISABLE_EDIT_MODE = 'DISABLE_EDIT_MODE';
export const SET_PAGE_CLIENTS_PAGE_SUCCESS = 'SET_PAGE_CLIENTS_PAGE_SUCCESS';
export const SET_CLIENTS_PAGE_URL = 'SET_CLIENTS_PAGE_URL';
export const GET_CLIENTS_URL = 'GET_CLIENTS_URL';
export const GET_ALL_DEFAULT_RATE_PLANS = 'GET_ALL_DEFAULT_RATE_PLANS';
export const GET_ALL_CUSTOM_RATE_PLANS = 'GET_ALL_CUSTOM_RATE_PLANS';
export const GET_ARCHIVED_RATE_PLANS = 'GET_ARCHIVED_RATE_PLANS';
export const SET_DEFAULT_RATE_PAGE_PLANS_URL = 'SET_DEFAULT_RATE_PAGE_PLANS_URL';
export const SET_ARCHIVED_RATE_PAGE_PLANS_URL = 'SET_ARCHIVED_RATE_PAGE_PLANS_URL';
export const SET_ARCHIVED_RATE_PAGE_PLANS_SUCCESS = 'SET_ARCHIVED_RATE_PAGE_PLANS_SUCCESS';
export const SET_DEFAULT_PLANS_PAGE_SUCCESS = 'SET_PAGE_DEFAULT_PLANS_PAGE_SUCCESS';
export const GET_DEFAULT_RATE_PLANS_URL = 'GET_DEFAULT_RATE_PLANS_URL';
export const GET_ARCHIVE_RATE_PLANS_URL = 'GET_ARCHIVE_RATE_PLANS_URL';
export const DELETE_ARCHIVE_PLAN_SUCCESS = 'DELETE_ARCHIVE_PLAN_SUCCESS';
export const SORT_CLIENTS_SUCCESS = 'SORT_CLIENTS_SUCCESS';
export const SORT_CLIENTS_URL = 'SORT_CLIENTS_URL';
export const FILTER_CLIENTS_BY_SUCCESS = 'FILTER_CLIENTS_BY_SUCCESS';
export const FILTER_CLIENTS_BY_SUCCESS_URL = 'FILTER_CLIENTS_BY_SUCCESS_URL';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_PAYMENT_TERMS = 'GET_PAYMENT_TERMS';
export const GET_CONTRACT = 'GET_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const START_FETCHING_PLAN_FILTERS_DATA = 'START_FETCHING_PLAN_FILTERS_DATA';
export const GET_PLAN_FILTERS_DATA_SUCCESS = 'GET_PLAN_FILTERS_DATA_SUCCESS';
export const GET_PLAN_FILTERS_DATA_FAILED = 'GET_PLAN_FILTERS_DATA_FAILED';
export const REMOVE_FROM_CUSTOM_RATE_PLANS_SUCCESS = 'REMOVE_FROM_CUSTOM_RATE_PLANS_SUCCESS';
export const GET_FREELANCERS_DEFAULT_PLANS = 'GET_FREELANCERS_DEFAULT_PLANS';
export const GET_FREELANCERS_CUSTOM_PLANS = 'GET_FREELANCERS_CUSTOM_PLANS';
export const SET_FREELANCERS_PAGE_PLANS_URL = 'SET_FREELANCERS_DEFAULT_PAGE_PLANS_URL';
export const GET_FREELANCERS_PAGE_PLANS_URL = 'GET_FREELANCERS_DEFAULT_PAGE_PLANS_URL';
export const REMOVE_FROM_CUSTOM_FREELANCER_PLANS_SUCCESS = 'REMOVE_FROM_CUSTOM_FREELANCER_PLANS_SUCCESS';
export const SET_FREELANCER_DEFAULT_PLANS_PAGE_SUCCESS = 'SET_FREELANCER_DEFAULT_PLANS_PAGE_SUCCESS';
export const SET_PLANS_LOADER = 'SET_PLANS_LOADER';
export const HIDE_PLANS_LOADER = 'HIDE_PLANS_LOADER';
// TODO same as in freelancer-overview-tab-new.js
export const START_FETCHING_FREELANCER_FILTERS_DATA = 'START_FETCHING_FREELANCER_FILTERS_DATA';
export const GET_FREELANCER_FILTERS_DATA_SUCCESS = 'GET_FREELANCER_FILTERS_DATA_SUCCESS';
export const GET_FREELANCER_FILTERS_DATA_FAILED = 'GET_FREELANCER_FILTERS_DATA_FAILED';
export const GET_PLAN_CLIENTS = 'GET_PLAN_CLIENTS';
export const GET_PLAN_FREELANCERS = 'GET_PLAN_FREELANCERS';
export const GET_PLAN_CLIENTS_PAGE_URL = 'GET_PLAN_CLIENTS_URL';
export const SET_PLAN_CLIENTS_PAGE_URL = 'SET_PLAN_CLIENTS_PAGE_URL';
export const GET_PLAN_FREELANCERS_PAGE_URL = 'GET_PLAN_FREELANCERS_PAGE_URL';
export const SET_PLAN_FREELANCERS_PAGE_URL = 'SET_PLAN_FREELANCERS_PAGE_URL';
export const SET_CLIENTS_PLAN_PAGE_SUCCESS = 'SET_CLIENTS_PLAN_PAGE_SUCCESS';
export const SET_FREELANCERS_PLAN_PAGE_SUCCESS = 'SET_FREELANCERS_PLAN_PAGE_SUCCESS';
