import { OrNull } from '../../types';

export type SimpleUserType = { id: number, name: string, avatar: string };

export enum UserRoles {
  CLIENT = 'CLIENT',
  ADMIN = 'ADMIN',
  SUPER = 'SUPER',
  WRITER = 'WRITER',
  DESIGNER = 'DESIGNER',
  PROFESSIONAL = 'PROFESSIONAL',
}

export const isSuperUserType = (type: unknown): type is UserRoles.SUPER => type === UserRoles.SUPER;
export const isAdminUserType = (type: unknown): type is UserRoles.ADMIN => type === UserRoles.ADMIN;
type AdministratorRolesType = (UserRoles.SUPER | UserRoles.ADMIN);
export const isSuperOrAdminUserType = (type: unknown): type is AdministratorRolesType => (
  isSuperUserType(type) || isAdminUserType(type)
);
export const isClientUserType = (type: unknown): type is UserRoles.CLIENT => type === UserRoles.CLIENT;
export const isWriterUserType = (type: unknown): type is UserRoles.WRITER => type === UserRoles.WRITER;
export const isDesignerUserType = (type: unknown): type is UserRoles.DESIGNER => type === UserRoles.DESIGNER;
export const isProfessionalUserType = (type: unknown): type is UserRoles.PROFESSIONAL => type === UserRoles.PROFESSIONAL;
export const isWriterOrDesignerUserType = (type: unknown) => (
  isWriterUserType(type) || isDesignerUserType(type)
);
type FreelancerRoleType = UserRoles.WRITER | UserRoles.DESIGNER | UserRoles.PROFESSIONAL;
export const isFreelancerOrProfessionalUserType = (type: unknown): type is FreelancerRoleType => (
  isWriterOrDesignerUserType(type) || isProfessionalUserType(type)
);

export enum UserStates {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  DECLINED = 'DECLINED',
  ONBOARDING = 'ONBOARDING',
  ARCHIVE = 'ARCHIVE',
}

type InactiveUserStates = UserStates.INACTIVE
| UserStates.PENDING_APPROVAL
| UserStates.DECLINED;

export const INACTIVE_USER_STATES: UserStates[] = [
  UserStates.INACTIVE,
  UserStates.PENDING_APPROVAL,
  UserStates.DECLINED,
];

export const isInactiveUserState = (state: UserStates): state is InactiveUserStates => (
  INACTIVE_USER_STATES.includes(state)
);

/// CLIENT INTERFACE
interface Writer {
  id: number;
  is_available: number;
  slogan: string;
  portfolio_clients?: object;
  portfolio_media?: object;
  hourly_rate_in_eur?: number;
  experience_in_years?: number;
  topics_of_expertise?: object;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  chamber_number: string;
  price_plan_id?: number;
  about: string;
  seo_optimize_id?: number;
  localisation_software_id?: number;
  onboarding_step: string;
  status_id: number;
  evaluate_project_id?: number;
  hours_1000_words_write?: number;
  hours_1000_words_localisation?: number;
  hours_1000_words_edit?: number;
  hours_1000_words_rewrite?: number;
  disapprove_type_id?: number;
  word_price_edit: number;
  word_price_rewrite: number;
  word_price_localisation: number;
  word_price_write: number;
  work_rate_type: number;
}

interface User {
  id: number;
  client_id?: number;
  state: string;
  name: string;
  locale: string;
  about?: string;
  sms_enabled: number;
  type: UserRoles;
  role: string;
  avatar: string;
  last_login_at: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  language: string;
  registration_country: string;
  onboarding_reminder_at?: string;
  time_zone?: string;
  crowdin_user_id?: number;
  send_verify: string;
  client?: object;
}

interface Contract {
  id: number;
  end_date: string;
  automatic_renewal: number;
  client_id: number;
  pilot_months: number;
  starting_date: string;
}

interface Admin {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  user: User;
}

export interface Client {
  id: number;
  state: string;
  state_num: number;
  is_pilot: boolean;
  admin_id: number;
  locale: string;
  company: string;
  name: string;
  phone: string;
  url: string;
  avatar: string;
  price_plan_id: number;
  contact_date: null,
  newsletter_subscribed: number,
  state_updated_at: string;
  scheduled_at: string;
  expires_at: string;
  created_at: string;
  updated_at: string;
  date_change_plan: string;
  deleted_at: string;
  old_price_plan_id: number;
  adding_old_plan: number;
  change_old_plan: number;
  date_assignment_plan: string,
  active_projects_count: number;
  nps: number;
  notes_count: number;
  client_user_id: number;
  main_user_id: number;
  leading: OrNull<SimpleUserType>,
  leading_id: OrNull<number>,
  writers: Writer[];
  users: User[];
  contract: Contract;
  watchers: Admin[];
  admins: Admin[];
}
