const logoutCrowdin = () => {
  const logoutUrl = process.env.REACT_APP_LOGOUT_CROWDIN_URL;
  if (logoutUrl) {
    const iframe = document.createElement('iframe');
    iframe.src = logoutUrl;
    iframe.style.display = 'none';
    const removeIframe = () => document.body.removeChild(iframe);
    ['load', 'abort', 'error'].forEach(eventType => (
      iframe.addEventListener(eventType, removeIframe)
    ));
    document.body.appendChild(iframe);

    // auto remove after 10s
    setTimeout(() => {
      try {
        removeIframe();
      } catch (e) {
        //
      }
    }, 10000);
  }
};

export {
  logoutCrowdin,
};
