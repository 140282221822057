import { useEffect, useRef } from 'react';
import { SEARCH_CALLBACK_DELAY } from '../utils';

type CallbackType<CallbackArgsType extends Array<any>> = (...args: CallbackArgsType) => void;

type ReturnValuesType<DebouncedCallbackArgsType extends Array<any>> = {
  debouncedCallback: CallbackType<DebouncedCallbackArgsType>,
  cleanup: () => void,
};

const useDebouncedCallback = <ArgsTypes extends Array<any>>(
  callback: CallbackType<ArgsTypes>,
  delay = SEARCH_CALLBACK_DELAY,
): ReturnValuesType<ArgsTypes> => {
  // track args & timeout handle between calls
  const argsRef = useRef<ArgsTypes | null>(null);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const cleanup: ReturnValuesType<ArgsTypes>['cleanup'] = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  // make sure our timeout gets cleared if
  // our consuming component gets unmounted
  useEffect(() => cleanup, []);

  const debouncedCallback: ReturnValuesType<ArgsTypes>['debouncedCallback'] = (...args) => {
    // capture latest args
    argsRef.current = args;

    // clear debounce timer
    cleanup();

    // start waiting again
    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        callback(...argsRef.current);
      }
    }, delay);
  };

  return {
    debouncedCallback,
    cleanup,
  };
};

export default useDebouncedCallback;
