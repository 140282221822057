export const GET_BRAINSTORM_BY_ID_SUCCESS = 'GET_BRAINSTORM_BY_ID_SUCCESS';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
// in project-details.js the same const
// export const DELIVER_FEEDBACK = 'DELIVER_FEEDBACK';
// in project-details.js the same const
// export const DISABLE_ACTIVE_FEEDBACKCARD = 'DISABLE_ACTIVE_FEEDBACKCARD';
export const UPLOAD_BRAINSTORM_FEEDBACK_SUCCESS = 'UPLOAD_BRAINSTORM_FEEDBACK_SUCCESS';
export const DELETE_BRAINSTORM_FEEDBACK_SUCCESS = 'DELETE_BRAINSTORM_FEEDBACK_SUCCESS';
export const DECLINE_BRAINSTORM_SUCCESS = 'DECLINE_BRAINSTORM_SUCCESS';
export const GET_USER_MESSAGES_SUCCESS = 'GET_USER_MESSAGES_SUCCESS';
// in project-details.js the same const
// export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
// in project-details.js the same const
// export const MARK_AS_READ = 'MARK_AS_READ';
export const UPDATE_BRAINSTORM_SUCCESS = 'UPDATE_BRAINSTORM_SUCCESS';
export const ADD_BRAINSTORM_FILE_SUCCESS = 'ADD_BRAINSTORM_FILE_SUCCESS';
export const REMOVE_RAINSTORM_FILE_SUCCESS = 'REMOVE_RAINSTORM_FILE_SUCCESS';
export const ADD_PARTICIPANT_SUCCESS = 'ADD_PARTICIPANT_SUCCESS';
export const REMOVE_PARTICIPANT_SUCCESS = 'REMOVE_PARTICIPANT_SUCCESS';
export const TOGGLE_COMPLETE_PROJECT_MODAL = 'TOGGLE_COMPLETE_PROJECT_MODAL';
export const SHOW_COMPLETE_PROJECT_MODAL = 'SHOW_COMPLETE_PROJECT_MODAL';
export const HIDE_COMPLETE_PROJECT_MODAL = 'HIDE_COMPLETE_PROJECT_MODAL';
