import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseIconSVG from '../../../../assets/svg/cross-out.svg';
import { hideUpdateBar } from '../../../../actions/auth';

const PlatformUpdateBar = () => {
  const platformUpdate = useSelector(state => state.core.platformUpdate);
  const dispatch = useDispatch();
  const handleCloseIconClick = () => {
    dispatch(hideUpdateBar());
  };

  const formatedLink = (link) => {
    if (!link.includes('http')) {
      return `https://${link}`;
    } else {
      return link;
    }
  };
  return (platformUpdate.isVisible
    ? (
      <div className='platform-update-container'>
        <div className='title-container'>
          <div className='icon'>i</div>
          <div className='title'>
            {platformUpdate.text}
            .&nbsp;
            {(platformUpdate.link && platformUpdate.link !== 'null') ? (
              <a
                className='read-more-link'
                target='_blank'
                href={formatedLink(platformUpdate.link)}
                rel='noreferrer'
              >
                Read more
              </a>
            ) : ''}
          </div>
        </div>
        <CloseIconSVG
          className='close-icon'
          onClick={handleCloseIconClick}
        />
      </div>
    )
    : null);
};

export default PlatformUpdateBar;
