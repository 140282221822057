import * as Sentry from '@sentry/browser';

class ApiException extends Error {
  constructor(message) {
    super();

    this.name = 'API Exception';
    this.message = message;
  }
}

class Logger {
  // eslint-disable-next-line class-methods-use-this
  message(msg) {
    Sentry.captureMessage(msg, Sentry.Severity.Info);
  }

  // eslint-disable-next-line class-methods-use-this
  fatal(msg) {
    Sentry.captureMessage(msg, Sentry.Severity.Fatal);
  }

  // eslint-disable-next-line class-methods-use-this
  debug(msg) {
    Sentry.captureMessage(msg, Sentry.Severity.Debug);
  }

  // eslint-disable-next-line class-methods-use-this
  warning(msg) {
    Sentry.captureMessage(msg, Sentry.Severity.Warning);
  }

  // eslint-disable-next-line class-methods-use-this
  exception(error) {
    console.error(error);
    Sentry.captureException(error.message);
  }

  // eslint-disable-next-line class-methods-use-this
  exceptionWithScope(error, data) {
    console.error(error);

    Sentry.withScope(scope => {
      if (data && data.user) {
        scope.setUser(data.user);
      }

      if (data && data.url) {
        Sentry.addBreadcrumb({
          category: 'api',
          message: data.url,
          level: Sentry.Severity.Info,
        });
      }

      Sentry.captureException(new ApiException(error.message));
    });
  }
}

const sentryLogger = new Logger();

export default sentryLogger;
