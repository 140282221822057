const PROJECT_STATUS = {
  PENDING_ACCEPT: 'PENDING_ACCEPT',
  DELIVER_DRAFT: 'DELIVER_DRAFT',
  WAITING_FOR_FEEDBACK: 'WAITING_FOR_FEEDBACK',
  COMPLETED: 'COMPLETED',
  FINISHED: 'FINISHED',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  // additional statuses
  STARTED: 'STARTED',
  WAIT_FOR_DRAFT: 'WAIT_FOR_DRAFT',
  DRAFT_READY: 'DRAFT_READY',
  DELIVER_FEEDBACK: 'DELIVER_FEEDBACK',
  FEEDBACK_DELIVERED: 'FEEDBACK_DELIVERED',
};

const PROJECT_STATUS_ID = {
  [PROJECT_STATUS.PENDING_ACCEPT]: 1,
  [PROJECT_STATUS.DELIVER_DRAFT]: 2,
  [PROJECT_STATUS.WAITING_FOR_FEEDBACK]: 3,
  [PROJECT_STATUS.FINISHED]: 5,
  [PROJECT_STATUS.COMPLETED]: 4,
  [PROJECT_STATUS.INACTIVE]: 6,
  [PROJECT_STATUS.ACTIVE]: 7,
  [PROJECT_STATUS.DRAFT]: 8,
};

const PROJECT_STATUS_BY_ID = {
  1: PROJECT_STATUS.PENDING_ACCEPT,
  2: PROJECT_STATUS.DELIVER_DRAFT,
  3: PROJECT_STATUS.WAITING_FOR_FEEDBACK,
  4: PROJECT_STATUS.COMPLETED,
  5: PROJECT_STATUS.FINISHED,
  6: PROJECT_STATUS.INACTIVE,
  7: PROJECT_STATUS.ACTIVE,
  8: PROJECT_STATUS.DRAFT,
};

export {
  PROJECT_STATUS,
  PROJECT_STATUS_ID,
  PROJECT_STATUS_BY_ID,
};
