import * as types from '../constants';

const initialStateServices = [];

function services(state = initialStateServices, action) {
  switch (action.type) {
    case types.GET_SERVICES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default services;
