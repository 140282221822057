const PARTICIPANT_ROLES = {
  CLIENT: 'client',
  PROJECT_OWNER: 'projectOwner',
  FREELANCER: 'freelancer',
  ACCOUNT_MANAGER: 'accountManager',
  EVALUATOR: 'evaluator',
};

export {
  PARTICIPANT_ROLES,
};
