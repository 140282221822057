import * as types from '../constants';

const initialState = {
  admin: [],
  url: null,
  current_page: null,
  last_page: null,
  total: null,
  per_page: 10,
};

export default function admin(state = initialState, action) {
  switch (action.type) {
    case types.GET_ACTIVE_ADMIN_SUCCESS:
    case types.GET_INACTIVE_ADMIN_SUCCESS:
    case types.SEARCH_ADMIN_SUCCESS:
    case types.SET_PAGE_ADMIN_PAGE_SUCCESS:
      return {
        ...state,
        admin: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.GET_ALL_ACTIVE_ADMINS:
      return {
        ...state,
        admin: action.payload,
      };
    case types.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        admin: state.admin.filter(item => item.id !== action.payload),
      };
    case types.GET_ACTIVE_ADMIN_URL:
    case types.GET_INACTIVE_ADMIN_URL:
      return {
        ...state,
        url: action.payload,
      };
    default:
      return state;
  }
}
