import React, { useEffect, useRef, useState } from 'react';
import {
  calcCurrentCursorPosition,
  formatNumber,
  formattedValueToNumber,
  isDecimalExist,
  isValueDecimalTyping,
  isValueTypingValid,
  isValueValid,
  isWholePartChanged,
  setCursor,
  wasDecimalChanged,
} from './helpers';
import { isNotEmpty } from '../../../../utils';

/**
 * @typedef {'en-US'|'de-DE'} DecimalSymbolValue
 * @type {{COMMA: DecimalSymbolValue, DOT: DecimalSymbolValue}}
 */
export const DECIMAL_SYMBOL = {
  COMMA: 'de-DE',
  DOT: 'en-US',
};

/**
 * params are optionals as them are not all always required in parent component
 * @typedef {{
 *   value?: number,
 *   formattedValue?: string,
 *   isValid?: boolean,
 *   wasChanged?: boolean,
 * }} OnChangeParams
 */

/**
 * @typedef {{
 *   maximumFractionDigits?: number,
 *   withNegative?: boolean,
 * }} OptionsType
 */

/**
 * @type {FunctionComponent}
 * @param {string} [type='text']
 * @param {number} value
 * @param {string} [placeholder='']
 * @param {boolean} [disabled=false]
 * @param {(v: OnChangeParams) => void} onChange
 * @param {(() => void) | null} [onBlur=null]
 * @param {OptionsType} [options={}]
 * @param {string} [decimalSymbol=DECIMAL_SYMBOL.DOT]
 * @param {string} [className='']
 * @param {string} [dataTestId='']
 * @param {boolean} [onlyValidChange=false]
 * @param {string?} [id]
 * @param {string | null} [name=null]
 */
const FormattedInput = ({
  type = 'text',
  value,
  placeholder = '',
  disabled = false,
  onChange,
  onBlur = null,
  options = {},
  decimalSymbol = DECIMAL_SYMBOL.DOT,
  className = '',
  dataTestId = '',
  onlyValidChange = false,
  id,
  name = null,
}) => {
  const inputRef = useRef(null);

  const locale = Object.values(DECIMAL_SYMBOL).includes(decimalSymbol)
    ? decimalSymbol
    : DECIMAL_SYMBOL.DOT;

  const initFormattedValue = value ? formatNumber({ number: value, locale, options }) : value;
  const [inputValueState, setInputValueState] = useState(initFormattedValue);

  const updateInputValue = ({ value: newValue, inputValue: userInputValue = '', withSetCursor = true }) => {
    setInputValueState(newValue);
    const inputElement = inputRef.current;
    if (inputElement) {
      const { selectionEnd: currentCaretPosition } = inputElement;
      const nextCaretPosition = calcCurrentCursorPosition({
        inputValue: userInputValue,
        value: newValue,
        currentCaretPosition,
        locale,
        options,
      });
      withSetCursor && setTimeout(() => {
        setCursor(inputElement, nextCaretPosition);
      }, 0);
    }
  };

  const handleChange = ({ target: { value: curValue } }) => {
    // to avoid non decimal symbols
    const isValidTyping = isValueTypingValid({ string: curValue, locale, options });

    if (isValidTyping) {
      // check if value is empty
      const { withNegative } = options;
      const startSymbol = withNegative ? '-?' : '';
      const emptyRegExp = new RegExp(`^${startSymbol}$`);
      const isEmpty = emptyRegExp.test(curValue);

      // start or end with decimalSeparator
      const isDecimalTyping = isValueDecimalTyping({ string: curValue, locale, options });
      const decimalNotChanged = !wasDecimalChanged({
        oldValue: inputValueState,
        newValue: curValue,
        locale,
        options,
      });
      const hasDecimal = isDecimalExist({ string: curValue, locale, options });
      const wasWholePartChanged = isWholePartChanged({
        oldValue: inputValueState,
        newValue: curValue,
        locale,
        options,
      });

      const isTypingNotFinished = isDecimalTyping || isEmpty;

      const wasValueNumberNotChanged = decimalNotChanged && hasDecimal && !wasWholePartChanged;

      const shouldNotFormatValue = isTypingNotFinished || wasValueNumberNotChanged;

      const newValue = formattedValueToNumber({ string: curValue, locale, options });
      const newFormattedInputValue = shouldNotFormatValue
        ? curValue
        : formatNumber({ number: newValue, locale, options });
      const isValid = shouldNotFormatValue
        ? !isTypingNotFinished
        : isValueValid({ string: newFormattedInputValue, locale, options });
      const wasChanged = !shouldNotFormatValue;

      const returnObject = {
        value: newValue,
        formattedValue: newFormattedInputValue,
        isValid,
        wasChanged,
      };

      updateInputValue({ value: newFormattedInputValue, inputValue: curValue });

      const isValidChange = isValid && wasChanged;
      // change if not only valid = each change (by prop)
      // or if it valid
      const shouldChange = !onlyValidChange || isValidChange;
      shouldChange && onChange(returnObject);
    }
  };

  const handleBlur = (e) => {
    const numValueWithoutFormat = formattedValueToNumber({ string: inputValueState, options, locale });
    const numValue = Number.isNaN(numValueWithoutFormat) ? 0 : numValueWithoutFormat;
    const newFormattedValue = formatNumber({ number: numValue, locale, options });
    const shouldCallOnChange = inputValueState !== newFormattedValue;
    if (shouldCallOnChange) {
      updateInputValue({ value: newFormattedValue, withSetCursor: false });

      const isValid = isValueValid({ string: newFormattedValue, locale, options });
      const returnObject = {
        value: numValue,
        formattedValue: newFormattedValue,
        isValid,
        wasChanged: true,
      };
      onChange(returnObject);
    }

    // custom onBlur. setTimeout to call it after onChange
    setTimeout(() => onBlur?.(e), 0);
  };

  useEffect(() => {
    const isNewValueValid = isNotEmpty(value) && value !== '';
    const newFormattedValue = isNewValueValid ? formatNumber({ number: value, locale, options }) : '';
    const shouldChange = inputValueState !== newFormattedValue;
    shouldChange && updateInputValue({ value: newFormattedValue });
  }, [value]);

  return (
    <input
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
      value={inputValueState}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      ref={inputRef}
      className={className}
      data-test-id={dataTestId}
    />
  );
};

export default FormattedInput;
