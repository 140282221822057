import * as types from '../../constants';

const initialState = {
  fetching: [],
};

export default function fetchingReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_FETCHING: {
      return {
        ...state,
        fetching: [
          ...state.fetching,
          action.payload,
        ],
      };
    }
    case types.REMOVE_FETCHING: {
      return {
        ...state,
        fetching: state.fetching.filter(item => item !== action.payload),
      };
    }
    default: {
      return state;
    }
  }
}
