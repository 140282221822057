import {
  Entries, FilterElement, Label, SelectedFilterValueType, SelectedMultipleFilter, SelectedMultipleFilters,
} from '../types';
import { isEmpty } from './utils';
import { DropdownType, OrNull } from '../../types';
import { StringNumberType } from './projectAttachments';

export const DEFAULT_PER_PAGE: number = 10;

// multi || isArrayValue ? SelectedFilterValueType : FilterElement['id'];
interface GetNewFilterValue {
  <IsMulti extends boolean, IsArrayValue extends boolean>(
    params: {
      value: FilterElement['id'],
      multi?: IsMulti,
      isArrayValue?: IsArrayValue,
      filterValue: SelectedFilterValueType,
    }): (
    IsMulti extends true
      ? SelectedFilterValueType
      : IsArrayValue extends true
        ? SelectedFilterValueType
        : FilterElement['id']
  );
  (params: {
    value: FilterElement['id'],
    multi?: boolean,
    isArrayValue?: boolean,
    filterValue: SelectedFilterValueType,
  }): FilterElement['id']
}

export const getNewFilterValue: GetNewFilterValue = ({
  value,
  multi = false,
  isArrayValue = true,
  filterValue,
}) => {
  // change logic to remove/add if multi and return array value if isArray
  if (multi) {
    const isInArray = filterValue.includes(value);
    return isInArray
      ? filterValue.filter(item => item !== value)
      : [...filterValue, value];
  } else {
    return isArrayValue ? [value] : value;
  }
};

export const appendDefaultFilters = <T extends Object>(filters: T, defaultFilters: Partial<T>): T => {
  const filtersToBeAdded: Partial<T> = {};
  Object.entries(defaultFilters)
    .forEach(([key, value]) => {
      const isKeyExist: boolean = Object.hasOwn(filters, key);
      const isValueEmpty: boolean = isEmpty(filters[key]);
      const shouldAddValue: boolean = !isKeyExist || isValueEmpty;
      shouldAddValue && (filtersToBeAdded[key] = value);
    });

  return { ...filters, ...filtersToBeAdded };
};

export const formatFiltersValue = <T extends SelectedMultipleFilters>(filters: T): SelectedMultipleFilters => (
  Object.fromEntries(
    Object.entries(filters)
      // remove empty arrays and strings from filters
      .filter(([, value]) => {
        const isString = typeof value === 'string';
        const isArray = Array.isArray(value);
        return !(isString || isArray) || value.length;
      })
      .map(([key, value]) => [
        key,
        Array.isArray(value)
          ? value.join(',')
          : value,
      ]),
  )
);

export type FilterItemType = DropdownType & { checked: boolean };

export type FilterItemsType<Id = number, K extends string | number | symbol = string> = {
  [key in K]: DropdownType<Id>[];
};

export type FiltersStateItemType = { [id: number]: FilterItemType };

export type FiltersStateType<T extends string = string> = {
  [filterKey in T]: FiltersStateItemType
};

export type FilterLabelType<T extends string = string> = DropdownType & { filterKey: T };

export type FilterLabelsStateType<F extends string = string, K extends string = string> = { [key in K]: FilterLabelType<F> };

export const getFilterStateItems = (items: DropdownType[]): FiltersStateItemType => (
  items.reduce((filterStateItems, { id, name }) => ({
    ...filterStateItems,
    [id]: { id, name, checked: false },
  }), {})
);

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
  EMPTY = '',
}

const orderKeys: { [key in SortOrder]: true } = {
  [SortOrder.ASC]: true,
  [SortOrder.DESC]: true,
  [SortOrder.EMPTY]: true,
};

export const NEXT_SORT_DIRECTION: { [key in SortOrder]: SortOrder } = {
  [SortOrder.EMPTY]: SortOrder.DESC,
  [SortOrder.DESC]: SortOrder.ASC,
  [SortOrder.ASC]: SortOrder.EMPTY,
};

const isValidSortOrder = (order: string): order is SortOrder => (
  Object.keys(orderKeys).includes(order)
);

export const getSortCombination = <S>({ prevSortKey, prevSortOrder, sortKey }: {
  prevSortKey: S, prevSortOrder: SortOrder, sortKey: S,
}): { sortKey: S, sortOrder: SortOrder } => {
  // if keys are same - check if order is valid
  // if keys are different - set prev as empty
  const prevValidSortOrder = (prevSortKey === sortKey) && isValidSortOrder(prevSortOrder)
    ? prevSortOrder
    : SortOrder.EMPTY;
  return { sortKey, sortOrder: NEXT_SORT_DIRECTION[prevValidSortOrder] };
};

export const defaultSortCombination = {
  sortOrder: 'asc',
  sortKey: 'id',
};

export const getLabelInfo = <
  Id,
  K extends keyof I,
  I extends FilterItemsType<Id, K>>(
    { id, key }: { id: Id, key: K },
    filterItems: I,
  ): OrNull<Label<Id, K>> => {
  const filterItemsByKey: DropdownType<Id>[] = filterItems[key] ?? [];
  const name: string = filterItemsByKey.find(({ id: filterId }) => String(id) === String(filterId))?.name ?? '';
  return name ? { key, id, name } : null;
};

export const getLabels = <
  F extends SelectedMultipleFilters,
  I extends FilterItemsType<F[keyof F], keyof F>,
  AF extends string,
>(
    filters: Partial<F>,
    filterItems: I,
    arrayFields: AF[] = [],
  ): Label<StringNumberType, keyof F>[] => (
    (Object.entries(filters) as Entries<F>)
      .reduce<Label<StringNumberType, keyof F>[]>((curLabels, [filterKey, idValue]) => {
      const key = filterKey;
      const labelsInfoArray = Array.isArray(idValue)
        ? idValue.map((selectedId) => getLabelInfo<SelectedMultipleFilter, keyof I, I>({ id: selectedId, key }, filterItems))
        : [getLabelInfo<SelectedMultipleFilter, keyof I, I>({ id: idValue, key }, filterItems)];
      const isArrayField = arrayFields.includes(key as AF);
      const labelsToAdd = isArrayField ? labelsInfoArray.filter(Boolean) as Label[] : [];
      return [...curLabels, ...labelsToAdd];
    }, [])
  );
