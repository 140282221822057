import * as types from '../constants';

const initialState = [];

export default function contentTypes(state = initialState, action) {
  switch (action.type) {
    case types.GET_CONTENT_TYPES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
