import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { identifyUser, MIXPANEL_PROJECT_TOKEN } from '../utils';
import { RootState } from '../store';
import { OrNull } from '../types';

const useInitMixpanel = () => {
  const impersonateId = localStorage.getItem('impersonate_id');
  const userIdStore: OrNull<number> = useSelector((state: RootState) => state.auth.user?.id ?? null);
  // react doesn't rerender on localstorage change
  // on impersonate we update user field in redux, so this rerenders component
  const userId = impersonateId ?? userIdStore;

  useEffect(() => {
    if (MIXPANEL_PROJECT_TOKEN) {
      // init mixpanel
      mixpanel.init(MIXPANEL_PROJECT_TOKEN);

      return mixpanel.reset;
    }
  }, []);

  useEffect(() => {
    if (MIXPANEL_PROJECT_TOKEN) {
      userId
        ? identifyUser(userId)
        : mixpanel.reset();
    }
  }, [userId]);
};

export default useInitMixpanel;
