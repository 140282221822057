export const GET_ALERT_TYPES = 'GET_ALERT_TYPES';
export const GET_ALERTS = 'GET_ALERTS';
export const ALERT_URL = 'ALERT_URL';
export const FILTER_ALERTS = 'FILTER_ALERTS';
export const SET_ALERTS_PAGE = 'SET_ALERTS_PAGE';
export const UPDATE_ALERT_STATUS = 'UPDATE_ALERT_STATUS';
export const CHANGE_ALERT_STATUS = 'CHANGE_ALERT_STATUS';
export const CHANGE_INACTIVE_ALERT_STATUS = 'CHANGE_INACTIVE_ALERT_STATUS';
export const UPDATE_ALERT_COUNT = 'UPDATE_ALERT_COUNT';
export const SEARCH_ALERT = 'SEARCH_ALERT';
export const GET_ALERTS_CLIENTS = 'GET_ALERTS_CLIENTS';
export const UPDATE_ISLOADING = 'UPDATE_ISLOADING';
