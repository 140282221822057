import PencilIconSVG from '../../assets/svg/pencil.svg';
import ResearchIconSVG from '../../assets/svg/research.svg';
import LocalisationIconSVG from '../../assets/svg/translate.svg';
import TranslationIconSVG from '../../assets/svg/translationBG_cur-color.svg';
import EraserIconSVG from '../../assets/svg/eraser.svg';
import AlignmentIconSVG from '../../assets/svg/align-left.svg';
import { OrNull, SVGType } from '../../types';

const iconByServiceName: {
  Localisation: SVGType;
  'Re-writing': SVGType;
  'Content-creation': SVGType;
  Writing: SVGType;
  Editing: SVGType;
  Translation: SVGType;
  'Audio-transcription': SVGType
} = {
  Writing: PencilIconSVG,
  'Content-creation': PencilIconSVG,
  Editing: ResearchIconSVG,
  Translation: TranslationIconSVG,
  Localisation: LocalisationIconSVG,
  'Re-writing': EraserIconSVG,
  'Audio-transcription': AlignmentIconSVG,
};

export const getIconByServiceName = (name: string): OrNull<SVGType> => (
  iconByServiceName[name.replace(/ /g, '-')] ?? null
);
