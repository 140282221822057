import * as types from '../constants';

const initialState = {
  designers: [],
  url: null,
  alldesigners: [],
  current_page: null,
  last_page: null,
  total: null,
  per_page: 10,
};

export default function designers(state = initialState, action) {
  switch (action.type) {
    case types.GET_DESIGNERS_SUCCESS:
      return {
        ...state,
        alldesigners: action.payload.data,
      };
    case types.GET_ALL_DESIGNERS_SUCCESS:
    case types.SET_PAGE_DESIGNERS_PAGE_SUCCESS:
    case types.SEARCH_DESIGNERS_SUCCESS:
    case types.FILTER_DESIGNERS_BY_SUCCESS:
    case types.GET_TEAM_DESIGNERS_SUCCESS:
    case types.SET_PAGE_ADD_DESIGNER_TO_TEAM_SUCCESS:
    case types.SEARCH_TEAM_DESIGNERS:
      return {
        ...state,
        designers: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
        // we add Nullish coalescing operator,
        // as we don't know is in each request per_page field will exist
        per_page: action.payload.per_page ?? state.per_page,
      };
    case types.GET_REQUESTS_DESIGNERS_URL:
    case types.GET_ALL_DESIGNERS_URL:
    case types.GET_REQUESTS_TEAM_DESIGNER_URL:
    case types.SEARCH_DESIGNERS_URL:
    case types.FILTER_DESIGNERS_URL:
    case types.SET_DESIGNERS_PAGE_URL:
    case types.SEARCH_TEAM_DESIGNERS_URL:
      return {
        ...state,
        url: action.payload,
      };
    case types.ACCEPT_DESIGNERS_SUCCESS:
    case types.DECLINE_DESIGNERS_SUCCESS:
      return {
        ...state,
        designers: state.designers.filter(item => item.id !== action.payload),
        total: state.total - 1 || 0,
      };
    case types.UPDATE_DESIGNER_STATUS_SUCCESS:
      return {
        ...state,
        designers: state.designers.map(item => (item.id !== action.id ? item : {
          ...item,
          user: {
            ...item.user,
            state: action.payload,
          },
        })),
      };
    case types.DEACTIVATE_DESIGNER:
      return {
        ...state,
        designers: state.designers.map(item => (item.id !== action.payload.id ? item : {
          ...item,
          user: {
            ...item.user,
            state: action.payload.state,
          },
        })),
      };
    case types.UPDATE_DESIGNERS_PER_PAGE:
      return {
        ...state,
        per_page: action.payload,
      };
    default:
      return state;
  }
}
