import { useState } from 'react';

type UseModalReturnProps = {
  isOpen: boolean,
  handleOpen: () => void,
  handleClose: () => void,
  handleToggle: () => void,
};

type UseModalProps = {
  onClose?: VoidFunction,
  defaultIsOpen?: boolean,
};
type UseModalType = ({ onClose, defaultIsOpen }?: UseModalProps) => UseModalReturnProps;
const useModal: UseModalType = ({ onClose, defaultIsOpen = false } = {}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);
  const handleOpen: VoidFunction = () => setIsOpen(true);
  const handleClose: VoidFunction = () => {
    setIsOpen(false);
    onClose?.();
  };
  const handleToggle: VoidFunction = () => {
    setIsOpen((prevIsOpen: boolean) => {
      const newValue: boolean = !prevIsOpen;
      // call on close if new value is false
      !newValue && onClose?.();
      return newValue;
    });
  };

  return {
    isOpen,
    handleOpen,
    handleClose,
    handleToggle,
  };
};

export default useModal;
