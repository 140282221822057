import HrIcon from '../../assets/svg/industries/hr-recruitment.svg';
import ItIcon from '../../assets/svg/industries/it.svg';
import TechIcon from '../../assets/svg/industries/tech.svg';
import FinanceIcon from '../../assets/svg/industries/finance.svg';
import HealthIcon from '../../assets/svg/industries/healthcare.svg';
import LegalIcon from '../../assets/svg/industries/legal.svg';
import TravelIcon from '../../assets/svg/industries/travel.svg';
import MarketingIcon from '../../assets/svg/industries/media.svg';
import LogisticsIcon from '../../assets/svg/industries/logistics.svg';
import ConstructionIcon from '../../assets/svg/industries/construction.svg';
import FmcgIcon from '../../assets/svg/industries/fmcg.svg';
import SportsIcon from '../../assets/svg/industries/sports.svg';
import OtherIcon from '../../assets/svg/industries/other.svg';
import AgricultureIcon from '../../assets/svg/industries/agriculture.svg';
import MiningIcon from '../../assets/svg/industries/mining.svg';
import ManufacturingIcon from '../../assets/svg/industries/manufacturing.svg';
import EnergyIcon from '../../assets/svg/industries/energy.svg';
import UtilityIcon from '../../assets/svg/industries/utility.svg';
import HospitalityIcon from '../../assets/svg/industries/hospitality.svg';
import RealIcon from '../../assets/svg/industries/real-estate.svg';
import PoliticsIcon from '../../assets/svg/industries/politics.svg';
import EducationIcon from '../../assets/svg/industries/education.svg';
import FashionIcon from '../../assets/svg/industries/fashion.svg';
import RetailIcon from '../../assets/svg/industries/retail.svg';
import EcommerceIcon from '../../assets/svg/industries/ecommerce.svg';
import TelecomIcon from '../../assets/svg/industries/telecom.svg';
import InsuranceIcon from '../../assets/svg/industries/insurance.svg';
import ConsultingIcon from '../../assets/svg/industries/consulting.svg';
import ArtIcon from '../../assets/svg/industries/art.svg';
import HobbyIcon from '../../assets/svg/industries/hobby.svg';

export default {
  1: HrIcon,
  2: ItIcon,
  3: TechIcon,
  4: FinanceIcon,
  5: HealthIcon,
  6: LegalIcon,
  10: TravelIcon,
  11: MarketingIcon,
  12: LogisticsIcon,
  13: ConstructionIcon,
  14: FmcgIcon,
  15: SportsIcon,
  16: OtherIcon,
  17: AgricultureIcon,
  18: MiningIcon,
  19: ManufacturingIcon,
  20: EnergyIcon,
  21: UtilityIcon,
  22: HospitalityIcon,
  23: RealIcon,
  24: PoliticsIcon,
  25: EducationIcon,
  26: FashionIcon,
  27: RetailIcon,
  28: EcommerceIcon,
  29: TelecomIcon,
  30: InsuranceIcon,
  31: ConsultingIcon,
  32: ArtIcon,
  33: HobbyIcon,
};
