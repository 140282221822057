import * as types from '../constants';

const initialState = [];

export default function specialties(state = initialState, action) {
  switch (action.type) {
    case types.GET_SPECIALTIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
