import * as React from 'react';
import { ComponentType, ReactNode } from 'react';
import usePermissions, { UsePermissionsReturnType } from '../hooks/usePermissions/usePermissions';
import useAuthPermissions from '../hooks/usePermissions/useAuthPermissions';
import { Permissions, useOriginalPermissions } from '../hooks';

const withPermissions = <P extends object>(
  Component: ComponentType<P & {
    permissions: UsePermissionsReturnType,
    authPermissions: Permissions,
    originalPermissions: Permissions,
  }>,
) => (
    (props: P): ReactNode => {
      const permissions: UsePermissionsReturnType = usePermissions();
      const authPermissions: Permissions = useAuthPermissions();
      const originalPermissions: Permissions = useOriginalPermissions();

      return (
        <Component
          {...props}
          permissions={permissions}
          authPermissions={authPermissions}
          originalPermissions={originalPermissions}
        />
      );
    }
  );

export default withPermissions;
