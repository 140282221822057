export const GET_MY_PROJECTS_SUCCESS = 'GET_MY_PROJECTS_SUCCESS';
export const GET_MY_PROJECTS_URL = 'GET_MY_PROJECTS_URL';
export const GET_ACTIVE_PROJECTS_SUCCESS = 'GET_ACTIVE_PROJECTS_SUCCESS';
export const GET_ACTIVE_PROJECTS_URL = 'GET_ACTIVE_PROJECTS_URL';
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS';
export const GET_ALL_PROJECTS_URL = 'GET_ALL_PROJECTS_URL';
export const GET_FINISHED_PROJECTS_SUCCESS = 'GET_FINISHED_PROJECTS_SUCCESS';
export const GET_FINISHED_PROJECTS_URL = 'GET_FINISHED_PROJECTS_URL';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SORT_PROJECTS_SUCCESS = 'SORT_PROJECTS_SUCCESS';
export const SORT_PROJECTS_URL = 'SORT_PROJECTS_URL';
export const FILTER_PROJECTS_BY_SUCCESS = 'FILTER_PROJECTS_BY_SUCCESS';
export const FILTER_PROJECTS_BY_SUCCESS_URL = 'FILTER_PROJECTS_BY_SUCCESS_URL';
export const RESULTS_PER_PAGE_SUCCESS = 'RESULTS_PER_PAGE_SUCCESS';
export const RESULTS_PER_PAGE_FAILURE = 'RESULTS_PER_PAGE_FAILURE';
export const SET_PAGE_PROJECTS_PAGE_SUCCESS = 'SET_PAGE_PROJECTS_PAGE_SUCCESS';
export const SET_PAGE_PROJECTS_PAGE_URL = 'SET_PAGE_PROJECTS_PAGE_URL';
export const GET_FOR_SELECT_SUCCESS = 'GET_FOR_SELECT_SUCCESS';
export const GET_DRAFT_BRIEFINGS_SUCCESS = 'GET_DRAFT_BRIEFINGS_SUCCESS';
export const GET_DRAFT_BRIEFINGS_URL = 'GET_DRAFT_BRIEFINGS_URL';

export const DELETE_PROJECT_FROM_DASHBOARD_SUCCESS = 'DELETE_PROJECT_FROM_DASHBOARD_SUCCESS';
