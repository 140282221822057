import * as types from '../constants/price-plan-types';

const initialState = [];

function pricePlanTypes(state = initialState, action) {
  switch (action.type) {
    case types.GET_PRICE_PLAN_TYPE:
      return action.payload;
    default:
      return state;
  }
}

export default pricePlanTypes;
