import * as types from '../constants';

const initialState = {
  copywriters: [],
  url: null,
  allcopywriters: [],
  current_page: null,
  last_page: null,
  total: null,
  elasticSearchFilter: null,
  per_page: 10,
};

export default function copywriters(state = initialState, action) {
  switch (action.type) {
    case types.GET_COPYWRITERS_SUCCESS:
      return {
        ...state,
        allcopywriters: action.payload.data,
      };
    case types.GET_REQUESTS_FREELANCER_SUCCESS:
    case types.GET_ALL_COPYWRITERS_SUCCESS:
    case types.SET_PAGE_COPYWRITERS_PAGE_SUCCESS:
    case types.SEARCH_COPYWRITER_SUCCESS:
    case types.FILTER_COPYWRITERS_BY_SUCCESS:
    case types.GET_TEAM_WRITERS_SUCCESS:
    case types.SET_PAGE_ADD_WRITER_TO_TEAM_SUCCESS:
    case types.SEARCH_TEAM_WRITER_SUCCESS:
      return {
        ...state,
        copywriters: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
        per_page: action.payload.per_page,
      };
    case types.GET_REQUESTS_FREELANCER_URL:
    case types.GET_ALL_COPYWRITERS_URL:
    case types.GET_REQUESTS_TEAM_WRITERS_URL:
    case types.SEARCH_COPYWRITER_URL:
    case types.FILTER_COPYWRITERS_URL:
    case types.SET_COPYWRITERS_PAGE_URL:
      return {
        ...state,
        url: action.payload,
      };
    case types.ACCEPT_COPYWRITER_SUCCESS:
    case types.DECLINE_COPYWRITER_SUCCESS:
      return {
        ...state,
        copywriters: state.copywriters.filter(item => item.id !== action.payload),
        total: state.total - 1 || 0,
      };
    case types.UPDATE_WRITER_STATUS_SUCCESS:
      return {
        ...state,
        copywriters: state.copywriters.map(item => (item.id !== action.id ? item : {
          ...item,
          user: {
            ...item.user,
            state: action.payload,
          },
        })),
      };
    case types.DEACTIVATE_WRITER:
      return {
        ...state,
        copywriters: state.copywriters.map(item => (item.id !== action.payload.id ? item : {
          ...item,
          user: {
            ...item.user,
            state: action.payload.state,
          },
        })),
      };
    case types.ELASTIC_SEARCH_FILTER:
      return {
        ...state,
        elasticSearchFilter: action.payload,
      };
    case types.UPDATE_CW_PER_PAGE:
      return {
        ...state,
        per_page: action.payload,
      };
    default:
      return state;
  }
}
