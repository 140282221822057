import { useMemo } from 'react';
import usePermissions from './usePermissions';
import { formatPermissions } from './helpers';
import { Permissions } from './types';

const useAuthPermissions = (): Permissions => {
  const { keys, roles } = usePermissions();

  const authPermissions: Permissions = useMemo(() => (
    formatPermissions(keys, roles)
  ), [keys, roles]);

  return authPermissions;
};

export default useAuthPermissions;
