import * as types from '../constants';

const initialState = {
  alertsTypes: [],
  items: [],
  clients: [],
  url: null,
  current_page: null,
  last_page: null,
  total: 0,
  per_page: 10,
  alerts_count: null,
  isLoading: false,
};

export default function alerts(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALERT_TYPES: {
      return {
        ...state,
        alertsTypes: action.payload,
      };
    }
    case types.GET_ALERTS:
    case types.FILTER_ALERTS:
    case types.SET_ALERTS_PAGE:
    case types.SEARCH_ALERT:
    {
      return {
        ...state,
        items: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    }
    case types.GET_ALERTS_CLIENTS: {
      return {
        ...state,
        clients: action.payload,
      };
    }
    case types.ALERT_URL: {
      return {
        ...state,
        url: action.payload,
      };
    }
    case types.UPDATE_ALERT_STATUS: {
      return {
        ...state,
        items: state.items.filter(item => !action.payload.includes(item.id)),
      };
    }
    case types.CHANGE_ALERT_STATUS: {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;

            return item;
          }

          return item;
        }),
      };
    }
    case types.CHANGE_INACTIVE_ALERT_STATUS: {
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
    }
    case types.RECEIVE_PROFILE_SUCCESS: {
      return {
        ...state,
        alerts_count: action.payload.alerts_count,
      };
    }
    case types.UPDATE_ALERT_COUNT: {
      return {
        ...state,
        alerts_count: state.alerts_count + action.payload,
      };
    }
    case types.UPDATE_ISLOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    default:
      return state;
  }
}
