import * as types from '../constants';

const initialStateLanguages = [];

export function languages(state = initialStateLanguages, action) {
  switch (action.type) {
    case types.GET_LANGUAGES_SUCCESS:
      return action.payload;
    case types.DELETE_LANGUAGE:
      return state.filter(item => item.id !== action.payload);
    case types.UPDATE_LANGUAGE:
      return state.map(item => (item.id === action.payload.id ? action.payload : item));
    case types.CREATE_LANGUAGE:
      return [
        ...state,
        action.payload,
      ];
    default:
      return state;
  }
}

const initialStateLanguagesById = {};

export function languagesById(state = initialStateLanguagesById, action) {
  switch (action.type) {
    case types.GET_LANGUAGES_SUCCESS:
      return action.payload.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    default:
      return state;
  }
}
