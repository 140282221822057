import * as types from '../constants';

const initialState = {
  freelancers: [],
  freelancerFromClientTeam: null,
  url: null,
  current_page: 1,
  last_page: null,
  total: null,
  per_page: 8,
  project: null,
};

export default function projectFlow(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROJECT_FLOW_WRITERS_SUCCESS:
    case types.GET_PROJECT_FLOW_DESIGNERS_SUCCESS:
      return {
        ...state,
        freelancers: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.GET_PROJECT_FLOW_FREE_DESIGNERS_SUCCESS:
      return {
        ...state,
        freelancers: action.payload,
      };
    case types.SET_PROJECT_FLOW_WRITERS_PAGE_URL:
      return {
        ...state,
        url: action.payload,
      };
    case types.GET_FREE_WRITERS_SUCCESS:
      return {
        ...state,
        freelancers: action.payload,
      };
    case types.GET_WRITER_BY_ID_SUCCESS:
    case types.GET_DESIGNER_BY_ID_SUCCESS:
    case types.ASSIGN_WRITER:
      return {
        ...state,
        freelancerFromClientTeam: action.payload,
      };
    case types.UNASSIGN_WRITER:
    case types.UNASSIGN_FREELANCER:
      return {
        ...state,
        freelancerFromClientTeam: null,
      };
    case types.SET_PROJECT_IN_PROJECT_FLOW:
      return {
        ...state,
        project: action.payload,
      };
    default:
      return state;
  }
}
