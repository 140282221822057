export const GET_SIGNUPS_SUCCESS = 'GET_SIGNUPS_SUCCESS';
export const GET_SIGNUPS_URL = 'GET_SIGNUPS_URL';
export const ACTIVATE_SIGNUP_SUCCESS = 'ACTIVATE_SIGNUP_SUCCESS';
export const UPDATE_SIGNUP_STATUS_SUCCESS = 'UPDATE_SIGNUP_STATUS_SUCCESS';
export const SEARCH_SIGNUPS_SUCCESS = 'SEARCH_SIGNUPS_SUCCESS';
export const FILTER_SIGNUPS_BY_SUCCESS = 'FILTER_SIGNUPS_BY_SUCCESS';
export const SEARCH_SIGNUPS_URL = 'SEARCH_SIGNUPS_URL';
export const FILTER_SIGNUPS_URL = 'FILTER_SIGNUPS_URL';
export const SET_PAGE_SIGNUPS_SUCCESS = 'SET_PAGE_SIGNUPS_SUCCESS';
export const SET_SIGNUPS_PAGE_URL = 'SET_SIGNUPS_PAGE_URL';
export const ASSIGN_EVALUATOR_SUCCESS = 'ASSIGN_EVALUATOR_SUCCESS';
export const SET_SIGN_UPS_LIMIT = 'SET_SIGN_UPS_LIMIT';
export const ONBOARDING_PLANNED_SUCCESS = 'ONBOARDING_PLANNED_SUCCESS';
export const ASSIGN_PLAN_SUCCESS = 'ASSIGN_PLAN_SUCCESS';
