const WEB_HOOK_URL = process.env.REACT_APP_WEB_HOOK_URL;

const sendSlackMessage = ({
  type,
  text,
  apiUrl,
  user,
  location,
  impersonateId,
  data,
  isFormData = false,
  headers,
  method,
}) => {
  const {
    id,
    name,
    email,
    role,
  } = user || {};

  const requestBody = {
    text,
    blocks: [
      !!type && {
        type: 'header',
        text: {
          type: 'plain_text',
          text: type,
        },
      },
      !!text && {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Text:* ${text}`,
        },
      },
      !!location && {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Location URL:* <${location}|${location}>`,
        },
      },
      !!method && {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Method:* ${method}`,
        },
      },
      !!apiUrl && {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Request URL:* <${apiUrl}|${apiUrl}>`,
        },
      },
      !!impersonateId && {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Impersonate ID:* ${impersonateId}`,
        },
      },
      !!user && {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Name:* ${name}`,
          },
          {
            type: 'mrkdwn',
            text: `*Email:* ${email}`,
          },
          {
            type: 'mrkdwn',
            text: `*Role:* ${role}`,
          },
          {
            type: 'mrkdwn',
            text: `*ID:* ${id}`,
          },
        ],
      },
      !!data && {
        type: 'divider',
      },
      !!data && {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: '*Body:*',
        },
      },
      !!data && {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Data:* ${data}`,
          },
          {
            type: 'mrkdwn',
            text: `*isFormData:* ${isFormData}`,
          },
        ],
      },
      !!headers && {
        type: 'divider',
      },
      !!headers && {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Headers:* ${headers}`,
        },
      },
    ].filter(Boolean),
  };

  const noDataError = new Error('No data to send');
  const webHookUrlNotExistError = new Error('REACT_APP_WEB_HOOK_URL in .env doesn\'t exist or has empty value');

  return requestBody.blocks.length
    ? WEB_HOOK_URL
      ? fetch(WEB_HOOK_URL, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(requestBody),
      })
      : Promise.reject(webHookUrlNotExistError)
    : Promise.reject(noDataError);
};

export default sendSlackMessage;
