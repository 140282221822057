import { client } from '../../utils';

type CreatePlatformFeeResponseType = {
  client_id: number,
  financial_id : number,
  id : number,
  price_plan_type_id : number
};

type CreatePlatformFeeType = ({ clientId, totalPrice }: {
  clientId: number,
  totalPrice: number
}) => Promise<CreatePlatformFeeResponseType>;
export const createPlatformFee: CreatePlatformFeeType = ({ clientId, totalPrice }) => {
  const formData = new FormData();

  formData.append('client_id', String(clientId));
  formData.append('total_price', String(totalPrice));

  return client.post('/client-platform-subscription', formData)
    .then(({ data: { result } }: { data: { result: CreatePlatformFeeResponseType } }) => result)
    .catch(({ data: { message } }: { data: { message: string } }) => Promise.reject(message));
};
