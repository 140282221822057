import { BackendConstantsKeys } from '../utils';
import useApi from './useApi';
import { getConstants } from '../actions/api-requests-only';

type UseProjectValidFileExtensions = (isCrowdinProject?: boolean) => {
  isLoading: boolean,
  data: string[],
  isFailed?: boolean,
};

const useProjectValidFileExtensions: UseProjectValidFileExtensions = (isCrowdinProject = false) => {
  const constantsKeys: BackendConstantsKeys[] = [
    BackendConstantsKeys.PROJECT_VALID_FILE_EXTENSIONS,
    BackendConstantsKeys.PROJECT_VALID_CROWDIN_FILE_EXTENSIONS,
  ];
  const {
    isLoading,
    isFailed,
    data: validFileExtensions,
  } = useApi<{
    [key in BackendConstantsKeys]?: string[]
  }, {
    keys: BackendConstantsKeys[]
  }>({
    api: getConstants,
    requestData: { keys: constantsKeys },
    defaultValue: {},
    shouldFetchOnRequestDataChange: false,
  });

  const allowedProjectExtensionKey = isCrowdinProject
    ? BackendConstantsKeys.PROJECT_VALID_CROWDIN_FILE_EXTENSIONS
    : BackendConstantsKeys.PROJECT_VALID_FILE_EXTENSIONS;
  const allowedExtensions: string[] = validFileExtensions[allowedProjectExtensionKey] ?? [];

  return {
    isLoading,
    isFailed,
    data: allowedExtensions,
  };
};

export default useProjectValidFileExtensions;
