import { isNotEmpty } from '../../../../utils';

function setCursor(element, selectionPosition) {
  const strNone = 'none';
  const isAndroid = typeof navigator !== 'undefined' && /Android/i.test(navigator.userAgent);
  const defer = typeof requestAnimationFrame !== 'undefined' ? requestAnimationFrame : setTimeout;
  if (document.activeElement === element) {
    if (isAndroid) {
      defer(() => element.setSelectionRange(selectionPosition, selectionPosition, strNone), 0);
    } else {
      element.setSelectionRange(selectionPosition, selectionPosition, strNone);
    }
  }
}

/**
 * @typedef {{
 *   number: number,
 *   locale: 'en-US'|'de-DE',
 *   options?: Object,
 * }} formatNumberOptions
 * number - the localized number
 * locale - the options that the number is represented in
 * options - the options that the number is represented in
 */
/**
 * Format number.
 * @param {formatNumberOptions} params
 * @return {string}
 */
const formatNumber = ({ number, locale, options = {} }) => {
  // maximumFractionDigits by default is 3, so we need to change it manually to max value (20 is the largest value)
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#maximumfractiondigits
  const isMaxDecimalCountExist = isNotEmpty(options.maximumFractionDigits);
  const maxDecimalCountOption = isMaxDecimalCountExist ? {} : { maximumFractionDigits: 20 };
  const formatterOptions = { ...options, ...maxDecimalCountOption };
  const formatter = new Intl.NumberFormat(locale, formatterOptions);
  return formatter.format(number);
};

/**
 * Parse a localized number to a float.
 * @param {'en-US'|'de-DE'} locale - the options that the number is represented in
 * @param {object} options - the options that the number is represented in
 */
const getSeparators = ({ locale, options: initOptions }) => {
  const { maximumFractionDigits, ...withoutMaxFractionDigits } = initOptions;
  const options = maximumFractionDigits === 0 ? withoutMaxFractionDigits : initOptions;
  const formattedParts = new Intl.NumberFormat(locale, options).formatToParts(1234.5);
  const { value: thousandSeparator } = formattedParts.find(({ type }) => (type === 'group')) ?? { value: null };
  // decimal symbol maybe not exist (maximumFractionDigits = 0)
  const { value: decimalSeparator } = formattedParts.find(({ type }) => (type === 'decimal')) ?? { value: null };
  const withDecimal = maximumFractionDigits !== 0;
  return {
    thousandSeparator,
    decimalSeparator,
    withDecimal,
  };
};

/**
 * Parse a localized number to a float.
 * @param {string} string - the localized number
 * @param {'en-US'|'de-DE'} locale - the options that the number is represented in
 * @param {object} options - the options that the number is represented in
 * @return {number}
 */
const formattedValueToNumber = ({ string, locale, options }) => {
  const { withDecimal, thousandSeparator, decimalSeparator } = getSeparators({ locale, options });

  const formattedValue = string
    .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
    .replace(new RegExp(`\\${decimalSeparator}`), '.');

  return withDecimal ? parseFloat(formattedValue) : parseInt(formattedValue, 10);
};

/**
 * Parse a localized number to a float.
 * @param {string} string - the localized number
 * @param {'en-US'|'de-DE'} locale - the options that the number is represented in
 * @param {object} options - the options that the number is represented in
 */
const isValueValid = ({ string, locale, options }) => {
  const { withNegative } = options;
  const startSymbol = withNegative ? '-?' : '';
  const { withDecimal, thousandSeparator, decimalSeparator } = getSeparators({ locale, options });
  // todo test thousandSeparator should always exist
  const thousandGroups = thousandSeparator ? `\\d{1,3}(?:\\${thousandSeparator}\\d{3})*` : '\\d+';
  const decimalPart = withDecimal ? `(?:\\${decimalSeparator}\\d+)?` : '';
  const regExp = new RegExp(`^${startSymbol}${thousandGroups}${decimalPart}$`);
  return regExp.test(string);
};

const isValueTypingValid = ({ string, locale, options }) => {
  const { withNegative } = options;
  const startSymbol = withNegative ? '-?' : '';
  const { withDecimal, thousandSeparator, decimalSeparator } = getSeparators({ locale, options });
  const isMaxDecimalCountExist = isNotEmpty(options.maximumFractionDigits);
  const decimalCount = isMaxDecimalCountExist ? `{0,${options.maximumFractionDigits}}` : '*';
  // it works with maximumFractionDigits = 0, (\d{0,0})
  // but 'withDecimal' condition will simplify regexp
  const decimalPart = withDecimal ? `(?:\\${decimalSeparator}\\d${decimalCount})?` : '';
  const regExp = new RegExp(`^${startSymbol}(?:\\${thousandSeparator}?\\d?)*${decimalPart}$`);
  // not allow input just thousand separators
  const justThousandSeparatorRegExp = new RegExp(`^${startSymbol}\\${thousandSeparator}+$`);
  return !justThousandSeparatorRegExp.test(string) && regExp.test(string);
};

const isValueDecimalTyping = ({ string, locale, options }) => {
  const { withNegative } = options;
  const startSymbol = withNegative ? '-?' : '';
  const { decimalSeparator } = getSeparators({ locale, options });
  const regExp = new RegExp(`(^${startSymbol}\\${decimalSeparator})|(\\${decimalSeparator}$)`);
  return regExp.test(string);
};

const calcCurrentCursorPosition = ({
  inputValue,
  value,
  currentCaretPosition,
  locale,
  options,
}) => {
  const { thousandSeparator } = getSeparators({ locale, options });
  // if in line start
  if (currentCaretPosition === 0) {
    return 0;
    // if in line end
  } else if (currentCaretPosition === inputValue.length) {
    return value.length;
  } else {
    const valueToCheck = inputValue.slice(currentCaretPosition);
    const chartsArray = [...valueToCheck].reverse();
    let caretPosition = value.length;
    // console.info({ inputValue, value, currentCaretPosition, valueToCheck, caretPosition });

    // check same charts from end to CaretPosition
    for (let chartIndex = 0; chartIndex < chartsArray.length; chartIndex += 1) {
      const chart = chartsArray[chartIndex];

      // ignore chart thousandSeparator
      if (chart !== thousandSeparator) {
        // ignore all thousandSeparator
        // '-1' as caretPosition is after current chart
        while (value[caretPosition - 1] === thousandSeparator) {
          caretPosition -= 1;
        }

        // '-1' as caretPosition is after current chart
        const valueChart = value[caretPosition - 1];
        if (valueChart === chart) {
          caretPosition -= 1;
        }
      }
    }

    // move before thousandSeparator
    if (value[caretPosition - 1] === thousandSeparator) {
      caretPosition -= 1;
    }

    return caretPosition;
  }
};

const wasDecimalChanged = ({
  oldValue, newValue, locale, options,
}) => {
  const { decimalSeparator } = getSeparators({ locale, options });
  const decimalRegExp = new RegExp(`\\${decimalSeparator}\\d*$`);
  // match array can be null
  const oldDecimal = ((oldValue ?? '').toString().match(decimalRegExp)?.[0]) ?? decimalSeparator;
  const newDecimal = ((newValue ?? '').toString().match(decimalRegExp)?.[0]) ?? decimalSeparator;
  // if decimal === decimalSeparator -> decimal is '' or '$decimalSeparator'
  const oldDecimalNumber = oldDecimal === decimalSeparator ? 0 : +oldDecimal.replace(decimalSeparator, '.');
  const newDecimalNumber = newDecimal === decimalSeparator ? 0 : +newDecimal.replace(decimalSeparator, '.');
  return oldDecimalNumber !== newDecimalNumber;
};

const isDecimalExist = ({ string, locale, options }) => {
  const { decimalSeparator } = getSeparators({ locale, options });
  const regExp = new RegExp(`\\${decimalSeparator}\\d+$`);
  return regExp.test(string);
};

const isWholePartChanged = ({
  oldValue, newValue, locale, options,
}) => {
  const { withNegative } = options;
  const startSymbol = withNegative ? '-?' : '';
  const { withDecimal, thousandSeparator, decimalSeparator } = getSeparators({ locale, options });
  const isMaxDecimalCountExist = isNotEmpty(options.maximumFractionDigits);
  const decimalCount = isMaxDecimalCountExist ? `{0,${options.maximumFractionDigits}}` : '*';
  // it works with maximumFractionDigits = 0, (\d{0,0})
  // but 'withDecimal' condition will simplify regexp
  const decimalPart = withDecimal ? `(?:\\${decimalSeparator}\\d${decimalCount})?` : '';
  const wholePart = `(?:\\${thousandSeparator}?\\d?)*`;
  const regExp = new RegExp(`^(${startSymbol}${wholePart})${decimalPart}$`);
  // get first group - it's whole part
  // warn: match array can be null
  const oldInt = ((oldValue ?? '').toString().match(regExp)?.[1]) ?? '';
  const newInt = ((newValue ?? '').toString().match(regExp)?.[1]) ?? '';
  // replace thousandSeparator with ''
  const replaceThousandRegExp = new RegExp(`\\${thousandSeparator}`, 'g');
  const oldIntNumber = oldInt.replace(replaceThousandRegExp, '');
  const newIntNumber = newInt.replace(replaceThousandRegExp, '');
  // check numbers as string
  return oldIntNumber !== newIntNumber;
};

export {
  calcCurrentCursorPosition,
  formatNumber,
  formattedValueToNumber,
  getSeparators,
  isValueDecimalTyping,
  isValueTypingValid,
  isValueValid,
  setCursor,
  wasDecimalChanged,
  isDecimalExist,
  isWholePartChanged,
};
