import * as types from '../constants';

const initialState = {
  url: null,
  current_page: null,
  last_page: null,
  total: null,
  per_page: 10,
  plans: [],
  financialReports: [],
  freelancerClients: {
    countClients: 0,
    clients: [],
  },
  writerView: {
    freelancerClients: [],
    contentTypes: [],
  },
};

export default function freelancerFinancial(state = initialState, action) {
  switch (action.type) {
    case types.GET_WRITER_FINANCIALS:
      return {
        ...state,
        plans: action.payload,
      };
    case types.GET_WRITER_PLAN_CLIENTS:
      return {
        ...state,
        freelancerClients: {
          countClients: action.payload.clientsCount,
          clients: action.payload.freelancersPlans,
        },
      };
    case types.SET_WRITER_PLANS_PAGE:
      return {
        ...state,
        plans: action.payload,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.GET_WRITER_VIEW:
      return {
        ...state,
        writerView: {
          freelancerClients: action.payload.client_freelancer_plans,
          contentTypes: action.payload.contentTypes,
        },
      };
    case types.GET_FREELANCER_PROJECTS_REPORTS:
      return {
        ...state,
        financialReports: action.payload,
      };
    case types.GET_WRITER_PLANS_URL:
    case types.SET_WRITER_PLANS_URL:
    case types.GET_WRITER_CLIENT_PLAN_URL:
    case types.SET_WRITER_CLIENT_PLAN_URL:
    case types.GET_WRITER_VIEW_URL:
    case types.SET_WRITER_VIEW_URL:
      return { ...state, url: action.payload };
    default:
      return state;
  }
}
