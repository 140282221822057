import { FREELANCER_ROLES } from './freelancerRoles';

const FREELANCER_TYPE_TO_LOCALSTORAGE = {
  [FREELANCER_ROLES.WRITER]: 'writer',
  [FREELANCER_ROLES.DESIGNER]: 'designer',
  [FREELANCER_ROLES.EXTERNAL]: 'writer', // default
};

export {
  FREELANCER_TYPE_TO_LOCALSTORAGE,
};
