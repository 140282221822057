import React, { Fragment, Suspense, SuspenseProps } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { PublicRouteProps } from './interfaces';

const PublicRoute: React.FunctionComponent<PublicRouteProps> = ({
  component: Component,
  lang,
  componentProps = {},
  children = null,
  lazy = false,
  ...rest
}) => {
  const Wrapper = lazy
    ? Suspense
    : Fragment;

  const wrapperProps = (lazy ? { fallback: null } : {}) as SuspenseProps;

  return (
    <Route
      {...rest}
    >
      {(routeProps) => (
        children || (
          <Wrapper
            {...wrapperProps}
          >
            <Component
              {...routeProps}
              {...componentProps}
              lang={lang}
            />
          </Wrapper>
        )
      )}
    </Route>
  );
};

// to get access to the history object’s properties
export default withRouter(PublicRoute);
