import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Alert.module.scss';
import ApproveIconSVG from '../../../../assets/svg/check.svg';

const Alert = () => {
  const alert = useSelector(state => state.core.alert);

  return (
    alert.isVisible ? (
      <div className={styles.alertContainer}>
        <div className={styles.alert}>
          <ApproveIconSVG className={styles.alertIcon} />
          <div className={styles.alertTitle}>
            {alert.message}
          </div>
        </div>
      </div>
    ) : null
  );
};

export default Alert;
