export const GET_COPYWRITERS_SUCCESS = 'GET_COPYWRITERS_SUCCESS';
export const GET_REQUESTS_FREELANCER_SUCCESS = 'GET_REQUESTS_FREELANCERS_SUCCESS';
export const GET_REQUESTS_FREELANCER_URL = 'GET_REQUESTS_FREELANCER_URL';
export const GET_ALL_COPYWRITERS_SUCCESS = 'GET_ALL_COPYWRITERS_SUCCESS';

export const GET_ALL_COPYWRITERS_URL = 'GET_ALL_COPYWRITERS_URL';
export const SEARCH_COPYWRITER_SUCCESS = 'SEARCH_COPYWRITER_SUCCESS';
export const FILTER_COPYWRITERS_BY_SUCCESS = 'FILTER_COPYWRITERS_BY_SUCCESS';
export const SET_PAGE_COPYWRITERS_PAGE_SUCCESS = 'SET_PAGE_COPYWRITERS_PAGE_SUCCESS';
export const ACCEPT_COPYWRITER_SUCCESS = 'ACCEPT_COPYWRITER_SUCCESS';
export const DECLINE_COPYWRITER_SUCCESS = 'DECLINE_COPYWRITER_SUCCESS';
export const SEARCH_COPYWRITER_URL = 'SEARCH_COPYWRITER_URL';
export const FILTER_COPYWRITERS_URL = 'FILTER_COPYWRITERS_URL';
export const SET_COPYWRITERS_PAGE_URL = 'SET_COPYWRITERS_PAGE_URL';
export const UPDATE_WRITER_STATUS_SUCCESS = 'UPDATE_WRITER_STATUS_SUCCESS';
export const ELASTIC_SEARCH_FILTER = 'ELASTIC_SEARCH_FILTER';
export const UPDATE_CW_PER_PAGE = 'UPDATE_CW_PER_PAGE';
