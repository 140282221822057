import { PermissionsListAction } from '../constants';
import { UserPermissionKeys } from '../types';

export type PermissionsList = {
  [key in UserPermissionKeys]: string
};

type PermissionsListStateType = {
  permissions: PermissionsList;
};
const initialState: PermissionsListStateType = {
  permissions: {} as PermissionsList,
};

type PermissionsListActionType = {
  type: PermissionsListAction.SET_PERMISSIONS;
  payload: PermissionsList;
};

const permissionsListReducer = (state = initialState, action: PermissionsListActionType) => {
  switch (action.type) {
    case PermissionsListAction.SET_PERMISSIONS: {
      return { ...state, permissions: action.payload };
    }
    default:
      return state;
  }
};

export default permissionsListReducer;
