import { StringNumberType } from './projectAttachments';

type FilterValueType = StringNumberType | StringNumberType[];
type FiltersType = {
  [key: string]: FilterValueType,
};
export const getQueryParamsString = (filters:FiltersType): string => (
  Object
    .entries(filters)
    .reduce((accumulator, [key, values]) => {
      const prefix = accumulator ? `${accumulator}&` : '';
      return (
        typeof values === 'number' || values.length
          ? Array.isArray(values)
            ? `${prefix}${key}=${values.join(',')}`
            : `${prefix}${key}=${values}`
          : accumulator
      );
    }, '')
);
