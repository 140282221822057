import { FunctionComponent, SVGAttributes } from 'react';
import { SimpleUserType, UserRoles, UserStates } from './utils';

export type OrNull<Type> = Type | null;

export type ValueOf<T> = T[keyof T];

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type ItemOrArray<T> = T | T[];

export enum UserPermissionKeys {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  WRITER = 'WRITER',
  DESIGNER = 'DESIGNER',
  PROFESSIONAL = 'PROFESSIONAL',
  FREELANCER = 'FREELANCER',
}

// TODO Should be added soon
export type IUser = UserType & {
  type: UserRoles,
  roles: DropdownType[],
  originalRoles: DropdownType[],
  tipalti_id: OrNull<number>,
  tipalti_complete_onboarding_at: OrNull<string>,
  tipalti_vat_status: number,
  tipalti_vat_type_id: number,
  state: UserStates,
  client_id: OrNull<number>,
};

export type UserType = SimpleUserType & {
  originalType: UserRoles,
  type: UserRoles,
};

export type DropdownType<IdType = number> = {
  id: IdType,
  name: string,
};

export type ListItemWithTitle<IdType = number> = {
  id: IdType,
  title: string,
};

export type SVGType = FunctionComponent<SVGAttributes<SVGElement>>;

export type Translation = (key: string, options?: Object) => string;

export type WithoutKey<T, K extends keyof T> = {
  [P in Exclude<keyof T, K>]: T[P];
};

export type RequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

export type DummyType = {};

// https:// stackoverflow.com/questions/52489261/typescript-can-i-define-an-n-length-tuple-type
type TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N ? R : TupleOf<T, N, [T, ...R]>;
export type Tuple<T, N extends number> = N extends N ? number extends N ? T[] : TupleOf<T, N, []> : never;

export type TimeoutType = ReturnType<typeof setTimeout> | null;
