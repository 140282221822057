const SERVICE_KEYS = {
  1: 'write',
  2: 'edit',
  3: 'localisation',
  4: 'rewrite',
};

const SEO_OPTIMIZES_TYPE = {
  NOT_EXPERIENCED: 'not experienced',
  SOMEWHAT_EXPERIENCED: 'somewhat experienced',
  VERY_EXPERIENCED: 'very experienced',
};

// TODO optimise SEO_OPTIMIZES_ID constant
const SEO_OPTIMIZES_ID = {
  1: SEO_OPTIMIZES_TYPE.NOT_EXPERIENCED,
  2: SEO_OPTIMIZES_TYPE.SOMEWHAT_EXPERIENCED,
  3: SEO_OPTIMIZES_TYPE.VERY_EXPERIENCED,
};

const SEO_OPTIMIZES_TITLE = {
  [SEO_OPTIMIZES_TYPE.NOT_EXPERIENCED]: 'Not experienced',
  [SEO_OPTIMIZES_TYPE.SOMEWHAT_EXPERIENCED]: 'Somewhat experienced',
  [SEO_OPTIMIZES_TYPE.VERY_EXPERIENCED]: 'Very experienced',
};

export {
  SERVICE_KEYS,
  SEO_OPTIMIZES_TYPE,
  SEO_OPTIMIZES_ID,
  SEO_OPTIMIZES_TITLE,
};
