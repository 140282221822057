const featureFlags = {
  NEW_DESIGN_DRAFTS: 'SHOULD_SHOW_NEW_DESIGN_DRAFTS',
  NEW_DESIGN_BRIEFING: 'SHOULD_SHOW_NEW_DESIGN_BRIEFING',
  OLD_PROJECT_FINANCIALS: 'SHOULD_SHOW_OLD_PROJECT_FINANCIALS',
  NEW_DESIGN_PROJECT_FINANCIALS: 'SHOULD_SHOW_NEW_DESIGN_PROJECT_FINANCIALS',
  NEW_LOGIC_ADMIN_FINANCIALS: 'SHOULD_SHOW_NEW_LOGIC_ADMIN_FINANCIALS',
  NEW_LOGIC_CLIENT_ADMIN_FINANCIALS: 'SHOULD_SHOW_NEW_LOGIC_CLIENT_ADMIN_FINANCIALS',
  NEW_LOGIC_FREELANCER_FINANCIALS: 'SHOULD_SHOW_NEW_LOGIC_FREELANCER_FINANCIALS',
};

const featureFlagValue = 'SHOULD_SHOW';

export {
  featureFlags,
  featureFlagValue,
};
