const WRITING_PROJECT_TOOLS = {
  MICROSOFT_WORD: 1,
  GOOGLE_DOCS: 2,
  CROWDIN: 3,
};

const WRITING_PROJECT_TOOLS_NAMES = {
  MICROSOFT_WORD: 'MICROSOFT_WORD',
  GOOGLE_DOCS: 'GOOGLE_DOCS',
  CROWDIN: 'CROWDIN',
};

const WRITING_PROJECT_TOOLS_BY_ID = {
  [WRITING_PROJECT_TOOLS.MICROSOFT_WORD]: WRITING_PROJECT_TOOLS_NAMES.MICROSOFT_WORD,
  [WRITING_PROJECT_TOOLS.GOOGLE_DOCS]: WRITING_PROJECT_TOOLS_NAMES.GOOGLE_DOCS,
  [WRITING_PROJECT_TOOLS.CROWDIN]: WRITING_PROJECT_TOOLS_NAMES.CROWDIN,
};

const TRANSLATION_KEY_PROJECT_TOOLS = {
  MICROSOFT_WORD: 'microsoft_word',
  GOOGLE_DOCS: 'google_docs',
  CROWDIN: 'contentoo_localisation_tool',
};

const TRANSLATION_KEY_PROJECT_TOOLS_BY_ID = {
  [WRITING_PROJECT_TOOLS.MICROSOFT_WORD]: TRANSLATION_KEY_PROJECT_TOOLS.MICROSOFT_WORD,
  [WRITING_PROJECT_TOOLS.GOOGLE_DOCS]: TRANSLATION_KEY_PROJECT_TOOLS.GOOGLE_DOCS,
  [WRITING_PROJECT_TOOLS.CROWDIN]: TRANSLATION_KEY_PROJECT_TOOLS.CROWDIN,
};

export {
  WRITING_PROJECT_TOOLS,
  WRITING_PROJECT_TOOLS_NAMES,
  WRITING_PROJECT_TOOLS_BY_ID,
  TRANSLATION_KEY_PROJECT_TOOLS,
  TRANSLATION_KEY_PROJECT_TOOLS_BY_ID,
};
